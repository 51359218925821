import { KinestheticSetType } from '../../../data/types';
import straightLineDown from './VO2-straight-line-down-letter.m4a';
import circleStarter from './VO2-circle-starter-letter.m4a';
import downCurve from './VO2-down-and-curve-letter.m4a';
import lineBack from './VO2-line-back.m4a';
import middleLineAcross from './VO2-middle-line-across.m4a';
import downAndUpPal from './VO2-down-and-up-pal.m4a';
import upAndDownPal from './VO2-up-and-down-pal.m4a';
import bump from './VO2-bump-letter.m4a';
import crissCross from './VO2-criss-cross.m4a';
import zipForwardBack from './VO2-zip-forward-back.m4a';
import kick from './VO2-kick-letter.m4a';
import bumpKick from './VO2-bump-and-kick-letter.m4a';
import superSquiggly from './VO2-super-squiggly.m4a';
import hook from './VO2-hook-letter.m4a';
import highStarter from './VO2-high-starter-letter.m4a';
import lineComesLater from './VO2-line-comes-later.m4a';
import dotsOnTop from './VO2-dots-on-top.m4a';
import hump from './VO2-hump-letter.m4a';
import circleComesLater from './VO2-circle-comes-later-letter.m4a';
import highHookLetter from './VO2-high-hook-letter.m4a';
import loopAroundLetter from './VO2-loop-around.m4a';
import downAndUpLetter from './VO2-down-and-up-letter.m4a';
import zigZag from './VO2-zig-zag.m4a';

const kinestheticGroups: { [key in KinestheticSetType]: string | null } = {
  [KinestheticSetType.STRAIGHT_LINE_DOWN]: straightLineDown,
  [KinestheticSetType.CIRCLE_STARTER]: circleStarter,
  [KinestheticSetType.DOWN_AND_CURVE]: downCurve,
  [KinestheticSetType.LINE_BACK]: lineBack,
  [KinestheticSetType.MIDDLE_LINE_ACROSS]: middleLineAcross,
  [KinestheticSetType.DOWN_AND_UP_PALS]: downAndUpPal,
  [KinestheticSetType.UP_AND_DOWN_PALS]: upAndDownPal,
  [KinestheticSetType.BUMP]: bump,
  [KinestheticSetType.UPPER_CRISS_CROSS]: crissCross,
  [KinestheticSetType.ZIP_FORWARD_BACK]: zipForwardBack,
  [KinestheticSetType.KICK]: kick,
  [KinestheticSetType.BUMP_AND_KICK]: bumpKick,
  [KinestheticSetType.SUPER_SQUIGGLY]: superSquiggly,
  [KinestheticSetType.HOOK]: hook,
  [KinestheticSetType.HIGH_STARTERS]: highStarter,
  [KinestheticSetType.LOWER_CIRCLE_STARTER]: circleStarter,
  [KinestheticSetType.LINE_COMES_LATER]: lineComesLater,
  [KinestheticSetType.DOTS_ON_TOP]: dotsOnTop,
  [KinestheticSetType.HUMP]: hump,
  [KinestheticSetType.CIRCLE_COMES_LATER]: circleComesLater,
  [KinestheticSetType.HIGH_HOOK]: highHookLetter,
  [KinestheticSetType.LOWER_DOWN_AND_UP]: downAndUpLetter,
  [KinestheticSetType.LOOP_AROUND]: loopAroundLetter,
  [KinestheticSetType.LOWER_SUPER_SQUIGGLY]: superSquiggly,
  [KinestheticSetType.LOWER_CRISS_CROSS]: crissCross,
  [KinestheticSetType.ZIG_ZAG]: zigZag,
};

export default kinestheticGroups;
