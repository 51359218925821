import React from 'react';
import { LaunchCustomSetAreaWrapper } from '../styles/components/ContentWrappers';
import { ContainerWithButtonContainer } from '../styles/components/ScreenContainers';
import NextButton from './Buttons/NextButton';
import dashedSquiggles3 from '../assets/images/backgrounds/dashed-squiggles-3.svg';
import planet from '../assets/images/planet.svg';
import starGroup3 from '../assets/images/star-group-3.png';
import starGroup5 from '../assets/images/star-group-5.png';
import starGroup6 from '../assets/images/star-group-6.svg';

interface LaunchCustomSetProps {
  setDisplayLabel: string,
  launchCustomSequence: () => void,
}

export default function LaunchCustomSet({ setDisplayLabel, launchCustomSequence }: LaunchCustomSetProps) {
  return (
    <ContainerWithButtonContainer>
      <h1 className="visually-hidden">
        Custom Set
        {' '}
        {setDisplayLabel}
      </h1>

      <LaunchCustomSetAreaWrapper>
        <img className="decoration planet-left" src={planet} alt="" />
        <img className="decoration star-group-left" src={starGroup5} alt="" />
        <img className="decoration star-group-bottom-left" src={starGroup6} alt="" />
        <img className="decoration squiggles" src={dashedSquiggles3} alt="" />
        <img className="decoration star-group-right" src={starGroup3} alt="" />

        <p className="text">{setDisplayLabel}</p>
      </LaunchCustomSetAreaWrapper>

      <div className="button-container">
        <NextButton
          isDisabled={false}
          buttonTouch={launchCustomSequence}
        />
      </div>
    </ContainerWithButtonContainer>
  );
}
