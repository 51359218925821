import styled from 'styled-components';
import { FlexRowCenter } from './FlexSpacers';
import modelLines155 from '../../assets/images/model-lines-155.svg';
import { DifficultyLevel } from '../../data/types';
import { MODEL_LINE_HEIGHT_SMALL, MODEL_LINE_HEIGHT_NORMAL } from '../../shared/constants';
import modelLines from '../../assets/images/model-lines.svg';
import modelLines83 from '../../assets/images/model-lines-83.svg';

interface ModelAreaProps {
  hide: boolean;
}

const ModelArea = styled(FlexRowCenter)`
  visibility: ${(props: ModelAreaProps) => (props.hide ? 'hidden' : 'visible')};
`;

type ModelLetterAreaProps = {
  difficultyLevel: DifficultyLevel,
}

export const ModelLetterArea = styled(ModelArea) <ModelLetterAreaProps>`
  width: 145px;
  height: ${(props) => (props.difficultyLevel === DifficultyLevel.EASY
    ? MODEL_LINE_HEIGHT_SMALL
    : MODEL_LINE_HEIGHT_NORMAL)}px;
  background: ${(props) => (props.difficultyLevel === DifficultyLevel.EASY
    ? `url(${modelLines83})`
    : `url(${modelLines})`)};
`;

export const ModelSpacingArea = styled(ModelArea)`
  background: url(${modelLines155});
  background-repeat: repeat-x;
  width: 300px;
  height: ${MODEL_LINE_HEIGHT_NORMAL}px;
  display: ${(props: ModelAreaProps) => (props.hide ? 'none' : 'flex')};
`;
