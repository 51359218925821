import { ActivityType, CharacterCategory, CharacterType } from '../../../data/types';
import successSolid from './VO10.m4a';
import successFaded from './VO15.m4a';
import successDotted from './VO17.m4a';

import speedyAndNeat from './VO23.m4a';
import greatJobLetters from './VO40-letters.m4a';
import greatJobNumbers from './VO41-numbers.m4a';
import greatJobSymbols from './VO42-symbols.m4a';

import lowerA from './VO25-LOWER_A.m4a';
import lowerB from './VO25-LOWER_B.m4a';
import lowerC from './VO25-LOWER_C.m4a';
import lowerD from './VO25-LOWER_D.m4a';
import lowerE from './VO25-LOWER_E.m4a';
import lowerF from './VO25-LOWER_F.m4a';
import lowerG from './VO25-LOWER_G.m4a';
import lowerH from './VO25-LOWER_H.m4a';
import lowerI from './VO25-LOWER_I.m4a';
import lowerJ from './VO25-LOWER_J.m4a';
import lowerK from './VO25-LOWER_K.m4a';
import lowerL from './VO25-LOWER_L.m4a';
import lowerM from './VO25-LOWER_M.m4a';
import lowerN from './VO25-LOWER_N.m4a';
import lowerO from './VO25-LOWER_O.m4a';
import lowerP from './VO25-LOWER_P.m4a';
import lowerQ from './VO25-LOWER_Q.m4a';
import lowerR from './VO25-LOWER_R.m4a';
import lowerS from './VO25-LOWER_S.m4a';
import lowerT from './VO25-LOWER_T.m4a';
import lowerU from './VO25-LOWER_U.m4a';
import lowerV from './VO25-LOWER_V.m4a';
import lowerW from './VO25-LOWER_W.m4a';
import lowerX from './VO25-LOWER_X.m4a';
import lowerY from './VO25-LOWER_Y.m4a';
import lowerZ from './VO25-LOWER_Z.m4a';

import number0 from './VO25-NUMBER_0.m4a';
import number1 from './VO25-NUMBER_1.m4a';
import number2 from './VO25-NUMBER_2.m4a';
import number3 from './VO25-NUMBER_3.m4a';
import number4 from './VO25-NUMBER_4.m4a';
import number5 from './VO25-NUMBER_5.m4a';
import number6 from './VO25-NUMBER_6.m4a';
import number7 from './VO25-NUMBER_7.m4a';
import number8 from './VO25-NUMBER_8.m4a';
import number9 from './VO25-NUMBER_9.m4a';

import upperA from './VO25-UPPER_A.m4a';
import upperB from './VO25-UPPER_B.m4a';
import upperC from './VO25-UPPER_C.m4a';
import upperD from './VO25-UPPER_D.m4a';
import upperE from './VO25-UPPER_E.m4a';
import upperF from './VO25-UPPER_F.m4a';
import upperG from './VO25-UPPER_G.m4a';
import upperH from './VO25-UPPER_H.m4a';
import upperI from './VO25-UPPER_I.m4a';
import upperJ from './VO25-UPPER_J.m4a';
import upperK from './VO25-UPPER_K.m4a';
import upperL from './VO25-UPPER_L.m4a';
import upperM from './VO25-UPPER_M.m4a';
import upperN from './VO25-UPPER_N.m4a';
import upperO from './VO25-UPPER_O.m4a';
import upperP from './VO25-UPPER_P.m4a';
import upperQ from './VO25-UPPER_Q.m4a';
import upperR from './VO25-UPPER_R.m4a';
import upperS from './VO25-UPPER_S.m4a';
import upperT from './VO25-UPPER_T.m4a';
import upperU from './VO25-UPPER_U.m4a';
import upperV from './VO25-UPPER_V.m4a';
import upperW from './VO25-UPPER_W.m4a';
import upperX from './VO25-UPPER_X.m4a';
import upperY from './VO25-UPPER_Y.m4a';
import upperZ from './VO25-UPPER_Z.m4a';

import symbolPlus from './VO25-SYMBOL_PLUS.m4a';
import symbolEqual from './VO25-SYMBOL_EQUAL.m4a';
import symbolQuestion from './VO25-SYMBOL_QUESTION.m4a';
import symbolExclamation from './VO25-SYMBOL_EXCLAMATION.m4a';

const activitySuccessCues = {
  [ActivityType.SOLID]: successSolid,
  [ActivityType.FADED]: successFaded,
  [ActivityType.DOTTED]: successDotted,
  [ActivityType.CHASE_STAR]: speedyAndNeat,
  [ActivityType.INDEPENDENT]: {
    [CharacterType.LOWER_A]: lowerA,
    [CharacterType.LOWER_B]: lowerB,
    [CharacterType.LOWER_C]: lowerC,
    [CharacterType.LOWER_D]: lowerD,
    [CharacterType.LOWER_E]: lowerE,
    [CharacterType.LOWER_F]: lowerF,
    [CharacterType.LOWER_G]: lowerG,
    [CharacterType.LOWER_H]: lowerH,
    [CharacterType.LOWER_I]: lowerI,
    [CharacterType.LOWER_J]: lowerJ,
    [CharacterType.LOWER_K]: lowerK,
    [CharacterType.LOWER_L]: lowerL,
    [CharacterType.LOWER_M]: lowerM,
    [CharacterType.LOWER_N]: lowerN,
    [CharacterType.LOWER_O]: lowerO,
    [CharacterType.LOWER_P]: lowerP,
    [CharacterType.LOWER_Q]: lowerQ,
    [CharacterType.LOWER_R]: lowerR,
    [CharacterType.LOWER_S]: lowerS,
    [CharacterType.LOWER_T]: lowerT,
    [CharacterType.LOWER_U]: lowerU,
    [CharacterType.LOWER_V]: lowerV,
    [CharacterType.LOWER_W]: lowerW,
    [CharacterType.LOWER_X]: lowerX,
    [CharacterType.LOWER_Y]: lowerY,
    [CharacterType.LOWER_Z]: lowerZ,

    [CharacterType.NUMBER_0]: number0,
    [CharacterType.NUMBER_1]: number1,
    [CharacterType.NUMBER_2]: number2,
    [CharacterType.NUMBER_3]: number3,
    [CharacterType.NUMBER_4]: number4,
    [CharacterType.NUMBER_5]: number5,
    [CharacterType.NUMBER_6]: number6,
    [CharacterType.NUMBER_7]: number7,
    [CharacterType.NUMBER_8]: number8,
    [CharacterType.NUMBER_9]: number9,

    [CharacterType.UPPER_A]: upperA,
    [CharacterType.UPPER_B]: upperB,
    [CharacterType.UPPER_C]: upperC,
    [CharacterType.UPPER_D]: upperD,
    [CharacterType.UPPER_E]: upperE,
    [CharacterType.UPPER_F]: upperF,
    [CharacterType.UPPER_G]: upperG,
    [CharacterType.UPPER_H]: upperH,
    [CharacterType.UPPER_I]: upperI,
    [CharacterType.UPPER_J]: upperJ,
    [CharacterType.UPPER_K]: upperK,
    [CharacterType.UPPER_L]: upperL,
    [CharacterType.UPPER_M]: upperM,
    [CharacterType.UPPER_N]: upperN,
    [CharacterType.UPPER_O]: upperO,
    [CharacterType.UPPER_P]: upperP,
    [CharacterType.UPPER_Q]: upperQ,
    [CharacterType.UPPER_R]: upperR,
    [CharacterType.UPPER_S]: upperS,
    [CharacterType.UPPER_T]: upperT,
    [CharacterType.UPPER_U]: upperU,
    [CharacterType.UPPER_V]: upperV,
    [CharacterType.UPPER_W]: upperW,
    [CharacterType.UPPER_X]: upperX,
    [CharacterType.UPPER_Y]: upperY,
    [CharacterType.UPPER_Z]: upperZ,

    [CharacterType.SYMBOL_PLUS]: symbolPlus,
    [CharacterType.SYMBOL_EQUAL]: symbolEqual,
    [CharacterType.SYMBOL_QUESTION]: symbolQuestion,
    [CharacterType.SYMBOL_EXCLAMATION]: symbolExclamation,
  },
  speedyAndNeat,
  greatJob: {
    [CharacterCategory.LETTER_LOWER]: greatJobLetters,
    [CharacterCategory.LETTER_UPPER]: greatJobLetters,
    [CharacterCategory.NUMBER]: greatJobNumbers,
    [CharacterCategory.SYMBOL]: greatJobSymbols,
  },
};

export default activitySuccessCues;
