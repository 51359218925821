import {
  assign,
  createMachine, MachineConfig, MachineOptions, StateMachine,
} from 'xstate';
import { gradientPens, initialPens, solidPens } from '../data/pens';
import {
  ActivityType, CharacterType, Pen, SpacingActivityType, SpacingItemType,
} from '../data/types';
import deepCopy from '../shared/deepCopy';
import shuffle from '../shared/shuffle';

export enum RewardState {
  IDLE = 'IDLE',
  REWARDING_PEN = 'REWARDING_PEN',
}

export interface RewardMachineSchema {
  states: {
    [key in RewardState]: {};
  }
}

export type RewardMachineContext = {
  activePen: Pen,
  unlockedPens: Pen[],
  availableSolidPens: Pen[],
  availableGradientPens: Pen[],
  rewardsPerItem: {
    [itemKey in CharacterType | SpacingItemType]?: {
      [activityKey in ActivityType | SpacingActivityType]?: boolean;
    }
  }
}

export const initialContext: RewardMachineContext = {
  activePen: initialPens[0],
  unlockedPens: deepCopy(initialPens),
  availableSolidPens: shuffle(solidPens, deepCopy),
  availableGradientPens: shuffle(gradientPens, deepCopy),
  rewardsPerItem: {},
};

export enum RewardEventTypes {
  CHANGE_ACTIVE_PEN = 'CHANGE_ACTIVE_PEN',
  REWARD_PEN = 'REWARD_PEN',
  NEXT = 'NEXT',
}

type RewardPayload = {
  itemType: CharacterType | SpacingItemType,
  activityType: ActivityType | SpacingActivityType,
}

export type RewardTransition<T> = {
  type: RewardEventTypes,
  payload?: T,
}

// guards

const hasNotReceivedPenForItemActivity = (context: RewardMachineContext, event: RewardTransition<RewardPayload>) => {
  if (!event.payload?.itemType || !event.payload.activityType) return false;
  return !context.rewardsPerItem[event.payload.itemType]?.[event.payload.activityType];
};

// effects/actions

const setActivePen = assign<RewardMachineContext, RewardTransition<Pen>>({
  activePen: (context: RewardMachineContext, event: RewardTransition<Pen>) => deepCopy(event.payload),
});

const unlockPenReward = assign((context: RewardMachineContext, event: RewardTransition<RewardPayload>) => {
  // early return if no rewards remain or required payload info isn't available
  if (!event.payload?.itemType
    || !event.payload?.activityType
    || !context?.availableSolidPens
    || !context?.availableGradientPens) {
    return context;
  }

  // note when pen has been rewarded for item/activity
  // to prevent multiple rewards when returning to a screen
  const nextRewardsPerItem = deepCopy(context.rewardsPerItem);
  if (!nextRewardsPerItem[event.payload.itemType]) {
    nextRewardsPerItem[event.payload.itemType] = {};
  }
  nextRewardsPerItem[event.payload.itemType][event.payload.activityType] = true;

  // select next pen to reward: all solid pens first, then gradient pens
  // and set new reward as the active pen
  if (context?.availableSolidPens.length) {
    const nextUnlockedPens = [...context.unlockedPens, context.availableSolidPens[0]];
    const nextSolidPens = [...context.availableSolidPens].slice(1);
    return {
      activePen: nextUnlockedPens[nextUnlockedPens.length - 1],
      unlockedPens: nextUnlockedPens,
      availableSolidPens: nextSolidPens,
      rewardsPerItem: nextRewardsPerItem,
    };
  } if (context?.availableGradientPens.length) {
    const nextUnlockedPens = [...context.unlockedPens, context.availableGradientPens[0]];
    const nextGradientPens = [...context.availableGradientPens].slice(1);
    return {
      activePen: nextUnlockedPens[nextUnlockedPens.length - 1],
      unlockedPens: nextUnlockedPens,
      availableGradientPens: nextGradientPens,
      rewardsPerItem: nextRewardsPerItem,
    };
  }
  return context;
});

// machine config

const rewardMachineConfig: MachineConfig<RewardMachineContext, RewardMachineSchema, RewardTransition<any>> = {
  schema: {
    context: {} as RewardMachineContext,
    events: {} as RewardTransition<any>,
  },
  predictableActionArguments: true,
  id: 'rewards',
  initial: RewardState.IDLE,
  context: initialContext,
  states: {
    [RewardState.IDLE]: {
      on: {
        [RewardEventTypes.CHANGE_ACTIVE_PEN]: {
          actions: 'setActivePen',
        },
        [RewardEventTypes.REWARD_PEN]: {
          cond: 'hasNotReceivedPenForItemActivity',
          target: RewardState.REWARDING_PEN,
          actions: 'unlockPenReward',
        },
      },
    },
    [RewardState.REWARDING_PEN]: {
      on: {
        [RewardEventTypes.NEXT]: {
          target: RewardState.IDLE,
        },
      },
    },
  },
};

const rewardMachineOptions: Partial<MachineOptions<RewardMachineContext, any>> = {
  guards: {
    hasNotReceivedPenForItemActivity,
  },
  actions: {
    setActivePen,
    unlockPenReward,
  },
};

export const rewardsMachine:
  StateMachine<RewardMachineContext, RewardMachineSchema, RewardTransition<any>> = createMachine(
    rewardMachineConfig,
    rewardMachineOptions,
  );
