import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';

interface LottiePlayerProps {
  data?: object,
  width: number|string,
  segmentFrames?: [number, number],
  handleComplete?: () => void,
  shouldAutoplay?: boolean,
  shouldLoop?: boolean,
  speed?: number;
}

export default function LottiePlayer({
  data, width, segmentFrames, handleComplete, shouldAutoplay, shouldLoop, speed,
}: LottiePlayerProps) {
  const [domLoaded, setDomLoaded] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    if ((speed !== 1.0) && ref && ref.current) {
      ref.current.setSpeed(speed);
    }

    if (data && domLoaded && segmentFrames?.length) {
      ref?.current?.playSegments(segmentFrames, true);
    }
  }, [segmentFrames, data, domLoaded]);

  if (data) {
    return (
      <div style={{ width }}>
        <Lottie
          animationData={data}
          loop={shouldLoop}
          width={width}
          autoplay={shouldAutoplay}
          lottieRef={ref}
          initialSegment={segmentFrames}
          onDOMLoaded={() => setDomLoaded(true)}
          onComplete={handleComplete}
        />
      </div>
    );
  }

  return null;
}

LottiePlayer.defaultProps = {
  data: null,
  segmentFrames: undefined,
  handleComplete: () => {},
  shouldAutoplay: false,
  shouldLoop: false,
  speed: 1.0,
};
