import {
  Activity, ActivityType, SpacingActivity, SpacingActivityCategory, SpacingActivityType,
} from './types';

const activities: {
  [key in ActivityType]: Activity;
} = {
  [ActivityType.SOLID]: {
    type: ActivityType.SOLID,
    currentIteration: 1,
    requiredIterations: 1,
    nextActivityType: ActivityType.FADED,
    starMeterFrames: [
      [0, 60],
    ],
  },
  [ActivityType.FADED]: {
    type: ActivityType.FADED,
    currentIteration: 1,
    requiredIterations: 1,
    nextActivityType: ActivityType.DOTTED,
    starMeterFrames: [
      [61, 120],
    ],
  },
  [ActivityType.DOTTED]: {
    type: ActivityType.DOTTED,
    currentIteration: 1,
    requiredIterations: 1,
    nextActivityType: ActivityType.CHASE_STAR,
    starMeterFrames: [
      [121, 180],
    ],
  },
  [ActivityType.CHASE_STAR]: {
    type: ActivityType.CHASE_STAR,
    currentIteration: 1,
    requiredIterations: 3,
    nextActivityType: ActivityType.INDEPENDENT,
    starMeterFrames: [
      [0, 30],
      [31, 60],
      [61, 90],
    ],
  },
  [ActivityType.INDEPENDENT]: {
    type: ActivityType.INDEPENDENT,
    currentIteration: 1,
    requiredIterations: 3,
    nextActivityType: ActivityType.STAR_GAME,
    starMeterFrames: [
      [91, 120],
      [121, 150],
      [151, 180],
    ],
  },
  [ActivityType.STAR_GAME]: {
    type: ActivityType.STAR_GAME,
    currentIteration: 1,
    requiredIterations: 1,
    nextActivityType: null,
    starMeterFrames: [
      [0, 180],
    ],
  },
};

export default activities;

export const spacingActivities: {
  [key in SpacingActivityType]: SpacingActivity;
} = {
  [SpacingActivityType.TRACE_FADED]: {
    type: SpacingActivityType.TRACE_FADED,
    category: SpacingActivityCategory.WRITING,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.MULTI_CHOICE_CLOSE,
    title: 'trace the faded character',
    starMeterFrames: [
      [0, 180],
    ],
  },
  [SpacingActivityType.MULTI_CHOICE_CLOSE]: {
    type: SpacingActivityType.MULTI_CHOICE_CLOSE,
    category: SpacingActivityCategory.MULTI_CHOICE,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.MULTI_CHOICE_WIDE,
    title: 'multiple choice',
  },
  [SpacingActivityType.MULTI_CHOICE_WIDE]: {
    type: SpacingActivityType.MULTI_CHOICE_WIDE,
    category: SpacingActivityCategory.MULTI_CHOICE,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.MULTI_CHOICE_ALL,
    title: 'multiple choice',
  },
  [SpacingActivityType.MULTI_CHOICE_ALL]: {
    type: SpacingActivityType.MULTI_CHOICE_ALL,
    category: SpacingActivityCategory.MULTI_CHOICE,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.TRACE_SHOOTING_STAR,
    title: 'multiple choice',
  },
  [SpacingActivityType.TRACE_SHOOTING_STAR]: {
    type: SpacingActivityType.TRACE_SHOOTING_STAR,
    category: SpacingActivityCategory.WRITING,
    requiredIterations: 2,
    nextActivityType: SpacingActivityType.SPACE_INDEPENDENT,
    title: 'trace the shooting star',
    starMeterFrames: [
      [0, 36],
      [36, 72],
    ],
  },
  [SpacingActivityType.SPACE_INDEPENDENT]: {
    type: SpacingActivityType.SPACE_INDEPENDENT,
    category: SpacingActivityCategory.WRITING,
    requiredIterations: 3,
    nextActivityType: SpacingActivityType.SELF_ASSESSMENT,
    title: 'independent writing',
    starMeterFrames: [
      [72, 108],
      [108, 144],
      [144, 180],
    ],
  },
  [SpacingActivityType.SELF_ASSESSMENT]: {
    type: SpacingActivityType.SELF_ASSESSMENT,
    category: SpacingActivityCategory.MULTI_CHOICE,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.PLANET_1_GAME,
    title: 'self-assessment',
    starMeterFrames: [
      [180, 180],
    ],
  },
  // planet games have a default required iteration length,
  // but this is set dynamically during play based on the active digraph/word length
  [SpacingActivityType.PLANET_1_GAME]: {
    type: SpacingActivityType.PLANET_1_GAME,
    category: SpacingActivityCategory.PLANET_GAME,
    requiredIterations: 1,
    nextActivityType: SpacingActivityType.PLANET_2_GAME,
    title: 'save the first planet',
  },
  [SpacingActivityType.PLANET_2_GAME]: {
    type: SpacingActivityType.PLANET_2_GAME,
    category: SpacingActivityCategory.PLANET_GAME,
    requiredIterations: 1,
    nextActivityType: null,
    title: 'save the second planet',
  },
};
