/* eslint-disable max-len */
import { DrawingStroke } from '../../components/Konva/types';
import { CharacterType } from './characters';
import { InstructionalSequenceType } from './sequences';

export interface Iteration {
  id: number,
  strokes: DrawingStroke[],
}

export enum StrokeOutcome {
  SUCCESS = 'SUCCESS', // success!
  ERROR_GENERAL_TRACING = 'ERROR_GENERAL_TRACING', // general error
  ERROR_START = 'ERROR_START', // when user doesn't hit the start dot
  ERROR_END = 'ERROR_END', // when user doesn't hit the stop dot or goes passed it.
  ERROR_TIME = 'ERROR_TIME', // when user doesn't finish their character in an appropriate amount of time.
  WARNING_INTERSECTION = 'WARNING_INTERSECTION', // when user doesn't close the gap or a loop.
  WARNING_INCORRECT_START_POINT = 'WARNING_INCORRECT_START_POINT', // is when a user tries to start their stroke in the wrong location and cannot draw a stroke. It's not counted as an error
  WARNING_REMINDER = 'WARNING_REMINDER', // when user is reminded to finish their character.
}

export enum StrokeOutcomeType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARN = 'WARN',
}

export enum CorrectiveFeedback {
  AUTOMATICITY_MEMORY = 'AUTOMATICITY_MEMORY', // Related to not starting a requested character within a certain amount of time -> indicating that they don’t remember how to make the letter without an example or prompt.
  AUTOMATICITY_SPEED = 'AUTOMATICITY_SPEED', // student's rate of speed when making character
  LEGIBILITY = 'LEGIBILITY', // Letters and numbers are sized to fit within writing lines and are placed correctly
}

export enum CorrectiveLegibilityTypes {
  CLOSE_GAP = 'CLOSE_GAP',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
}

export type DrawnCharacter = {
  id: string,
  order: number,
  characterId: CharacterType,
  sequenceId: InstructionalSequenceType | undefined,
  sequenceItemOrder: number,
  drawingStrokes: DrawingStroke[],
  isSelected: boolean,
}

export enum GameScreens {
  SELECT_SEQUENCE = 'SELECT_SEQUENCE',
  SELECT_PRACTICE_CHARACTER = '',
  KINESTHETIC_GROUP = 'KINESTHETIC_GROUP',
  DEMO_VIDEO = 'DEMO_VIDEO',
  GRIP_VIDEO = 'GRIP_VIDEO',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  DRAWING_ACTIVITIES = 'DRAWING_ACTIVITIES',
  STAR_GAME = 'STAR_GAME',
}

export enum LineType {
  SOLID = 'SOLID',
  FADED = 'FADED',
  DOTTED = 'DOTTED',
  DISAPPEARING = 'DISAPPEARING',
  NONE = 'NONE',
}
