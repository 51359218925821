import React from 'react';
import { AutoHeightWrapper } from '../styles/components/ContentWrappers';
import { SelectCustomSetContainer } from '../styles/components/ScreenContainers';
import { FlexWrapper, FlexRowSpaceBetween } from '../styles/components/FlexSpacers';
import { ThreeGroupHexagonButtonLayout, HexagonButtonGroup } from '../styles/components/HexagonButtonLayouts';
import EmptyCharacterSpacer from '../styles/components/EmptyCharacterSpacer';
import SequencePageButton from './Buttons/SequencePageButton';
import CharacterButton from './Buttons/CharacterButton';
import TeacherConfirmButton from './Buttons/TeacherConfirmButton';
import HexagonPlaceholder from './SVG/HexagonPlaceholder';
import {
  lowerLetterList, numberList, symbolList, upperLetterList,
} from '../data/characters';
import { CharacterCategory, CharacterType } from '../data/types';
import { HexagonButtonSizes } from './SVG/types';
import { SelectedChar } from '../pages/CreateCustomCharacterSet';
import EducatorResourcesLink from './Links/EducatorResourcesLink';
import { ActionType, useGameContext } from '../context/GameContext';

const CHARACTER_BUTTON_WIDTH = '56px';
const CHARACTER_BUTTON_HEIGHT = '65px';

interface SelectCustomSetProps {
  selectedChars: SelectedChar[],
  selectChar: (key: CharacterType, category: CharacterCategory) => void,
  removeChar: (index: number) => void,
  goToConfirmScreen: () => void,
  showConfirmLeavingModal: () => void,
}

export default function SelectCustomSet({
  selectedChars,
  selectChar,
  removeChar,
  goToConfirmScreen,
  showConfirmLeavingModal,
}: SelectCustomSetProps) {
  // Design requires specific letters on specific rows
  const upperLetterListRow1 = upperLetterList.slice(0, 14);
  const upperLetterListRow2 = upperLetterList.slice(14, 26);
  const lowerLetterListRow1 = lowerLetterList.slice(0, 14);
  const lowerLetterListRow2 = lowerLetterList.slice(14, 26);

  const selectedCharTypes = selectedChars.map((char: SelectedChar) => char.type);
  const ctx = useGameContext();

  return (
    <SelectCustomSetContainer>
      <FlexRowSpaceBetween className="text-wrapper">
        <div>
          <h1>Make a set for your student.</h1>
          <p>Select minimum of 2 and maximum of 5 characters. This is the order in which your set will appear.</p>
        </div>
        <SequencePageButton buttonTouch={showConfirmLeavingModal} />
      </FlexRowSpaceBetween>
      <AutoHeightWrapper className="selection-wrapper">
        <FlexRowSpaceBetween>
          <FlexWrapper>
            {(selectedChars as SelectedChar[]).map((char, index) => (
              <CharacterButton
                key={char.id}
                charType={char.type}
                charCategory={CharacterCategory.NUMBER}
                buttonTouch={() => removeChar(index)}
                isCharacterInCurrentSequence
                isDeleteable
                size={HexagonButtonSizes.SM}
              />
            ))}
            {Array(5 - selectedChars.length).fill('').map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <HexagonPlaceholder size={HexagonButtonSizes.SM} key={`placheholder-${i}`} />
            ))}
          </FlexWrapper>
          <TeacherConfirmButton
            isDisabled={selectedChars.length < 2}
            buttonText="DONE"
            buttonTouch={goToConfirmScreen}
          />
        </FlexRowSpaceBetween>
      </AutoHeightWrapper>
      <AutoHeightWrapper style={{ marginBottom: '0.8em' }}>
        <ThreeGroupHexagonButtonLayout>
          <HexagonButtonGroup className="number">
            <div className="row">
              {(numberList as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.NUMBER}
                  buttonTouch={() => selectChar(key, CharacterCategory.NUMBER)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
          </HexagonButtonGroup>
          <HexagonButtonGroup className="symbol">
            <div className="row custom-set">
              {(symbolList as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.SYMBOL}
                  buttonTouch={() => selectChar(key, CharacterCategory.SYMBOL)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
          </HexagonButtonGroup>
          <HexagonButtonGroup className="upper-letter">
            <div className="row one small">
              {(upperLetterListRow1 as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.LETTER_UPPER}
                  buttonTouch={() => selectChar(key, CharacterCategory.LETTER_UPPER)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
            <div className="row two small">
              <EmptyCharacterSpacer width={CHARACTER_BUTTON_WIDTH} height={CHARACTER_BUTTON_HEIGHT} />
              {(upperLetterListRow2 as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.LETTER_UPPER}
                  buttonTouch={() => selectChar(key, CharacterCategory.LETTER_UPPER)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
          </HexagonButtonGroup>
          <HexagonButtonGroup className="lower-letter">
            <div className="row one small">
              {(lowerLetterListRow1 as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.LETTER_LOWER}
                  buttonTouch={() => selectChar(key, CharacterCategory.LETTER_LOWER)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
            <div className="row two small">
              <EmptyCharacterSpacer width={CHARACTER_BUTTON_WIDTH} height={CHARACTER_BUTTON_HEIGHT} />
              {(lowerLetterListRow2 as CharacterType[]).map((key) => (
                <CharacterButton
                  key={key}
                  charType={key}
                  charCategory={CharacterCategory.LETTER_LOWER}
                  buttonTouch={() => selectChar(key, CharacterCategory.LETTER_LOWER)}
                  isCharacterInCurrentSequence={selectedCharTypes.includes(key)}
                  size={HexagonButtonSizes.SM}
                />
              ))}
            </div>
          </HexagonButtonGroup>
        </ThreeGroupHexagonButtonLayout>
      </AutoHeightWrapper>
      <EducatorResourcesLink handleLinkTouch={() => ctx?.dispatch({ type: ActionType.RESET })} />
    </SelectCustomSetContainer>
  );
}
