import { RefObject, useMemo, useState } from 'react';
import { characters } from '../data/characters';
import { Character, CharacterType, DifficultyLevel } from '../data/types';

enum ReadySetGoState {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  NONE = 'NONE',
}

/* This hook handles the logic for Chase the Shooting Star activity's
animation sequence and instruction */
export default function useChaseTheShootingStar() {
  const [readyStarState, setReadyStarState] = useState<ReadySetGoState>(ReadySetGoState.NONE);
  const [startShootingStarAnim, setStartShootingStarAnim] = useState(false);

  const isReadySetGoFinished = useMemo(() => (
    readyStarState === ReadySetGoState.FINISHED
  ), [readyStarState]);

  const isReadySetGoStarted = useMemo(() => (
    readyStarState === ReadySetGoState.STARTED
  ), [readyStarState]);

  const beginShootingStarAnim = () => setStartShootingStarAnim(true);

  const finishReadySetGoSequence = () => {
    setReadyStarState(ReadySetGoState.FINISHED);
  };

  const calculateXYTranslatePoints = (
    char: Character,
    drawingAreaEl: HTMLDivElement,
    readySetGoStarEl: HTMLDivElement,
    offsetX: number,
  ): { translate: [number, number] } => {
    /* Translate calculation is used in Ready Set Go Star animation
    ** The goal of this function is to find the point where the Ready Set Go Star should animate to and disappear at
    ** This point is the characters' first stroke's start point. Since the start point is inside the canvas, it's
    **  difficult to locate without using individual character positioning properties.
    **
    ** IMPORTANT: Extra margins have been added to the elements within WritingArea and accounting for them here
    ** provides more accurate calculations. This must be updated if those margins ever change.
    **
    ** Calculations are also affected by height css for FlexRowCenterTop in WritingPracticeAreaWrapper
    ** found in DrawingActivity.tsx
    */
    const drawingAreaRect = drawingAreaEl?.getBoundingClientRect();
    const readySetGoStarRect = readySetGoStarEl?.getBoundingClientRect();
    const charFirstStrokeStartPoint = char.strokes[0].points[0].coordinates[DifficultyLevel.NORMAL] || { x: 0, y: 0 };

    /* Calculate y
    **
    ** Start Dot Y = (drawing area y) + (character positioning y) + (first stroke start dot y) - drawable margins
    ** Ready Set Go Star Y = ready set go star y position
    ** Y Translation is the difference between the start dot and the ready set go star.
    */
    const positionY = (char.positioning[DifficultyLevel.NORMAL]?.scaffold.yOffset || 0);
    const startDotY = drawingAreaRect.y + positionY + charFirstStrokeStartPoint.y - 33;
    const readySetGoStarY = readySetGoStarRect.y;
    const translateY = Math.round(startDotY - readySetGoStarY);

    /* Calculate x
    **
    ** Start Dot X = (drawing area x) + (character positioning x) + (first stroke start dot x) + (drawable margin)
    ** + optional offset for spacing activities with wider (word) scaffolds
    ** Ready Set Go Star X = middle point x
    ** X Translation is the difference between the start dot and the ready set go star.
    */
    const positionX = (char.positioning[DifficultyLevel.NORMAL]?.scaffold.xOffset || 0);
    const startDotX = drawingAreaRect.x + positionX + charFirstStrokeStartPoint.x + 28 + offsetX;
    const readySetGoStarX = readySetGoStarRect.x + Math.round(readySetGoStarRect.width / 2);
    const translateX = Math.round(startDotX - readySetGoStarX);

    return { translate: [translateX, translateY] };
  };

  const getReadyStarAnimationData = (
    drawingAreaGrid: RefObject<HTMLDivElement>,
    readySetGoStarRef: RefObject<HTMLDivElement | null>,
    characterType: CharacterType,
    startDotPositionOffsetX?: number,
  ): { translate: [number, number] } => {
    if (!drawingAreaGrid?.current || !readySetGoStarRef?.current || !characterType) {
      return { translate: [0, 0] };
    }
    const character = characters[characterType];
    return calculateXYTranslatePoints(
      character,
      drawingAreaGrid.current,
      readySetGoStarRef.current,
      startDotPositionOffsetX || 0,
    );
  };

  const resetChaseStarIteration = () => {
    setStartShootingStarAnim(false);
    setReadyStarState(ReadySetGoState.NONE);
  };

  const startChaseStarIteration = () => {
    setReadyStarState(ReadySetGoState.STARTED);
  };

  const stopChaseStarAnimation = () => {
    setStartShootingStarAnim(false);
  };

  return {
    isReadySetGoFinished,
    isReadySetGoStarted,
    finishReadySetGoSequence,
    beginShootingStarAnim,
    startShootingStarAnim,
    resetChaseStarIteration,
    startChaseStarIteration,
    getReadyStarAnimationData,
    stopChaseStarAnimation,
  };
}
