import React from 'react';
import { useTheme } from 'styled-components';
import homeIcon from '../../assets/images/icons/home-icon.svg';
import { CircleButton } from '../../styles/components/Buttons';

export default function HomeButton({ handleTouch }: { handleTouch: any }) {
  const theme = useTheme();

  return (
    <CircleButton
      colors={theme.colors.buttons.home}
      aria-label="go to home page"
      onTouchStart={handleTouch}
      role="link"
    >
      <img
        alt=""
        src={homeIcon}
      />
    </CircleButton>
  );
}
