import greatJob from './VO71.m4a';
import youDidIt from './VO45.m4a';
import tryAgainNextTime from './VO73.m4a';
import ohNo from './VO47.m4a';

export const planetGameAudio = {
  caughtAStar: youDidIt,
  caughtAllStars: greatJob,
  missedStar: ohNo,
  missedAllStars: tryAgainNextTime,
};
