import React, { useState, useEffect } from 'react';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { spacingItems, spacingItemTypesList } from '../data/spacing-items';
import { SpacingItemType } from '../data/types';
import { SpacingEventTypes } from '../stateMachines/spacingMachine';
import { SelectCharactersContainer } from '../styles/components/ScreenContainers';
import { CharacterSelectionAreaWrapper, SequenceScreenAccessWrapper } from '../styles/components/ContentWrappers';
import SequencePageButton from '../components/Buttons/SequencePageButton';
import starGroup1 from '../assets/images/star-group-1.png';
import starGroup2 from '../assets/images/star-group-2.png';
import { SingleGroupHexagonButtonLayout, HexagonButtonGroup } from '../styles/components/HexagonButtonLayouts';
import SpacingItemButton from '../components/Buttons/SpacingItemButton';
import ClosePageButton from '../components/Buttons/ClosePageButton';
import GrantAccess from '../components/GrantAccess';
import { spacingSets } from '../data/spacing-sets';
import { useAudioContext } from '../context/AudioContext';
import selectGreenArrow from '../assets/audio/VO1.m4a';
import selectBlueButton from '../assets/audio/VO74.m4a';

enum ViewState {
  SHOW_ACCESS_GUARD = 'SHOW_ACCESS_GUARD',
  SELECT_ITEM = 'SELECT_ITEM'
}

export default function SelectNextSpacingItem() {
  const audioContext = useAudioContext();
  const isOnline = useOnlineStatus();
  const { state, send } = useSpacingGameContext();
  const [view, setView] = useState(ViewState.SELECT_ITEM);

  const isNextItem = (key: SpacingItemType) => state.context.currentSet?.sequenceItems[0]?.type === key;

  const isItemInUnlockedSet = (key: SpacingItemType) => state.context.currentSet?.practiceItems[key];

  const isButtonDisabled = (key: SpacingItemType) => {
    if (!state.context?.isPracticeMode) {
      return !isNextItem(key);
    }
    return !isItemInUnlockedSet(key);
  };

  const isItemInRemainingSequence = (key: SpacingItemType) => (
    state.context?.currentSet?.sequenceItems.map((item) => item.type).includes(key)
  );

  const selectItem = (key: SpacingItemType) => (
    send({ type: SpacingEventTypes.SELECT_ITEM, payload: spacingItems[key] })
  );

  useEffect(() => {
    audioContext?.handlePlay({ src: state.context?.isPracticeMode ? selectBlueButton : selectGreenArrow });
    return () => audioContext?.handleComplete();
  }, []);

  const handleCloseButtonTouch = () => {
    setView(ViewState.SELECT_ITEM);
    window.scrollTo(0, 0);
  };

  const handleHomeButtonTouch = () => {
    const remainingItems = state.context?.currentSet?.sequenceItems || [];
    const currentSetType = state.context?.currentSet?.type;
    const initialItems = currentSetType ? spacingSets[currentSetType].sequenceItems : [];
    const completedItemsCount = initialItems.length - remainingItems.length;

    if (!completedItemsCount) {
      send(SpacingEventTypes.RESET);
    } else {
      setView(ViewState.SHOW_ACCESS_GUARD);
    }
  };

  // Design requires specific letters on specific rows
  const row1Types = spacingItemTypesList.slice(0, 5);
  const row2Types = spacingItemTypesList.slice(5, 11);
  const row3Types = spacingItemTypesList.slice(11, 14);
  const rows = [row1Types, row2Types, row3Types];

  if (view === ViewState.SHOW_ACCESS_GUARD) {
    return (
      <SequenceScreenAccessWrapper>
        <ClosePageButton buttonTouch={handleCloseButtonTouch} ariaLabel="go back" />
        <div className="content">
          <GrantAccess buttonTouch={() => send(SpacingEventTypes.RESET)} buttonText="Go to Home" />
        </div>
      </SequenceScreenAccessWrapper>
    );
  }

  return (
    <>
      {!isOnline && <NoInternetToast />}
      <SelectCharactersContainer>
        <h1>Starwriter</h1>
        <CharacterSelectionAreaWrapper>
          <SequencePageButton
            buttonTouch={handleHomeButtonTouch}
          />
          <img className="stars-left" src={starGroup1} alt="" />
          <img className="stars-right" src={starGroup2} alt="" />
          <SingleGroupHexagonButtonLayout>
            <HexagonButtonGroup className="spacing">
              {(rows as SpacingItemType[][]).map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="row" key={index}>
                  {row.map((key) => (
                    <SpacingItemButton
                      key={key}
                      displayLabel={spacingItems[key].display}
                      isDisabled={isButtonDisabled(key)}
                      buttonTouch={() => selectItem(key)}
                      isCurrentItem={isNextItem(key)}
                      isItemInCurrentSequence={isItemInRemainingSequence(key)}
                      isItemInUnlockedSet={isItemInUnlockedSet(key)}
                    />
                  ))}
                </div>
              ))}
            </HexagonButtonGroup>
          </SingleGroupHexagonButtonLayout>
        </CharacterSelectionAreaWrapper>
      </SelectCharactersContainer>
    </>
  );
}
