import { SpacingItem, UnlockedItems } from './index';

export enum SpacingSetType {
  DIGRAPH = 'DIGRAPH',
  WORDS_1 = 'WORDS_1',
  WORDS_2 = 'WORDS_2',
  WORDS_3 = 'WORDS_3',
}

export type SpacingSet = {
  type: SpacingSetType,
  sequenceItems: SpacingItem[],
  practiceItems: UnlockedItems,
  display: string,
  displayOrder: number,
}
