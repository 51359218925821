/* eslint-disable no-case-declarations */
import React, {
  createContext, useContext, useReducer, useMemo,
} from 'react';
import { DrawingStroke } from '../components/Konva/types';
import {
  ActivityType, GameScreens, Iteration, StrokeOutcome,
} from '../data/types';

export type ActivityProgressState = {
  inProgress?: boolean,
  starMeterFrames?: [number, number],
  iterations?: Iteration[],
  isActivityComplete?: boolean,
  lastStrokeOutcome?: StrokeOutcome|null,
  strokes?: DrawingStroke[],
  currentIteration?: number,
}

type ActivitiesProgressState = {
  [key in ActivityType]?: ActivityProgressState;
}

export type ProgressState = {
  redirectedFrom: GameScreens|ActivityType|null,
  activities: ActivitiesProgressState,
}

export enum ActionType {
  SAVE_ACTIVITY_PROGRESS = 'SAVE_ACTIVITY_PROGRESS',
  UPDATE_ACTIVITY_PROGRESS = 'UPDATE_ACTIVITY_PROGRESS',
  RESET_ACTIVITY_PROGRESS = 'RESET_ACTIVITY_PROGRESS',
  RESET_ALL_PROGRESS = 'RESET_ALL_PROGRESS',
}

type Action =
  { type: ActionType.SAVE_ACTIVITY_PROGRESS, payload: {
    activityType: ActivityType,
    activityProgress: ActivityProgressState,
    redirectedFrom: GameScreens|ActivityType|null,
  } }
  | { type: ActionType.UPDATE_ACTIVITY_PROGRESS, payload: {
    activityType: ActivityType,
    strokeProgress?: DrawingStroke[],
    redirectedFrom: GameScreens|ActivityType|null,
  } }
  | { type: ActionType.RESET_ACTIVITY_PROGRESS, payload: ActivityType }
  | { type: ActionType.RESET_ALL_PROGRESS };

export const initialState: ProgressState = {
  redirectedFrom: null,
  activities: {},
};

interface ActivityProgressContextInterface extends ProgressState {
  dispatch: React.Dispatch<any>,
}

const ActivityProgressContext = createContext<ActivityProgressContextInterface | null>(null);

type ActivityProgressContextProviderProps = {
  children: React.ReactNode,
}

export const reducer = (currentState: ProgressState, action: Action): ProgressState => {
  switch (action.type) {
    case ActionType.SAVE_ACTIVITY_PROGRESS:
      const allActivities = { ...currentState.activities };
      allActivities[action.payload.activityType] = {
        ...allActivities[action.payload.activityType],
        ...action.payload.activityProgress,
        inProgress: true,
      };
      return {
        ...currentState,
        redirectedFrom: action.payload.redirectedFrom,
        activities: { ...allActivities },
      };
    case ActionType.UPDATE_ACTIVITY_PROGRESS:
      const allCurrentActivities = { ...currentState.activities };

      if (action.payload.strokeProgress) {
        const currentStrokes = currentState.activities[action.payload.activityType]?.strokes;
        allCurrentActivities[action.payload.activityType] = {
          ...allCurrentActivities[action.payload.activityType],
          strokes: currentStrokes
            ? [...currentStrokes, ...action.payload.strokeProgress]
            : [...action.payload.strokeProgress],
        };
      }

      return {
        ...currentState,
        redirectedFrom: action.payload.redirectedFrom,
        activities: allCurrentActivities,
      };
    case ActionType.RESET_ACTIVITY_PROGRESS:
      const currentActivities = { ...currentState.activities };
      const savedActivities = Object.keys(currentActivities)
        .filter((activityType) => activityType !== action.payload)
        .reduce<ActivitiesProgressState>((allRemainingActivities, activityType) => {
          // eslint-disable-next-line no-param-reassign
          allRemainingActivities[activityType as ActivityType] = currentActivities[activityType as ActivityType];
          return allRemainingActivities;
        }, {} as ActivitiesProgressState);

      return {
        ...currentState,
        redirectedFrom: null,
        activities: { ...savedActivities },
      };
    case ActionType.RESET_ALL_PROGRESS:
      return initialState;
    default:
      return currentState;
  }
};

export default function ActivityProgressContextProvider({ children }: ActivityProgressContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const providerValue = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return (
    <ActivityProgressContext.Provider value={providerValue}>
      {children}
    </ActivityProgressContext.Provider>
  );
}

export function useActivityProgressContext() {
  const context = useContext(ActivityProgressContext);

  if (context === undefined) {
    throw new Error('useActivityProgressContext must be used within ActivityProgressContextProvider');
  }

  return context;
}
