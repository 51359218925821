import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import GripButton, { GripButtonType } from '../components/Buttons/GripButton';
import SequenceButton from '../components/Buttons/SequenceButton';
import { useGameContext, GameFlowStep, ActionType } from '../context/GameContext';
import { instructionalSequences, instructionalSequenceTypeList } from '../data/sequences';
import {
  InstructionalSequence, InstructionalSequenceType, SequenceCategory, SpacingSet,
} from '../data/types';
import { SelectionAreaWrapper } from '../styles/components/ContentWrappers';
import { SelectSequenceContainer } from '../styles/components/ScreenContainers';
import { TextButtonStateColors, TextButtonWithAdditionalColors } from '../styles/styled';
import selectASequence from '../assets/audio/VO0.m4a';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import { useAudioContext } from '../context/AudioContext';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { spacingSetTypeList, spacingSets } from '../data/spacing-sets';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { SpacingEventTypes } from '../stateMachines/spacingMachine';
import EducatorResourcesLink from '../components/Links/EducatorResourcesLink';

export default function SelectSequence() {
  const ctx = useGameContext();
  const theme = useTheme();
  const isOnline = useOnlineStatus();
  const audioContext = useAudioContext();
  const { send } = useSpacingGameContext();

  useEffect(() => {
    /* Since this page is the first to show on app load, many browsers do not allow
        audio to play until its resumed or after a user gesture: https://goo.gl/7K7WLu.
        So, on load, SelectSequence will not play VO until a user taps on screen.
    */
    audioContext?.handlePlay({ src: selectASequence });
    return () => audioContext?.handleComplete();
  }, []);

  const isButtonDisabled = (sequence: InstructionalSequence): boolean => (
    sequence.characters.length === 0 && sequence.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS
  );

  const getSpacingSequenceColors = (): TextButtonWithAdditionalColors => (
    theme.colors.buttons.instructionalDigraphSequence.active
  );

  const getCharacterSequenceColors = (sequence: InstructionalSequence): TextButtonWithAdditionalColors => {
    let colorObject: TextButtonStateColors;

    if (sequence.id === InstructionalSequenceType.SEQUENCE_23) {
      // Symbol sequence has different coloring than other Instructional sequences
      colorObject = theme.colors.buttons.practiceSequence;
    } else {
      switch (sequence.category) {
        case SequenceCategory.INSTRUCTIONAL_CHARACTERS:
          colorObject = theme.colors.buttons.instructionalCharacterSequence;
          break;
        case SequenceCategory.PRACTICE:
          colorObject = theme.colors.buttons.practiceSequence;
          break;
        default:
          colorObject = theme.colors.buttons.instructionalDigraphSequence;
          break;
      }
    }

    return (isButtonDisabled(sequence))
      ? colorObject.disabled
      : colorObject.active;
  };

  const handleCharacterSequenceButtonTouch = (sequence: InstructionalSequence): void => {
    /* If user does not tap on screen, VO remains loaded in Audio Player and
        plays briefly right before SelectCharacters VO. This stops that from happening.
    */
    audioContext?.handleComplete();
    ctx?.dispatch({
      type: (sequence?.category === SequenceCategory.PRACTICE)
        ? ActionType.SELECT_PRACTICE_SEQUENCE
        : ActionType.SELECT_SEQUENCE,
      payload: sequence,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSpacingSetButtonTouch = (payload: SpacingSet): void => {
    audioContext?.handleComplete();
    send({ type: SpacingEventTypes.LAUNCH_SET, payload });
  };

  const handleGripDemoButtonTouch = () => {
    ctx?.dispatch({ type: ActionType.GO_TO_GRIP_DEMO_VIDEO });
  };

  return (
    <>
      {!isOnline && <NoInternetToast />}
      <SelectionAreaWrapper>
        <h1 className="visually-hidden">Sequence Selection</h1>
        {ctx?.flowStep === GameFlowStep.INIT ? (
          <SelectSequenceContainer>
            <GripButton type={GripButtonType.PILL} handleTouch={handleGripDemoButtonTouch} />
            {instructionalSequenceTypeList
              .map((key) => {
                if (key === InstructionalSequenceType.CUSTOM_1 || !instructionalSequences[key]) return null;
                const display = instructionalSequences[key].display || '';
                return (
                  <SequenceButton
                    key={key}
                    colors={getCharacterSequenceColors(instructionalSequences[key])}
                    isDisabled={isButtonDisabled(instructionalSequences[key])}
                    ariaLabel={`start practicing ${display}`}
                    sequenceOrder={instructionalSequences[key].order}
                    buttonTouch={() => handleCharacterSequenceButtonTouch(instructionalSequences[key])}
                  >
                    {display}
                  </SequenceButton>
                );
              })}
            {spacingSetTypeList.map((key) => {
              if (!spacingSets[key]) return null;
              const display = spacingSets[key].display || '';
              return (
                <SequenceButton
                  key={key}
                  colors={getSpacingSequenceColors()}
                  ariaLabel={`start practicing spacing for ${display}`}
                  sequenceOrder={spacingSets[key].displayOrder}
                  buttonTouch={() => handleSpacingSetButtonTouch(spacingSets[key])}
                >
                  {display}
                </SequenceButton>
              );
            })}
            <div className="spacer" />
          </SelectSequenceContainer>
        ) : null}
        <EducatorResourcesLink shouldCheckAccess />
      </SelectionAreaWrapper>
    </>
  );
}
