import React, { useEffect, useMemo, useState } from 'react';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { SpacingActionOutcome, SpacingEventTypes, SpacingState } from '../stateMachines/spacingMachine';
import PlanetGameWrapper from '../styles/components/PlanetGameWrapper';
import planetGameWords from '../assets/images/planet-game';
import {
  GameProgress, PlanetGameProgress, SpacingActivityType, SpacingItemType,
} from '../data/types';
import NextButton from '../components/Buttons/NextButton';
import PlanetGameShootingStar from '../components/PlanetGameShootingStar';
import { useAudioContext } from '../context/AudioContext';
import spacingActivityIntros from '../assets/audio/spacingActivityIntros';
import { PLANET_GAME_ALLOWED_ATTEMPTS } from '../shared/constants';
import PlanetLottie from '../components/PlanetLottie';
import StarScoreBoard from '../components/StarScoreBoard';
import { planetGameAudio } from '../assets/audio/rewards';

const defaultGameProgress = {
  totalStarsToCatch: 0,
  starsCaught: 0,
  starsMissed: 0,
  attempts: 0,
};

export default function SpacingPlanetGame() {
  const { state, send } = useSpacingGameContext();
  const currentItem = state.context?.currentItem;
  const audioContext = useAudioContext();
  const [gameProgress, setGameProgress] = useState<PlanetGameProgress>({
    [SpacingActivityType.PLANET_1_GAME]: defaultGameProgress,
    [SpacingActivityType.PLANET_2_GAME]: defaultGameProgress,
  });

  const animatePlanet = useMemo(() => state.matches(SpacingState.SHOWING_ERROR_FEEDBACK), [state.value]);

  const showNextButton = useMemo(() => (
    state.matches(SpacingState.CUEING_ACTION)
        && state.context?.currentActivity?.type === SpacingActivityType.PLANET_1_GAME
  ), [state.value, state.context?.currentActivity?.type]);

  const shootingStarPosition = useMemo(() => {
    const leftPosition = state.context?.currentItem?.positioning.planetGame.xOffset || 0;
    const currentActivityType = state.context?.currentActivity?.type;
    if (!currentActivityType) return 0;
    return (gameProgress[currentActivityType].starsCaught < 1)
      ? leftPosition
      : leftPosition + 105;
  }, [state.context?.currentActivity?.type, gameProgress]);

  const cueActivityIntro = (currentActivityType: SpacingActivityType) => {
    audioContext?.handlePlay({
      src: spacingActivityIntros[currentActivityType],
      onEnd: () => {
        if (currentActivityType === SpacingActivityType.PLANET_2_GAME) {
          send(SpacingEventTypes.NEXT);
        }
      },
    });
  };

  const cueActivityError = (currentGameProgress: GameProgress) => {
    const src = (currentGameProgress.attempts === PLANET_GAME_ALLOWED_ATTEMPTS)
      ? planetGameAudio.missedAllStars
      : planetGameAudio.missedStar;
    audioContext?.handlePlay({ src, onEnd: () => send(SpacingEventTypes.NEXT) });
  };

  const cueActivitySuccess = (currentGameProgress: GameProgress) => {
    const src = (currentGameProgress.starsCaught === currentGameProgress.totalStarsToCatch)
      ? planetGameAudio.caughtAllStars
      : planetGameAudio.caughtAStar;
    audioContext?.handlePlay({ src, onEnd: () => send(SpacingEventTypes.NEXT) });
  };

  const getUpdatedActivityProgress = () => {
    if (!state.context || !state.context.currentActivity) return null;
    const currentGameProgress = { ...gameProgress[state.context.currentActivity.type] };

    if (currentGameProgress) {
      const currentMissedStars = currentGameProgress.starsMissed;
      const currentAttempts = currentGameProgress.attempts;
      const currentStarsCaught = currentGameProgress.starsCaught;
      return {
        ...gameProgress,
        [state.context.currentActivity.type]: {
          ...currentGameProgress,
          starsCaught: state.matches(SpacingState.SHOWING_ERROR_FEEDBACK) ? currentStarsCaught : currentStarsCaught + 1,
          starsMissed: state.matches(SpacingState.SHOWING_ERROR_FEEDBACK) ? currentMissedStars + 1 : currentMissedStars,
          attempts: state.matches(SpacingState.SHOWING_ERROR_FEEDBACK) ? currentAttempts + 1 : 0,
        },
      };
    }
    return null;
  };

  useEffect(() => {
    if (!state.context || !state.context.currentActivity) return;
    const currentActivityType = state.context.currentActivity.type;

    if (state.matches(SpacingState.CUEING_ACTION) && state.context.currentActivity) {
      cueActivityIntro(currentActivityType);
    } else if (state.matches(SpacingState.SHOWING_ERROR_FEEDBACK)) {
      const updatedProgress = getUpdatedActivityProgress();
      if (updatedProgress) {
        setGameProgress({ ...updatedProgress });
        cueActivityError(updatedProgress[state.context.currentActivity.type]);
      }
    } else if (state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)) {
      const updatedProgress = getUpdatedActivityProgress();
      if (updatedProgress) {
        setGameProgress({ ...updatedProgress });
        cueActivitySuccess(updatedProgress[state.context.currentActivity.type]);
      }
    }
  }, [state.value]);

  useEffect(() => {
    if (!state.context || !state.context.currentActivity || !currentItem) return;
    const wordLength = currentItem.display.length;
    const initialGameProgress = { ...gameProgress };
    initialGameProgress[state.context.currentActivity.type] = {
      totalStarsToCatch: wordLength - 1,
      starsCaught: 0,
      starsMissed: 0,
      attempts: 0,
    };
    setGameProgress(initialGameProgress);
  }, [state.context?.currentActivity]);

  if (!state.context || !currentItem) return null;

  return (
    <PlanetGameWrapper wordHeight={currentItem.positioning.planetGame.height || null}>
      <div>
        <div className="top">
          <PlanetLottie planetType={state.context?.currentActivity?.type || null} shouldAnimate={animatePlanet} />
          <PlanetGameShootingStar
            leftPosition={shootingStarPosition}
            start={state.matches(SpacingState.ACTIVE)}
            handleCaughtStar={() => {
              send({
                type: SpacingEventTypes.ACTION_COMPLETED,
                payload: { spacingOutcome: SpacingActionOutcome.PASS },
              });
            }}
            handleMissedStar={() => {
              send({
                type: SpacingEventTypes.ACTION_COMPLETED,
                payload: { spacingOutcome: SpacingActionOutcome.FAIL },
              });
            }}
          />
        </div>

        <div className="middle">
          <StarScoreBoard gameProgress={gameProgress} />
        </div>

        <div className="bottom">
          {(currentItem && currentItem.type) && (
            <img alt="" src={planetGameWords[currentItem.type as SpacingItemType]} className="word" />
          )}
          {showNextButton && (
            <NextButton
              isDisabled={false}
              buttonTouch={() => send(SpacingEventTypes.NEXT)}
            />
          )}
        </div>
      </div>
    </PlanetGameWrapper>
  );
}
