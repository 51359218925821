import React from 'react';
import {
  Character, DifficultyLevel, LineType,
} from '../data/types';
import { FlexRowBottomSpaced, FlexRowCenterTop } from '../styles/components/FlexSpacers';
import ModelLetter from './ModelLetter';
import useDifficultyLevel from '../hooks/useDifficultyLevel';

type ScaffoldModelProps = {
  character: Character,
  show: boolean,
  height: string,
  lineType: LineType,
}

export default function ScaffoldModel({
  character, show, height, lineType,
}: ScaffoldModelProps) {
  const { difficultyLevel } = useDifficultyLevel();

  return (
    difficultyLevel === DifficultyLevel.EASY ? (
      <FlexRowBottomSpaced style={{ height: '85px', width: '100%' }}>
        <ModelLetter
          currentCharacter={character}
          showModelLetter={show}
          difficultyLevel={difficultyLevel}
          lineType={lineType}
        />
        <ModelLetter
          currentCharacter={character}
          showModelLetter={show}
          difficultyLevel={difficultyLevel}
          lineType={lineType}
        />
      </FlexRowBottomSpaced>
    ) : (
      <FlexRowCenterTop style={{ height }}>
        <ModelLetter
          currentCharacter={character}
          showModelLetter={show}
          difficultyLevel={difficultyLevel}
          lineType={lineType}
        />
      </FlexRowCenterTop>
    )
  );
}
