import { CharacterType, SpacingSetType } from './index';

export enum SpacingItemType {
  CH = 'CH',
  SH = 'SH',
  TH = 'TH',
  IN = 'IN',
  AM = 'AM',
  UP = 'UP',
  IT = 'IT',
  AT = 'AT',
  IF = 'IF',
  US = 'US',
  NO = 'NO',
  YES = 'YES',
  RED = 'RED',
  BAG = 'BAG'
}

type SpacingPosition = {
  xOffset?: number,
  yOffset?: number,
  height?: number,
  width?: number,
  // trace the shooting star lotties can use the tracing character's positionY
  // but need a separate positionX value to account for the width of the word
  lottiePositionX?: number,
}

export enum SpacingItemSize {
  sm = 'small',
  lg = 'large',
}

export enum SpacingFeedbackType {
  TOO_CLOSE = 'TOO_CLOSE',
  TOO_WIDE = 'TOO_WIDE',
  CORRECT = 'CORRECT',
}

export type SpacingGradingPosition = {
  inner?: SpacingPosition,
  outer: SpacingPosition,
}

export type SpacingItem = {
  type: SpacingItemType,
  display: string,
  set: SpacingSetType,
  traceCharacterType: CharacterType,
  size: SpacingItemSize,
  positioning: {
    model: SpacingPosition,
    scaffold: SpacingPosition,
    multi: SpacingPosition,
    chooseStar: {
      [key in SpacingFeedbackType]: SpacingPosition
    },
    shootingStar: SpacingPosition,
    grading: SpacingGradingPosition,
    planetGame: SpacingPosition,
  }
}

export type UnlockedItems = {
  [key in SpacingItemType]?: boolean;
}
