import styled, { css } from 'styled-components';
import { FlexColumnCenter } from './FlexSpacers';
import lineBg from '../../assets/images/backgrounds/line-background.svg';
import purpleBg from '../../assets/images/backgrounds/custom-set-purple.svg';
import { DifficultyLevel } from '../../data/types';

export const ContentAreaWrapper = styled(FlexColumnCenter)`
  background-color: #FFFFFF;
  border-radius: 54px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
`;

// padding-inline value source URL:
// https://utopia.fyi/space/calculator/?c=1024,32,1.2,1440,72.5,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
// padding-block value source URL:
// https://utopia.fyi/space/calculator?c=1024,82,1.2,1440,144,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
const ContentAreaWrapperWithResponsivePadding = styled(ContentAreaWrapper)`
  --space-xs-s: clamp(3.88rem, calc(-8.74rem + 19.71vw), 9.00rem);
  --space-s-m: clamp(2.00rem, calc(-9.85rem + 18.51vw), 6.81rem);

  padding-top: var(--space-xs-s);
  padding-right: var(--space-s-m);
  padding-bottom: var(--space-xs-s);
  padding-left: var(--space-s-m);
`;

export const AutoHeightWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

export const DemoAreaWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  height: 475px;
  max-width: 870px;
  position: relative;

  img {
    position: absolute;
  }

  .squiggles.top {
    top: -14%;
    left: -12%;
  }

  .squiggles.bottom {
    bottom: -11%;
    right: 14%;
  }

  .pencil {
    bottom: -6%;
    right: -2%;
    z-index: 5;
  }

  .warning {
    font-size: ${(props) => props.theme.fonts.sizes.xxxl};
    font-family: ${(props) => props.theme.fonts.families.primary};
  }

  .button-container {
    position: absolute;
    z-index: 5;

    &.bottom {
      bottom: -13%;
      right: -7%;
    }
  }
`;

export const CustomSetConfirmAreaWrapper = styled(ContentAreaWrapper)`
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 2em;

  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .character-buttons {
    margin: 3em 0;

    button {
      margin-right: 1em;
    }
  }
`;

type WritingPracticeAreaWrapperType = {
  difficultyLevel: DifficultyLevel,
}

export const WritingPracticeAreaWrapper = styled(ContentAreaWrapper) <WritingPracticeAreaWrapperType>`
  height: 500px;
  max-width: 870px;
  width: 100%;
  margin: auto;

  ${(props) => props.difficultyLevel === DifficultyLevel.EASY && css`
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 23px 45px 45px;
    max-width: 780px;
    height: 432px;
  `}
`;

export const SpacingPracticeAreaWrapper = styled(WritingPracticeAreaWrapper)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 8%;
  position: relative;

  .ready-set-go-star {
    width: 130px;
    height: 126px;
    z-index: 99;
    position: absolute;
  }

  .activity-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2em;
    width: 100%;
    justify-items: center;
  }
`;

export const InfoPageAreaWrapper = styled(ContentAreaWrapperWithResponsivePadding)`
  align-items: flex-start;

  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 425px;
  position: relative;
  margin: 55px 0;

  h2, p, a {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    margin: 0;
  }

  h2 {
    font-weight: ${(props) => props.theme.fonts.weights.boldest};
    font-size: ${(props) => props.theme.fonts.sizes.md};
    line-height: 110%;
    color: ${(props) => props.theme.colors.text.secondary};
    margin-bottom: 8px;
  }

  p + h2 {
    margin-top: 0.5em;
  }

  p {
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    font-size: ${(props) => props.theme.fonts.sizes.sm};
    line-height: 140%;
    letter-spacing: -0.01em;
    color: ${(props) => props.theme.colors.text.primary};
    margin-bottom: 12px;

    &.small {
      font-size: ${(props) => props.theme.fonts.sizes.xs};
    }
  }

  .about-content a {
    font-weight: ${(props) => props.theme.fonts.weights.bolder};
    font-size: ${(props) => props.theme.fonts.sizes.sm};
    line-height: 140%;
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.text.secondary};
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    a {
      margin-right: 80px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .educator-resources-link-container {
    position: relative;
    bottom: -22%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const SequenceScreenAccessWrapper = styled(ContentAreaWrapper)`
  height: 575px;
  width: 800px;
  position: relative;

 .content {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
      font-family: ${(props) => props.theme.fonts.families.secondary};
      font-size: ${(props) => props.theme.fonts.sizes.lg};
      font-weight: ${(props) => props.theme.fonts.weights.bold};
      line-height: 1.5;
      margin-bottom: 2.5rem;
    }
  }
`;

export const StarGameAreaWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  max-width: 825px;
  height: 525px;
  position: relative;

  .hide {
    display: none;
  }

  img, button {
    position: absolute;
  }

  .squiggles.top {
    top: -15%;
    left: -16%;
  }

  .squiggles.bottom {
    bottom: -12%;
    right: 10%;
  }

  button {
    bottom: -12%;
    right: -8%;
  }
`;

const ContentAreaWrapperWithLines = styled(ContentAreaWrapper)`
  background-image: url(${lineBg});
  background-repeat: repeat;
  background-position: 0 20px;
  position: relative;
`;

export const SelectionAreaWrapper = styled(ContentAreaWrapperWithLines)`
  max-height: 650px;
  height: 100%;
  padding-bottom: 8px;

  .educator-resources-link-container {
    position: absolute;
    bottom: 2%;
  }
`;

export const CharacterSelectionAreaWrapper = styled(ContentAreaWrapperWithLines)`
  max-width: 983px;
  max-height: 655px;

  > button {
    position: absolute;
    right: 0;
    top: -9%;
  }

  img.stars-left,
  img.stars-right,
  img.pencil {
    position: absolute;
  }

  img.pencil {
    bottom: -17%;
    right: 8%;
    transform: rotate(94deg);
  }

  img.stars-left {
    top: -12%;
    left: -3%;
  }

  img.stars-right {
    top: -17%;
    right: 13%;
  }
`;

export const LaunchCustomSetAreaWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  height: 100%;
  max-width: 870px;
  max-height: 432px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${purpleBg});
  background-repeat: repeat;
  background-position: -15px -15px;

  img.decoration {
    position: absolute;

    &.squiggles {
      bottom: -55%;
      right: -15%;
      z-index: -1;
    }

    &.star-group-right {
      width: 125px;
      height: auto;
      top: 20%;
      right: -3%;
    }

    &.star-group-bottom-left {
      width: 133px;
      height: auto;
      bottom: -8%;
      left: 5%;
    }

    &.star-group-left {
      width: 169px;
      height: auto;
      top: 8%;
      left: 8%;
    }

    &.planet-left {
      width: 212px;
      height: auto;
      top: -18%;
      left: -4%;
    }
  }

  .text {
    font-family: ${(props) => props.theme.fonts.families.primary};
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    font-size: 108px;
    color: ${(props) => props.theme.colors.white};
    letter-spacing: 0.8rem;
    z-index: 2;
  }
`;

export const KinestheticGroupAreaWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  height: 100%;
  max-width: 870px;
  max-height: 432px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img.decoration {
    position: absolute;

    &.pencil {
      transform: rotate(-72deg);
      top: -25%;
      left: 3%;
    }

    &.squiggles {
      bottom: -55%;
      right: -15%;
      z-index: -1;
    }

    &.star-group-left {
      width: 165px;
      height: auto;
      top: 0%;
      left: 11%;
    }

    &.star-group-right {
      width: auto;
      height: 141px;
      top: 14%;
      right: -2%;
    }

    &.star-group-bottom {
      width: 233px;
      height: auto;
      bottom: -12%;
      left: 4%;
    }
  }

  .button-container button {
    position: absolute;
    bottom: -28%;
    right: -6%;
  }

  .kinesthetic-group-container {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const SelfAssessmentAreaWrapper = styled(FlexColumnCenter)`
  max-width: 870px;

  .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 43px;
    row-gap: 48px;
    grid-template-areas:
    ". spacer"
    ". .";

    .practice-card {
      grid-column: span 2;
    }

    .spacer {
      grid-area: spacer;
      grid-column: span 1;
    }
  }

  .button-container {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 120px;
    width: 90px;
  }
`;

export const TouchDeviceRequiredWrapper = styled(ContentAreaWrapperWithResponsivePadding)`
  width: 800px;
  height: 575px;

  button img {
    width: 52px;
    height: 55px;
  }

  h1 {
    font-family: ${(props) => props.theme.fonts.families.primary};
    text-transform: lowercase;
    text-align: center;
    font-size: 128px;
    font-weight: ${(props) => props.theme.fonts.weights.bold};
    margin: 14px 0 16px 0;
  }

  p {
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-size: 32px;
    margin: 0 0 3.5rem 0;
  }

  p.small {
    font-size: ${(props) => props.theme.fonts.sizes.lg};
    margin: 0;
  }

  a {
    color: ${(props) => props.theme.colors.text.secondary};
    font-weight: ${(props) => props.theme.fonts.weights.bolder};
  }
`;

interface ChooseSpacingAreaProps {
  hasRows?: boolean,
}

export const ChooseSpacingAreaWrapper = styled.div<ChooseSpacingAreaProps>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.25em;
  row-gap: ${(props) => (props.hasRows ? '1.25em' : '0')};
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  grid-template-areas:
    "spacer ."
    ". .";
  height: 500px;
  max-height: 500px;

  .practice-card-wrapper {
    position: relative;
    grid-column: span 2;
  }

  .spacer {
    grid-area: spacer;
    grid-column: span 1;
  }
`;

ChooseSpacingAreaWrapper.defaultProps = {
  hasRows: false,
};

export const TopNavigationWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;

  ul.demo-buttons {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;

    li + li {
      margin-inline-start: 24px;
    }
  }
`;

export const ResourcesAreaWrapper = styled(ContentAreaWrapper)`
  width: 100%;
  height: 100%;
  max-width: 928px;
  max-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 0;

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 35px;

    .title {
      font-family: ${(props) => props.theme.fonts.families.primary};
      font-weight: ${(props) => props.theme.fonts.weights.bold};
      font-size: ${(props) => props.theme.fonts.sizes.xxxl};
    }

    .sub-title {
      font-family: ${(props) => props.theme.fonts.families.secondary};
      font-weight: ${(props) => props.theme.fonts.weights.bold};
      font-size: ${(props) => props.theme.fonts.sizes.lg};
      text-align: center;
      line-height: 24px;
    }
  }

  .resource-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5em;
    justify-content: center;
    margin-bottom: 55px;
    width: 80%;
  }

  .launch-starwriter {
    align-self: center;

    .launch-text {
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .link-container {
    position: relative;
    bottom: -16%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: ${(props) => props.theme.fonts.families.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.sm};
    font-weight: ${(props) => props.theme.fonts.weights.bolder};

    a {
      margin-right: 40px;
      color: ${(props) => props.theme.colors.text.tertiary};

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const DocumentAreaWrapper = styled(ContentAreaWrapper)`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 875px;
  max-height: 475px;
  margin: 55px 25px;
  padding: 25px;

  .educator-resources-link-container {
    position: relative;
    bottom: -63%;
    left: 0%;
  }
`;
