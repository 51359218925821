import { ActivityType, DifficultyLevel, KinestheticSetType } from './index';

export enum CharacterCategory {
  LETTER_UPPER = 'LETTER_UPPER',
  LETTER_LOWER = 'LETTER_LOWER',
  NUMBER = 'NUMBER',
  SYMBOL = 'SYMBOL',
}

export enum CharacterType {
  NUMBER_0 = 'NUMBER_0',
  NUMBER_1 = 'NUMBER_1',
  NUMBER_2 = 'NUMBER_2',
  NUMBER_3 = 'NUMBER_3',
  NUMBER_4 = 'NUMBER_4',
  NUMBER_5 = 'NUMBER_5',
  NUMBER_6 = 'NUMBER_6',
  NUMBER_7 = 'NUMBER_7',
  NUMBER_8 = 'NUMBER_8',
  NUMBER_9 = 'NUMBER_9',
  UPPER_A = 'UPPER_A',
  UPPER_B = 'UPPER_B',
  UPPER_C = 'UPPER_C',
  UPPER_D = 'UPPER_D',
  UPPER_E = 'UPPER_E',
  UPPER_F = 'UPPER_F',
  UPPER_G = 'UPPER_G',
  UPPER_H = 'UPPER_H',
  UPPER_I = 'UPPER_I',
  UPPER_J = 'UPPER_J',
  UPPER_K = 'UPPER_K',
  UPPER_L = 'UPPER_L',
  UPPER_M = 'UPPER_M',
  UPPER_N = 'UPPER_N',
  UPPER_O = 'UPPER_O',
  UPPER_P = 'UPPER_P',
  UPPER_Q = 'UPPER_Q',
  UPPER_R = 'UPPER_R',
  UPPER_S = 'UPPER_S',
  UPPER_T = 'UPPER_T',
  UPPER_U = 'UPPER_U',
  UPPER_V = 'UPPER_V',
  UPPER_W = 'UPPER_W',
  UPPER_X = 'UPPER_X',
  UPPER_Y = 'UPPER_Y',
  UPPER_Z = 'UPPER_Z',
  LOWER_A = 'LOWER_A',
  LOWER_B = 'LOWER_B',
  LOWER_C = 'LOWER_C',
  LOWER_D = 'LOWER_D',
  LOWER_E = 'LOWER_E',
  LOWER_F = 'LOWER_F',
  LOWER_G = 'LOWER_G',
  LOWER_H = 'LOWER_H',
  LOWER_I = 'LOWER_I',
  LOWER_J = 'LOWER_J',
  LOWER_K = 'LOWER_K',
  LOWER_L = 'LOWER_L',
  LOWER_M = 'LOWER_M',
  LOWER_N = 'LOWER_N',
  LOWER_O = 'LOWER_O',
  LOWER_P = 'LOWER_P',
  LOWER_Q = 'LOWER_Q',
  LOWER_R = 'LOWER_R',
  LOWER_S = 'LOWER_S',
  LOWER_T = 'LOWER_T',
  LOWER_U = 'LOWER_U',
  LOWER_V = 'LOWER_V',
  LOWER_W = 'LOWER_W',
  LOWER_X = 'LOWER_X',
  LOWER_Y = 'LOWER_Y',
  LOWER_Z = 'LOWER_Z',
  SYMBOL_PLUS = 'SYMBOL_PLUS',
  SYMBOL_EQUAL = 'SYMBOL_EQUAL',
  SYMBOL_QUESTION = 'SYMBOL_QUESTION',
  SYMBOL_EXCLAMATION = 'SYMBOL_EXCLAMATION',
}

type XYCoords = {
  x: number,
  y: number,
}

type XYMinMax = {
  xMin?: number,
  xMax?: number,
  yMin?: number,
  yMax?: number,
}

export type Point = {
  // coordinates are positioned from top left of character position
  coordinates: {
    [DifficultyLevel.EASY]?: XYCoords,
    [DifficultyLevel.NORMAL]: XYCoords,
    [DifficultyLevel.HARD]?: XYCoords,
  }

  // set x, y coords before setting rotation and offsets for start point
  rotationDegrees?: number,
  rotationOffsetX?: number,
  rotationOffsetY?: number,
  // necessary for visible midpoints
  visible?: boolean,
  // circle radius of zone used to capture intersections
  // or distance allowed if start or end point
  // if not specified, a default value is used
  radius?: number,
  // index of intersection order; start point = 0
  orderId?: number,
  // supports characters with overlapping start/stop points or retraced strokes, e.g. 'O' or 'm'
  // point will only track intersection after specified orderId point is intersected
  allowIntersectionAfterOrderId?: number,
  // supports showing mid/end points only after a stroke passes a certain point to reduce
  // confusion in users about where and in what direction to bring the stroke.
  visibleAfterOrderId?: number,
}

export interface ThresholdRestrictedPoint extends Point {
  // supports strokes with start/endpoints that must not pass a certain threshold, e.g. 'g' or 'q'
  // coordinates are positioned from top left of yellow guide box
  minMax?: {
    [DifficultyLevel.EASY]?: XYMinMax,
    [DifficultyLevel.NORMAL]?: XYMinMax,
    [DifficultyLevel.HARD]?: XYMinMax,
  }
}

export enum RestrictedAreaType {
  MID_STROKE = 'MID_STROKE',
  END_STROKE = 'END_STROKE',
}

export type RestrictedAreaShape = {
  id: string,
  type: RestrictedAreaType,
  coordinates: {
    [DifficultyLevel.EASY]?: XYCoords,
    [DifficultyLevel.NORMAL]: XYCoords,
    [DifficultyLevel.HARD]?: XYCoords,
  }
  points: number[],
  tension?: number,
}

export enum StrokeIntersectionType {
  END_POINT_INTERSECTION = 'END_POINT_INTERSECTION',
  START_POINT_INTERSECTION = 'START_POINT_INTERSECTION',
}

export type Stroke = {
  id: string,
  order: number,
  paths: {
    [DifficultyLevel.EASY]?: string,
    [DifficultyLevel.NORMAL]: string,
    [DifficultyLevel.HARD]?: string,
  },
  // dot strokes will have only one point
  points: (Point | ThresholdRestrictedPoint)[],
  restrictedAreas?: RestrictedAreaShape[],
  requireIntersection?: StrokeIntersectionType,
};

export enum PracticeLinesRelationship {
  WITHIN_LINES = 'WITHIN_LINES',
  BELOW_LINES = 'BELOW_LINES',
}

export type GradingSettings = {
  pointRadius: number,
  pathStrokeWidth: number,
  // percentage of drawn points that must intersect with model path
  strokePathAccuracyThreshold: number,
};

export type CharPosition = {
  // used to position whole model within target writing area from top left
  // should be set based on writing lines and scaled up for large model lines at top
  xOffset?: number,
  yOffset?: number,
  // default to line height for upper chars, but variable for lower chars
  height?: number,
  width?: number,
}

export type DifficultyLevelCharPosition = {
  // when the model character needs specific positioning, especially for lowercase letters
  // if not set, positionX and positionY are used
  model?: CharPosition,
  scaffold: CharPosition,
}

export type Character = {
  type: CharacterType,
  category: CharacterCategory,
  kinestheticGroupType?: KinestheticSetType,
  display: string,
  // relationship to lower line. Default is within
  practiceLinesRelationship?: PracticeLinesRelationship,
  positioning: {
    [DifficultyLevel.EASY]?: DifficultyLevelCharPosition,
    [DifficultyLevel.NORMAL]: DifficultyLevelCharPosition,
    [DifficultyLevel.HARD]?: DifficultyLevelCharPosition,
  }
  strokes: Stroke[],
  lottieSettings?: {
    [ActivityType.CHASE_STAR]?: {
      positionX: number,
      positionY: number,
      frames: [number, number],
    },
  },
  gradingSettings: GradingSettings,
  // assigned dynamically in GameContext
  rewards?: {
    [key in ActivityType]?: boolean;
  }
};

export type UnlockedCharacters = {
  [key in CharacterType]?: boolean;
}
