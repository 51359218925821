import { CharacterType } from '../../../data/types';

// introduction start VOs
import startAtTheTop from './start-at-the-top.m4a';
import startNearTheTop from './start-near-the-top.m4a';
import startAtTheBottom from './start-at-the-bottom.m4a';
import startJustAboveMiddle from './start-just-above-middle.m4a';
import startAtTheMiddle from './start-at-the-middle.m4a';
import startNearTheMiddle from './start-near-the-middle.m4a';
import startinBottomArea from './start-in-bottom-area.m4a';

// instruction end VOs
import upperA from './UPPER_A.m4a';
import upperB from './UPPER_B.m4a';
import upperC from './UPPER_C.m4a';
import upperD from './UPPER_D.m4a';
import upperE from './UPPER_E.m4a';
import upperF from './UPPER_F.m4a';
import upperG from './UPPER_G.m4a';
import upperH from './UPPER_H.m4a';
import upperI from './UPPER_I.m4a';
import upperJ from './UPPER_J.m4a';
import upperK from './UPPER_K.m4a';
import upperL from './UPPER_L.m4a';
import upperM from './UPPER_M.m4a';
import upperN from './UPPER_N.m4a';
import upperO from './UPPER_O.m4a';
import upperP from './UPPER_P.m4a';
import upperQ from './UPPER_Q.m4a';
import upperR from './UPPER_R.m4a';
import upperS from './UPPER_S.m4a';
import upperT from './UPPER_T.m4a';
import upperU from './UPPER_U.m4a';
import upperV from './UPPER_V.m4a';
import upperW from './UPPER_W.m4a';
import upperX from './UPPER_X.m4a';
import upperY from './UPPER_Y.m4a';
import upperZ from './UPPER_Z.m4a';

import lowerA from './LOWER_A.m4a';
import lowerB from './LOWER_B.m4a';
import lowerC from './LOWER_C.m4a';
import lowerD from './LOWER_D.m4a';
import lowerE from './LOWER_E.m4a';
import lowerF from './LOWER_F.m4a';
import lowerG from './LOWER_G.m4a';
import lowerH from './LOWER_H.m4a';
import lowerI from './LOWER_I.m4a';
import lowerJ from './LOWER_J.m4a';
import lowerK from './LOWER_K.m4a';
import lowerL from './LOWER_L.m4a';
import lowerM from './LOWER_M.m4a';
import lowerN from './LOWER_N.m4a';
import lowerO from './LOWER_O.m4a';
import lowerP from './LOWER_P.m4a';
import lowerQ from './LOWER_Q.m4a';
import lowerR from './LOWER_R.m4a';
import lowerS from './LOWER_S.m4a';
import lowerT from './LOWER_T.m4a';
import lowerU from './LOWER_U.m4a';
import lowerV from './LOWER_V.m4a';
import lowerW from './LOWER_W.m4a';
import lowerX from './LOWER_X.m4a';
import lowerY from './LOWER_Y.m4a';
import lowerZ from './LOWER_Z.m4a';

import symbolEqual from './SYMBOL_EQUAL.m4a';
import symbolExclamation from './SYMBOL_EXCLAMATION.m4a';
import symbolPlus from './SYMBOL_PLUS.m4a';
import symbolQuestion from './SYMBOL_QUESTION.m4a';

import number0 from './NUMBER_0.m4a';
import number1 from './NUMBER_1.m4a';
import number2 from './NUMBER_2.m4a';
import number3 from './NUMBER_3.m4a';
import number4 from './NUMBER_4.m4a';
import number5 from './NUMBER_5.m4a';
import number6 from './NUMBER_6.m4a';
import number7 from './NUMBER_7.m4a';
import number8 from './NUMBER_8.m4a';
import number9 from './NUMBER_9.m4a';

type CharInstructions = {
  [key in CharacterType]?: {
    instructionStart: any,
    instructionEnd: any,
  };
}

export const charInstructions: CharInstructions = {
  [CharacterType.LOWER_A]: { instructionStart: startNearTheMiddle, instructionEnd: lowerA },
  [CharacterType.LOWER_B]: { instructionStart: startAtTheTop, instructionEnd: lowerB },
  [CharacterType.LOWER_C]: { instructionStart: startNearTheMiddle, instructionEnd: lowerC },
  [CharacterType.LOWER_D]: { instructionStart: startNearTheMiddle, instructionEnd: lowerD },
  [CharacterType.LOWER_E]: { instructionStart: startinBottomArea, instructionEnd: lowerE },
  [CharacterType.LOWER_F]: { instructionStart: startNearTheTop, instructionEnd: lowerF },
  [CharacterType.LOWER_G]: { instructionStart: startNearTheMiddle, instructionEnd: lowerG },
  [CharacterType.LOWER_H]: { instructionStart: startAtTheTop, instructionEnd: lowerH },
  [CharacterType.LOWER_I]: { instructionStart: startAtTheMiddle, instructionEnd: lowerI },
  [CharacterType.LOWER_J]: { instructionStart: startAtTheMiddle, instructionEnd: lowerJ },
  [CharacterType.LOWER_K]: { instructionStart: startAtTheTop, instructionEnd: lowerK },
  [CharacterType.LOWER_L]: { instructionStart: startAtTheTop, instructionEnd: lowerL },
  [CharacterType.LOWER_M]: { instructionStart: startAtTheMiddle, instructionEnd: lowerM },
  [CharacterType.LOWER_N]: { instructionStart: startAtTheMiddle, instructionEnd: lowerN },
  [CharacterType.LOWER_O]: { instructionStart: startAtTheMiddle, instructionEnd: lowerO },
  [CharacterType.LOWER_P]: { instructionStart: startAtTheMiddle, instructionEnd: lowerP },
  [CharacterType.LOWER_Q]: { instructionStart: startNearTheMiddle, instructionEnd: lowerQ },
  [CharacterType.LOWER_R]: { instructionStart: startAtTheMiddle, instructionEnd: lowerR },
  [CharacterType.LOWER_S]: { instructionStart: startNearTheMiddle, instructionEnd: lowerS },
  [CharacterType.LOWER_T]: { instructionStart: startAtTheTop, instructionEnd: lowerT },
  [CharacterType.LOWER_U]: { instructionStart: startAtTheMiddle, instructionEnd: lowerU },
  [CharacterType.LOWER_V]: { instructionStart: startAtTheMiddle, instructionEnd: lowerV },
  [CharacterType.LOWER_W]: { instructionStart: startAtTheMiddle, instructionEnd: lowerW },
  [CharacterType.LOWER_X]: { instructionStart: startAtTheMiddle, instructionEnd: lowerX },
  [CharacterType.LOWER_Y]: { instructionStart: startAtTheMiddle, instructionEnd: lowerY },
  [CharacterType.LOWER_Z]: { instructionStart: startAtTheMiddle, instructionEnd: lowerZ },

  [CharacterType.NUMBER_0]: { instructionStart: startAtTheTop, instructionEnd: number0 },
  [CharacterType.NUMBER_1]: { instructionStart: startNearTheTop, instructionEnd: number1 },
  [CharacterType.NUMBER_2]: { instructionStart: startNearTheTop, instructionEnd: number2 },
  [CharacterType.NUMBER_3]: { instructionStart: startNearTheTop, instructionEnd: number3 },
  [CharacterType.NUMBER_4]: { instructionStart: startAtTheTop, instructionEnd: number4 },
  [CharacterType.NUMBER_5]: { instructionStart: startAtTheTop, instructionEnd: number5 },
  [CharacterType.NUMBER_6]: { instructionStart: startAtTheTop, instructionEnd: number6 },
  [CharacterType.NUMBER_7]: { instructionStart: startAtTheTop, instructionEnd: number7 },
  [CharacterType.NUMBER_8]: { instructionStart: startAtTheTop, instructionEnd: number8 },
  [CharacterType.NUMBER_9]: { instructionStart: startNearTheTop, instructionEnd: number9 },

  [CharacterType.UPPER_A]: { instructionStart: startAtTheBottom, instructionEnd: upperA },
  [CharacterType.UPPER_B]: { instructionStart: startAtTheTop, instructionEnd: upperB },
  [CharacterType.UPPER_C]: { instructionStart: startNearTheTop, instructionEnd: upperC },
  [CharacterType.UPPER_D]: { instructionStart: startAtTheTop, instructionEnd: upperD },
  [CharacterType.UPPER_E]: { instructionStart: startAtTheTop, instructionEnd: upperE },
  [CharacterType.UPPER_F]: { instructionStart: startAtTheTop, instructionEnd: upperF },
  [CharacterType.UPPER_G]: { instructionStart: startNearTheTop, instructionEnd: upperG },
  [CharacterType.UPPER_H]: { instructionStart: startAtTheTop, instructionEnd: upperH },
  [CharacterType.UPPER_I]: { instructionStart: startAtTheTop, instructionEnd: upperI },
  [CharacterType.UPPER_J]: { instructionStart: startAtTheTop, instructionEnd: upperJ },
  [CharacterType.UPPER_K]: { instructionStart: startAtTheTop, instructionEnd: upperK },
  [CharacterType.UPPER_L]: { instructionStart: startAtTheTop, instructionEnd: upperL },
  [CharacterType.UPPER_M]: { instructionStart: startAtTheBottom, instructionEnd: upperM },
  [CharacterType.UPPER_N]: { instructionStart: startAtTheBottom, instructionEnd: upperN },
  [CharacterType.UPPER_O]: { instructionStart: startAtTheTop, instructionEnd: upperO },
  [CharacterType.UPPER_P]: { instructionStart: startAtTheTop, instructionEnd: upperP },
  [CharacterType.UPPER_Q]: { instructionStart: startAtTheTop, instructionEnd: upperQ },
  [CharacterType.UPPER_R]: { instructionStart: startAtTheTop, instructionEnd: upperR },
  [CharacterType.UPPER_S]: { instructionStart: startNearTheTop, instructionEnd: upperS },
  [CharacterType.UPPER_T]: { instructionStart: startAtTheTop, instructionEnd: upperT },
  [CharacterType.UPPER_U]: { instructionStart: startAtTheTop, instructionEnd: upperU },
  [CharacterType.UPPER_V]: { instructionStart: startAtTheTop, instructionEnd: upperV },
  [CharacterType.UPPER_W]: { instructionStart: startAtTheTop, instructionEnd: upperW },
  [CharacterType.UPPER_X]: { instructionStart: startAtTheTop, instructionEnd: upperX },
  [CharacterType.UPPER_Y]: { instructionStart: startAtTheTop, instructionEnd: upperY },
  [CharacterType.UPPER_Z]: { instructionStart: startAtTheTop, instructionEnd: upperZ },

  [CharacterType.SYMBOL_EQUAL]: { instructionStart: startJustAboveMiddle, instructionEnd: symbolEqual },
  [CharacterType.SYMBOL_PLUS]: { instructionStart: startJustAboveMiddle, instructionEnd: symbolPlus },
  [CharacterType.SYMBOL_QUESTION]: { instructionStart: startNearTheTop, instructionEnd: symbolQuestion },
  [CharacterType.SYMBOL_EXCLAMATION]: { instructionStart: startAtTheTop, instructionEnd: symbolExclamation },
};
