import React, { useEffect, useMemo, useState } from 'react';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { SpacingEventTypes } from '../stateMachines/spacingMachine';
import NextButton from '../components/Buttons/NextButton';
import DemoVideoWrapper from '../components/DemoVideoWrapper';
import VideoPlayer from '../components/VideoPlayer';
import { SpacingItemType, SpacingSetType } from '../data/types';
import useOnlineStatus from '../hooks/useOnlineStatus';
import envSettings from '../data/envSettings';
import { DEFAULT_VIDEO_PATH } from '../shared/constants';

export default function SpacingDemo() {
  const { send, state } = useSpacingGameContext();
  const isOnline = useOnlineStatus();
  const [videoHasFinished, setVideoHasFinished] = useState(false);
  const [videoHasPlayedBefore, setVideoHasPlayedBefore] = useState(false);

  const nextButtonIsEnabled = useMemo(
    () => (!isOnline || videoHasFinished || videoHasPlayedBefore || envSettings.devMode),
    [isOnline, videoHasFinished, videoHasPlayedBefore],
  );

  useEffect(() => {
    setVideoHasPlayedBefore(state.context?.hasWatchedDemo || state.context?.isPracticeMode || false);
  }, [state]);

  const makeSpacingVideoPath = (itemType: SpacingItemType): string => {
    let tone = '';
    switch (itemType) {
      case SpacingItemType.IN:
      case SpacingItemType.US:
        tone = '1';
        break;
      case SpacingItemType.IF:
      case SpacingItemType.UP:
      case SpacingItemType.TH:
        tone = '2';
        break;
      case SpacingItemType.BAG:
      case SpacingItemType.RED:
      case SpacingItemType.SH:
        tone = '3';
        break;
      case SpacingItemType.AT:
      case SpacingItemType.NO:
      case SpacingItemType.CH:
        tone = '4';
        break;
      default:
        tone = '5';
    }
    return `${DEFAULT_VIDEO_PATH}/Spacing-Demo-Tone${tone}.mp4`;
  };

  if (!state || !state.context || !state.context.currentItem) return null;
  const { currentItem } = state.context;
  const setTypeText = currentItem.set === SpacingSetType.DIGRAPH ? 'diagraph' : 'word';

  return (
    <DemoVideoWrapper
      headerText={`Demo Video for ${setTypeText} ${currentItem.display}`}
      nextButton={(
        <NextButton
          buttonTouch={() => send(SpacingEventTypes.NEXT)}
          isDisabled={!nextButtonIsEnabled}
        />
          )}
    >
      <VideoPlayer
        path={makeSpacingVideoPath(currentItem.type)}
        ariaLabelPostfix={`${setTypeText} ${currentItem.display}`}
        hasPlayedBefore={videoHasPlayedBefore}
        onFinish={() => setVideoHasFinished(true)}
      />
    </DemoVideoWrapper>
  );
}
