/* eslint-disable max-len */
import { WRITING_LINE_HEIGHT_LARGE, WRITING_LINE_HEIGHT_NORMAL, WRITING_LINE_HEIGHT_SMALL } from '../shared/constants';
import {
  ActivityType, CharacterType, CharacterCategory, Character, KinestheticSetType, PracticeLinesRelationship, StrokeIntersectionType, DifficultyLevel, GradingSettings, RestrictedAreaType,
} from './types/index';

const gradingDefaults: GradingSettings = {
  pointRadius: 25,
  pathStrokeWidth: 20,
  strokePathAccuracyThreshold: 80,
};

export const characterTypeList = (Object.keys(CharacterType) as (keyof typeof CharacterType)[]);

export const characters: {
  [key in CharacterType]: Character;
} = {
  [CharacterType.NUMBER_0]: {
    type: CharacterType.NUMBER_0,
    category: CharacterCategory.NUMBER,
    display: '0',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M65.9386 59.0436C65.9386 91.7219 51.5233 117.906 32.9036 117.906 14.2839 117.906 0 91.7031 0 59.0436 0 26.384 14.4152.2938 32.9036.2938 51.3919.2938 65.9386 26.4777 65.9386 59.0248V59.0436ZM59.5755 59.0436C59.5755 29.8564 47.5629 6.7882 32.9036 6.7882 18.2443 6.7882 6.363 29.8564 6.363 59.0436 6.363 88.2307 18.3757 111.4119 32.9036 111.4119 47.4315 111.4119 59.5755 88.3432 59.5755 59.0436Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: -4 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 105 },
            },
            rotationDegrees: 0,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            orderId: 2,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 50 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 28, y: -4 },
            },
            orderId: 4,
            allowIntersectionAfterOrderId: 2,
            visibleAfterOrderId: 0, // Stop dot should only appear after stroke passes the start dot.
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -10,
        positionY: -35,
        frames: [253, 460],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
    },
  },
  [CharacterType.NUMBER_1]: {
    type: CharacterType.NUMBER_1,
    category: CharacterCategory.NUMBER,
    display: '1',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 34,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M31.5989 0.299805V120.3H25.1845V15.191L4.48439 34.9261L0 30.3282L31.5989 0.299805Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 18 },
            },
            rotationDegrees: 45,
            rotationOffsetX: -4,
            rotationOffsetY: 6,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: 10 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 10,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 55 },
            },
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 110 },
            },
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -17,
        positionY: -35,
        frames: [246, 390],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_2]: {
    type: CharacterType.NUMBER_2,
    category: CharacterCategory.NUMBER,
    display: '2',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 28,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M64.8649 41.435C64.8649 41.3202 64.1379 43.1569 64.1379 43.1569C60.7131 50.3891 55.5664 57.4873 51.1659 63.4758C46.8802 69.3495 42.5945 75.1085 38.194 80.8674C33.6595 86.8751 29.1251 92.8636 24.6098 98.986L13.2259 113.68H64.4057V120.3H0.119995L19.3483 94.949C23.8827 88.9605 28.532 82.9529 33.0664 76.9452C37.3521 71.1863 41.7527 65.4274 46.0384 59.5536C50.3241 53.6799 55.1072 46.9452 58.7807 39.3495C59.393 37.0154 59.8904 34.5664 59.8904 31.8878C59.8904 17.921 48.87 6.9197 34.9032 6.9197C20.9363 6.9197 9.9159 17.9401 9.9159 33.1123H3.42994C3.42994 14.6302 17.7603 0.299805 34.9032 0.299805C52.046 0.299805 66.3764 14.745 66.3764 31.8878C66.3764 35.1978 65.8789 38.5077 64.9032 41.435H64.8649Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -1, y: 20 },
            },
            rotationDegrees: 25,
            rotationOffsetX: -4,
            rotationOffsetY: 4,
            orderId: 0,
            radius: 18,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 6, y: 4 },
            },
            orderId: 1,
            radius: 8,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 28, y: -4 },
            },
            orderId: 2,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 52, y: 18 },
            },
            orderId: 3,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 50 },
            },
            orderId: 4,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: 90 },
            },
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -1, y: 105 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            orderId: 6,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: 110 },
            },
            radius: 15,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 58, y: 105 },
            },
            orderId: 8,
          },
        ],
        restrictedAreas: [
          {
            id: '1',
            type: RestrictedAreaType.MID_STROKE,
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 0 },
            },
            points: [
              0, 0,
              2, -2,
              20, 18,
              18, 20,
              0, 0,
            ],
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -9,
        positionY: -35,
        frames: [252, 421],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_3]: {
    type: CharacterType.NUMBER_3,
    category: CharacterCategory.NUMBER,
    display: '3',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 28,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 4,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M61.3156 88.5419C61.3156 105.685 46.9852 120.13 29.8424 120.13C17.234 120.13 5.71612 112.419 0.339844 99.6771L6.3475 97.1133C10.748 107.636 19.7021 113.51 29.8615 113.51C43.8284 113.51 54.8488 102.49 54.8488 88.5419C54.8488 74.5942 43.8284 63.4398 29.8615 63.4398L28.9623 59.4411L29.8615 59.2689C43.8284 56.8199 54.8488 45.7995 54.8488 31.7179C54.8488 17.6363 43.8284 6.74978 29.8615 6.74978C19.7021 6.74978 10.7671 12.6235 6.3475 23.1465L0.339844 20.5827C5.73525 7.84034 17.234 0.129883 29.8424 0.129883C46.9852 0.129883 61.3156 14.575 61.3156 31.7179C61.3156 45.0534 52.7442 56.686 44.058 60.1299C52.7442 63.5546 61.3156 75.1873 61.3156 88.5419Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 18 },
            },
            rotationDegrees: 15,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: -4 },
            },
            radius: 12,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 20 },
            },
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 32, y: 44 },
            },
            orderId: 3,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 50 },
            },
            rotationDegrees: 105,
            rotationOffsetX: 4,
            rotationOffsetY: 16,
            visible: true,
            orderId: 4,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 32, y: 60 },
            },
            orderId: 5,
            radius: 12,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 85 },
            },
            radius: 15,
            orderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 105 },
            },
            radius: 12,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 85 },
            },
            orderId: 8,
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -9,
        positionY: -38,
        frames: [251, 421],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_4]: {
    type: CharacterType.NUMBER_4,
    category: CharacterCategory.NUMBER,
    display: '4',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 1,
          height: WRITING_LINE_HEIGHT_NORMAL - 6,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M72.5998 56.9377V63.4371H.0898V.1299H6.5893V56.9377H72.5998Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 20 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 48 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            orderId: 2,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 48 },
            },
            orderId: 3,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: 48 },
            },
            orderId: 4,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M57.2619 56.9377H57.2619V120.13H50.7625V.1299H57.2619V56.9377Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 43, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 43, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 43, y: 100 },
            },
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 100,
              },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -19,
        positionY: -39,
        frames: [251, 465],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.NUMBER_5]: {
    type: CharacterType.NUMBER_5,
    category: CharacterCategory.NUMBER,
    display: '5',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M51.5962 112.039C45.346 117.312 37.6004 120.13 29.6247 120.13C28.3976 120.13 26.9213 120.13 25.5793 119.881C15.878 118.769 6.92453 113.247 0.310059 104.063L5.58247 100.267C11.2192 108.243 18.2171 112.423 26.327 113.515C34.0535 114.493 41.3006 112.173 47.1865 107.016C60.1854 95.8576 61.5467 74.6338 50.1391 61.1364C44.8667 54.8862 37.3703 51.3202 29.2795 51.3202C21.1888 51.3202 13.3281 54.637 7.55722 61.1364L1.92053 67.6359V0.129883H57.5013V6.62932H8.42V51.416C12.7146 47.7349 20.8053 44.6674 29.2795 44.7824C39.0958 44.8974 48.6628 49.4413 55.1622 57.0527C68.5446 72.8891 66.8191 98.6376 51.5962 112.02V112.039Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: -4 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: -4 },
            },
            orderId: 1,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -4 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 15,
            rotationOffsetY: 16,
            visible: true,
            orderId: 2,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 10 },
            },
            orderId: 3,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 54 },
            },
            rotationDegrees: 30,
            rotationOffsetX: -2,
            rotationOffsetY: 5,
            visible: true,
            orderId: 4,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 8, y: 42 },
            },
            orderId: 5,
            radius: 8,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 50 },
            },
            orderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 100 },
            },
            orderId: 7,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 90 },
            },
            orderId: 8,
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -39,
        frames: [251, 450],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_6]: {
    type: CharacterType.NUMBER_6,
    category: CharacterCategory.NUMBER,
    display: '6',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M64.6596 87.8674C64.6596 105.528 49.9329 120.14 32.2723 120.14C14.6117 120.14 0 105.528 0 87.8674C0 83.2077 1.51487 78.8549 2.70375 74.8664C3.89262 70.8779 7.24835 56.2087 26.5197 0.139648H33.2694C25.9061 21.6162 16.3375 50.9354 13.0202 61.5011C15.3596 59.2959 23.5666 55.6142 32.2915 55.6142C49.9521 55.6142 64.6788 70.2259 64.6788 87.8865L64.6596 87.8674ZM58.1592 87.8674C58.1592 73.3899 46.7498 62.0955 32.2723 62.0955C17.7949 62.0955 6.50051 73.3899 6.50051 87.8674C6.50051 102.345 17.9099 113.639 32.2723 113.639C46.6347 113.639 58.1592 102.23 58.1592 87.8674Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: -2 },
            },
            rotationDegrees: -170,
            rotationOffsetX: 17,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: 30 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 87 },
            },
            rotationDegrees: 170,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            orderId: 2,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 105 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 52, y: 75 },
            },
            orderId: 4,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 50 },
            },
            orderId: 5,
            radius: 20,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 7, y: 58 },
            },
            orderId: 6,
            radius: 20,
            allowIntersectionAfterOrderId: 4,
            visibleAfterOrderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -39,
        frames: [251, 425],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_7]: {
    type: CharacterType.NUMBER_7,
    category: CharacterCategory.NUMBER,
    display: '7',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M67.4768 0.129883L7.36208 120.13H0.129883L56.9347 6.63498H1.3544V0.129883H67.4768Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: -3 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: -3 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 54, y: -4 },
            },
            rotationDegrees: 199,
            rotationOffsetX: 16,
            rotationOffsetY: 14,
            visible: true,
            radius: 12,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 20 },
            },
            radius: 15,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 50 },
            },
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 1, y: 101 },
            },
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 100,
              },
            },
            orderId: 5,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -37,
        frames: [251, 421],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_8]: {
    type: CharacterType.NUMBER_8,
    category: CharacterCategory.NUMBER,
    display: '8',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M62.0626 86.5857C62.0626 103.4652 47.9341 117.612 31.0361 117.612 14.1382 117.612.0098 103.4838.0098 86.5857.0098 73.5238 8.5056 62.1463 17.02 58.7967 8.5243 55.447.0098 44.0695.0098 31.0076.0098 14.1284 14.1382 0 31.0361 0 47.9341 0 62.0626 14.1284 62.0626 31.0076 62.0626 44.0695 53.5668 55.4283 45.0523 58.7967 53.548 62.1463 62.0626 73.5238 62.0626 86.5857ZM30.9239 55.5593H31.0361C44.809 53.164 55.5877 44.7805 55.5877 31.0076 55.5877 17.2348 44.809 6.3437 31.0361 6.3437 17.2633 6.3437 6.3722 17.2348 6.3722 31.0076 6.3722 44.7805 17.2633 55.5593 30.9239 55.5593ZM55.5877 86.5857C55.5877 72.8128 44.809 62.0341 31.0361 62.0341H30.9239C17.2633 62.0341 6.3722 72.8128 6.3722 86.5857 6.3722 100.3583 17.2633 111.2492 31.0361 111.2492 44.809 111.2492 55.5877 100.3583 55.5877 86.5857Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 19, y: 0 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 25 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 50 },
            },
            radius: 15,
            rotationDegrees: 97,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            allowIntersectionAfterOrderId: 1,
            visible: true,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 75 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 100 },
            },
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 75 },
            },
            radius: 20,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 50 },
            },
            radius: 20,
            rotationDegrees: 80,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 6,
            visible: true,
            allowIntersectionAfterOrderId: 5,
            visibleAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 25 },
            },
            radius: 20,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 0 },
            },
            orderId: 8,
            allowIntersectionAfterOrderId: 7,
            visibleAfterOrderId: 0,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -36,
        frames: [251, 513],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.NUMBER_9]: {
    type: CharacterType.NUMBER_9,
    category: CharacterCategory.NUMBER,
    display: '9',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 26,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.START_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M60.0118 30.1896V117.7274H53.5108V49.1852C50.6174 53.9952 40.8846 60.1204 29.9495 60.1204 13.6031 60.1204 0 46.536 0 30.1896 0 13.8432 13.5843.1273 29.9495.1273 46.3146.1273 59.5233 13.3547 59.8804 28.2731L59.9931 30.1896H60.0118ZM53.7552 28.2731C53.2666 16.4924 43.0453 6.384 29.9495 6.384 16.8536 6.384 6.2566 16.8494 6.2566 30.1896 6.2566 43.5297 16.722 53.8825 29.9495 53.8825 42.3314 53.8825 52.1957 44.8638 53.5108 32.8388L53.7552 28.2731Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 20 },
            },
            rotationDegrees: -5,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 16,
                yMax: 35,
                xMin: 72,
                xMax: 90,
              },
            },
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 0 },
            },
            radius: 20,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 25 },
            },
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 50 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 30 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 10,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 69 },
            },
            radius: 20,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 100 },
            },
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 100,
              },
            },
            orderId: 6,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -11,
        positionY: -35,
        frames: [251, 504],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_A]: {
    type: CharacterType.UPPER_A,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.MIDDLE_LINE_ACROSS,
    display: 'A',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 20,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M4 130 L42 12 L82 130',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 108 },
            },
            rotationDegrees: 15,
            rotationOffsetX: -2,
            rotationOffsetY: 2,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 10 },
            },
            orderId: 2,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 34, y: -2 },
            },
            rotationDegrees: 160,
            rotationOffsetX: 12,
            rotationOffsetY: 16,
            visible: true,
            orderId: 3,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 10 },
            },
            orderId: 4,
            radius: 12,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 50 },
            },
            orderId: 5,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 70, y: 108 },
            },
            orderId: 6,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M10 60 L75 60',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 17, y: 52 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 52, y: 52 },
            },
            orderId: 1,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -36,
        frames: [251, 500],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_B]: {
    type: CharacterType.UPPER_B,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.BUMP,
    display: 'B',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 27,
          yOffset: 2,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 119.1022.0185 1.4368 6.4273 1.4368V119.1022Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0.5 1.5V7.5H11L23 8L30.5 9L36.5 11.5L44.5 15.5L49.5 20L53 24L54 27L55 29V33V35.5L53 39.5L50 43L43.5 47.5L38 49.5L28 53L18.5 54.5L0.5 57V63L9.5 64L24 66.5L33.5 69L40.5 71.5L45 74L48.5 76L51.5 79L53 81.5L55 84.5V89C54.8333 90.1667 54.4 92.7 54 93.5C53.6 94.3 52.5 96.1667 52 97L48 101L42.5 105L36.5 108.5L28 111L12.5 112.5H0.5V118.5H13.5L24 117.5H29.5L34.5 115.5L43.5 112L49.5 108.5L56.5 101.5L60.5 94.5L61 84.5L59 78.5L54 72.5L50 69L40.5 64L32 61.5L25 60L28 59.5L37 57L44.5 54L49.5 51L54 47.5L56.5 44L58 42L60.5 37.5L61.5 33L61 27L59 23L56 18L49.5 11.5L40.5 6L34.5 4L26 2.5L20.5 1.5L11 1L0.5 1.5Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 5 },
            },
            radius: 20,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 25 },
            },
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 45 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 55 },
            },
            radius: 20,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 75 },
            },
            radius: 20,
            orderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 95 },
            },
            radius: 20,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            radius: 20,
            orderId: 8,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -36,
        frames: [251, 500],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_C]: {
    type: CharacterType.UPPER_C,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.CIRCLE_STARTER,
    display: 'C',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 16,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M47.7193 115.368C27.3392 115.368 10.0658 95.7231 7.08333 69.0708C6.71053 66.4431 6.58626 63.8154 6.58626 61.0626C6.58626 30.7815 24.9781 6.6318 47.7193 6.6318C59.6491 6.6318 70.8333 13.2636 79.4079 26.4021L85 22.6482C75.1827 8.00821 61.7617 0 47.7193 0C21.4985 0 0 27.6533 0 61.0626C0 63.9405 0.124269 66.9436 0.497076 69.8215C3.72807 99.3518 24.3567 122 47.7193 122C61.7617 122 75.1827 113.992 85 99.4769L79.4079 95.7231C70.8333 108.736 59.6491 115.368 47.7193 115.368Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 76, y: 22 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 4,
            rotationOffsetY: 0,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 0 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 5, y: 25 },
            },
            orderId: 2,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 5, y: 80 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 108 },
            },
            orderId: 4,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 76, y: 86 },
            },
            orderId: 5,
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -36,
        frames: [251, 500],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_D]: {
    type: CharacterType.UPPER_D,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.BUMP,
    display: 'D',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 27,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 119.1022.0185 1.4368 6.4273 1.4368V119.1022Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M.1992 6.27H15.3035C45.4459 6.27 69.6512 29.8868 69.6512 59.3876 69.6512 88.8873 45.4685 112.6383 15.3035 112.6383H.1992L.1992 118.6067 15.1742 118.6067C48.8885 118.7749 76.6636 92.0001 76.6636 59.3876 76.6636 26.8888 48.8885 0 15.3035 0L.1992.3097.1992 6.27Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 15 },
            },
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 60, y: 48 },
            },
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 85 },
            },
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -36,
        frames: [251, 500],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_E]: {
    type: CharacterType.UPPER_E,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.LINE_BACK,
    display: 'E',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 25,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 4,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M80 3L-10 3M3 125L3 -3M-10 3M-10 116 L80 116',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 62, y: -3 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: -3 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -3 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 108 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            radius: 15,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 108 },
            },
            orderId: 5,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 62, y: 108 },
            },
            orderId: 6,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 60L80 60',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 50 },
            },
            orderId: 1,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -35,
        frames: [251, 536],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_F]: {
    type: CharacterType.UPPER_F,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.LINE_BACK,
    display: 'F',
    positioning: {
      [DifficultyLevel.EASY]: {
        scaffold: {
          xOffset: 75,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_LARGE,
        },
      },
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 25,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M 13.9927 13.9894 V 258 H 0 V 0 H 163 V 13.9894 H 14.0339 H 13.9927 Z',
          [DifficultyLevel.NORMAL]: 'M80 3L-10 3M3 125L3 -3',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 135, y: -9 },
              [DifficultyLevel.NORMAL]: { x: 62, y: -3 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 60, y: -9 },
              [DifficultyLevel.NORMAL]: { x: 30, y: -3 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -9, y: -9 },
              [DifficultyLevel.NORMAL]: { x: -2, y: -3 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            orderId: 2,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -9, y: 110 },
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -9, y: 230 },
              [DifficultyLevel.NORMAL]: { x: -2, y: 110 },
            },
            orderId: 4,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.EASY]: 'M 13.9927 121.737 V 121.737 H 128.906 V 135.727 H 13.9927 Z',
          [DifficultyLevel.NORMAL]: 'M0 60L80 60',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -9, y: 110 },
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 100, y: 110 },
              [DifficultyLevel.NORMAL]: { x: 50, y: 50 },
            },
            orderId: 1,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -35,
        frames: [251, 500],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_G]: {
    type: CharacterType.UPPER_G,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.CIRCLE_STARTER,
    display: 'G',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 18,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 1,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M41.3107 57.4484V64.0531H78.3652C77.6141 92.3412 61.8409 115.271 42.5626 115.271C22.6583 115.271 6.63476 91.2196 6.63476 60.9377C6.63476 30.7804 22.6583 6.6047 42.5626 6.6047C52.7025 6.6047 62.2165 13.0848 69.8527 25.7957L75.486 22.3064C66.8483 7.97549 54.9558 0 42.5626 0C19.1532 0 0 27.665 0 60.9377C0 94.335 19.1532 122 42.5626 122C65.8468 122 85 94.2104 85 60.8131V57.4484H41.3107Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 66, y: 20 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 4,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 0 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 5, y: 25 },
            },
            orderId: 2,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 5, y: 80 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 108 },
            },
            orderId: 4,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 66, y: 85 },
            },
            radius: 20,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 73, y: 53 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            visible: true,
            orderId: 6,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 37, y: 53 },
            },
            orderId: 7,
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -36,
        frames: [251, 500],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      strokePathAccuracyThreshold: 95,
    },
  },
  [CharacterType.UPPER_H]: {
    type: CharacterType.UPPER_H,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.MIDDLE_LINE_ACROSS,
    display: 'H',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 20,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M5 -8 L5 125',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -4 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 108 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M75 -8 L75 125',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: -4 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: 108 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-3',
        order: 3,
        paths: {
          [DifficultyLevel.NORMAL]: 'M-7 58 L88 58',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: 50 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -35,
        frames: [251, 524],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 30,
    },
  },
  [CharacterType.UPPER_I]: {
    type: CharacterType.UPPER_I,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.STRAIGHT_LINE_DOWN,
    display: 'I',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 58,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M7 122V0H0V122H7Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: -3 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: WRITING_LINE_HEIGHT_NORMAL - 10 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -36,
        frames: [251, 373],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_J]: {
    type: CharacterType.UPPER_J,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.DOWN_AND_CURVE,
    display: 'J',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M67.6883.8816V83.1054C67.6883 102.1985 52.3277 118.028 33.8348 118.028 15.3419 118.028 0 102.1797 0 83.1054V78.1914H6.358V83.1054C6.358 99.0661 18.4741 111.67 33.8536 111.67 49.2331 111.67 61.349 99.0661 61.349 83.1054V.8816H67.7071 67.6883Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 56, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 15,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 56, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 26, y: 100 },
            },
            rotationDegrees: 0,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            visible: false,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 76 },
            },
            orderId: 3,
            radius: 20,
          },
        ],
        restrictedAreas: [
          {
            id: '1',
            type: RestrictedAreaType.END_STROKE,
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -25, y: 54 },
            },
            points: [
              0, 0,
              50, 0,
              50, 14,
              0, 14,
              0, 0,
            ],
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: 2,
        positionY: -35,
        frames: [251, 421],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_K]: {
    type: CharacterType.UPPER_K,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.KICK,
    display: 'K',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 25,
          yOffset: 1,
          height: WRITING_LINE_HEIGHT_NORMAL - 6,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 119.1022.0185 1.4368 6.4273 1.4368V119.1022Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 100 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M59.5 0.5L0.5 55.5V64.5L59.5 119.5L69 120L4.5 60.5L69.5 0.5H59.5Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 0 },
            },
            rotationDegrees: -145,
            rotationOffsetX: 16,
            rotationOffsetY: 4,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 25 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            rotationDegrees: 140,
            rotationOffsetX: 12,
            rotationOffsetY: 20,
            visible: true,
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 75 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 100 },
            },
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -37,
        frames: [251, 582],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_L]: {
    type: CharacterType.UPPER_L,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.STRAIGHT_LINE_DOWN,
    display: 'L',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: -1,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M6.61426 115.375V0H0V122H77V115.375H6.61426Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: -4 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 40 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 80 },
            },
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 108 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            radius: 15,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 108 },
            },
            radius: 15,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 70, y: 108 },
            },
            orderId: 5,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -18,
        positionY: -37,
        frames: [251, 430],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_M]: {
    type: CharacterType.UPPER_M,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.UP_AND_DOWN_PALS,
    display: 'M',
    positioning: {
      [DifficultyLevel.EASY]: {
        scaffold: {
          xOffset: 45,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_LARGE,
        },
      },
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 12,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M199.148 254.767H185.671L184.931 61.8514L96.2047 258L14.9566 64.8116L13.2038 254.728H0L1.98645 0L96.4384 224.815L198.174 0.778985L199.187 254.767H199.148Z',
          [DifficultyLevel.NORMAL]: 'M92.388 117.0905H86.1943L85.8541 28.4268 45.0756 118.5762 7.734 29.7873 6.9286 117.0725H.8601L1.7731 0 45.183 103.3246 91.9405.358 92.406 117.0905H92.388Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -8, y: WRITING_LINE_HEIGHT_LARGE - 30 },
              [DifficultyLevel.NORMAL]: { x: -5, y: 101 },
            },
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -8, y: WRITING_LINE_HEIGHT_LARGE / 2 },
              [DifficultyLevel.NORMAL]: { x: -5, y: 60 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -8, y: 48 },
              [DifficultyLevel.NORMAL]: { x: -5, y: 20 },
            },
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -7, y: 0 },
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 157,
            rotationOffsetX: 11,
            rotationOffsetY: 19,
            visible: true,
            radius: 12,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 10, y: 48 },
              [DifficultyLevel.NORMAL]: { x: 5, y: 20 },
            },
            radius: 15,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 55, y: WRITING_LINE_HEIGHT_LARGE - 90 },
              [DifficultyLevel.NORMAL]: { x: 30, y: 90 },
            },
            orderId: 5,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 80, y: WRITING_LINE_HEIGHT_LARGE - 30 },
              [DifficultyLevel.NORMAL]: { x: 37, y: 110 },
            },
            rotationDegrees: 30,
            rotationOffsetX: -2,
            rotationOffsetY: 4,
            visible: true,
            radius: 12,
            orderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 110, y: WRITING_LINE_HEIGHT_LARGE - 90 },
              [DifficultyLevel.NORMAL]: { x: 45, y: 90 },
            },
            orderId: 7,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 160, y: 48 },
              [DifficultyLevel.NORMAL]: { x: 75, y: 20 },
            },
            radius: 15,
            orderId: 8,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 177, y: 0 },
              [DifficultyLevel.NORMAL]: { x: 83, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 15,
            rotationOffsetY: 16,
            visible: true,
            radius: 12,
            orderId: 9,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 177, y: 48 },
              [DifficultyLevel.NORMAL]: { x: 83, y: 20 },
            },
            radius: 15,
            orderId: 10,
            allowIntersectionAfterOrderId: 9,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 177, y: WRITING_LINE_HEIGHT_LARGE / 2 },
              [DifficultyLevel.NORMAL]: { x: 82, y: 60 },
            },
            radius: 15,
            orderId: 11,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 177, y: WRITING_LINE_HEIGHT_LARGE - 30 },
              [DifficultyLevel.NORMAL]: { x: 82, y: 101 },
            },
            orderId: 12,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -35,
        frames: [251, 610],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_N]: {
    type: CharacterType.UPPER_N,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.UP_AND_DOWN_PALS,
    display: 'N',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M74.8799 3.6447V118.7621L7.7943 20.6236V116.5789H1.6775V.7577L68.7631 98.9143V3.6447H74.8799Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -2 },
            },
            rotationDegrees: 150,
            rotationOffsetX: 9,
            rotationOffsetY: 19,
            visible: true,
            radius: 12,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 8, y: 20 },
            },
            radius: 15,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 60 },
            },
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: 100 },
            },
            visible: true,
            radius: 12,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: 70 },
            },
            radius: 15,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: 30 },
            },
            radius: 20,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: -2 },
            },
            orderId: 8,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -35,
        frames: [251, 533],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_O]: {
    type: CharacterType.UPPER_O,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.CIRCLE_STARTER,
    display: 'O',
    positioning: {
      [DifficultyLevel.EASY]: {
        scaffold: {
          xOffset: 50,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_LARGE,
        },
      },
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 16,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.EASY]: 'M94.6599 0C42.6898 0 0 58.7067 0 129.633C0 200.293 42.6898 259 94.6599 259C146.63 259 189.32 200.293 189.32 129.633C189.32 58.7067 146.63 0 94.6599 0ZM94.6599 244.921C50.1141 244.921 14.0531 193.918 14.0531 129.633C14.0531 65.3477 50.1141 14.079 94.6599 14.079C139.206 14.079 175.267 65.3477 175.267 129.633C175.267 193.918 139.206 244.921 94.6599 244.921Z',
          [DifficultyLevel.NORMAL]: 'M45 0.444824C20.5686 0.444824 0.5 28.0982 0.5 61.5074C0.5 94.7915 20.5686 122.445 45 122.445C69.4314 122.445 89.5 94.7915 89.5 61.5074C89.5 28.0982 69.4314 0.444824 45 0.444824ZM45 115.813C24.0588 115.813 7.10644 91.7884 7.10644 61.5074C7.10644 31.2264 24.0588 7.07662 45 7.07662C65.9412 7.07662 82.8936 31.2264 82.8936 61.5074C82.8936 91.7884 65.9412 115.813 45 115.813Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 75, y: -9 },
              [DifficultyLevel.NORMAL]: { x: 36, y: -4 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -9, y: 110 },
              [DifficultyLevel.NORMAL]: { x: 0, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 80, y: 230 },
              [DifficultyLevel.NORMAL]: { x: 38, y: 110 },
            },
            rotationDegrees: 0,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 165, y: 110 },
              [DifficultyLevel.NORMAL]: { x: 80, y: 50 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 78, y: -9 },
              [DifficultyLevel.NORMAL]: { x: 44, y: -4 },
            },
            orderId: 4,
            allowIntersectionAfterOrderId: 2,
            visibleAfterOrderId: 0, // Stop dot should only appear after stroke passes the start dot.
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -37,
        frames: [251, 460],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_P]: {
    type: CharacterType.UPPER_P,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.BUMP,
    display: 'P',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 27,
          yOffset: 1,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 119.1022.0185 1.4368 6.4273 1.4368V119.1022Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M21.5 0.5H1V6.5H9.5H20L28.5 7.5L38 9.5L42 12.5L50 17.5L54 22.5L56 27.5V34.5L54 41L50 45.5L43.5 50.5L36.5 54L30 55.5H22H11H1.5V61.5L13 62H25.5L32.5 61L42 58L49 54L54.5 50.5L59.5 43.5L62.5 37L63 30.5L59.5 19.5L54 12.5L45.5 6.5L38 3.5L30 1L21.5 0.5Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 5 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 25 },
            },
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 45 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -36,
        frames: [251, 478],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_Q]: {
    type: CharacterType.UPPER_Q,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.CIRCLE_STARTER,
    display: 'Q',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 16,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M45 0.444824C20.5686 0.444824 0.5 28.0982 0.5 61.5074C0.5 94.7915 20.5686 122.445 45 122.445C69.4314 122.445 89.5 94.7915 89.5 61.5074C89.5 28.0982 69.4314 0.444824 45 0.444824ZM45 115.813C24.0588 115.813 7.10644 91.7884 7.10644 61.5074C7.10644 31.2264 24.0588 7.07662 45 7.07662C65.9412 7.07662 82.8936 31.2264 82.8936 61.5074C82.8936 91.7884 65.9412 115.813 45 115.813Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 36, y: -4 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 50 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 38, y: 110 },
            },
            rotationDegrees: 0,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 80, y: 50 },
            },
            orderId: 3,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 44, y: -4 },
            },
            orderId: 4,
            allowIntersectionAfterOrderId: 2,
            visibleAfterOrderId: 0, // Stop dot should only appear after stroke passes the start dot.
            radius: 20,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M45 70 L95 130',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 75 },
            },
            rotationDegrees: 130,
            rotationOffsetX: 8,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 78, y: 110 },
            },
            orderId: 1,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -36,
        frames: [251, 564],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_R]: {
    type: CharacterType.UPPER_R,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.BUMP_AND_KICK,
    display: 'R',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 4,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 119.1022.0185 1.4368 6.4273 1.4368V119.1022Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 100 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M1.5 3V9L11.5 7L17 6H25.5L33.5 7L43 9L48.5 13L52 18.5L54.5 25L55 30.5L54.5 35L51.5 39L46.5 44L39.5 48.5L25.5 53L15.5 55L1.5 57L2 62L59.5 118H68L10 62L24.5 59.5L35.5 56L42 54L48 50L54 46.5L57.5 41.5L59.5 37.5L61 31L60.5 22.5L56 12.5L49 6L43 3L31.5 0.5H15.5L1.5 3Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 5 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 25 },
            },
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 45 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 50 },
            },
            rotationDegrees: 130,
            rotationOffsetX: 6,
            rotationOffsetY: 20,
            visible: true,
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 75 },
            },
            radius: 20,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 57, y: 100 },
            },
            orderId: 6,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -1,
        positionY: -35,
        frames: [251, 582],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_S]: {
    type: CharacterType.UPPER_S,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.SUPER_SQUIGGLY,
    display: 'S',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 26,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M69.6779 84.782C71.2438 90.8197 70.5268 97.2156 67.2629 102.895 62.3194 111.3477 52.7723 117.0267 40.9424 118.5928 38.7728 118.8381 36.4708 119.0834 34.1878 119.0834 21.3954 119.0834 8.5844 114.4985.0184 105.9138L4.6033 101.4422C13.1692 109.895 27.0559 114.008 40.2254 112.1966 50.2442 110.876 57.7346 106.5175 61.6024 99.6308 64.1308 95.4046 64.7346 91.1781 63.414 86.4614 61.7158 79.9331 56.4139 73.7822 48.5651 69.3104 43.98 66.6501 38.6594 64.5935 33.584 62.6691 25.2444 59.4051 16.5655 56.0276 9.7919 49.8768 2.5468 43.3486-.8306 34.8958.6221 26.4432 2.4336 16.8961 10.2823 8.4435 21.5088 4.2172 38.0556-2.0659 58.6971 1.9153 71.0175 14.5943L66.4326 19.0659C55.6781 7.9528 38.3009 4.5755 23.9238 10.1227 14.5089 13.8585 8.2259 20.2736 7.0185 27.6319 5.8108 33.9147 8.2259 39.9524 14.1503 45.1409 19.9428 50.3296 27.6785 53.4806 35.9989 56.7257 41.1876 58.7823 46.6216 60.8389 51.6969 63.6124 60.7534 68.8011 67.5081 76.65 69.6967 84.7443L69.6779 84.782Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 57, y: 10 },
            },
            rotationDegrees: -45,
            rotationOffsetX: 5,
            rotationOffsetY: -3,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 0 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 25 },
            },
            radius: 20,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 50 },
            },
            radius: 20,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 57, y: 80 },
            },
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 105 },
            },
            radius: 20,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 96 },
            },
            orderId: 6,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: 0,
        positionY: -35,
        frames: [251, 475],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_T]: {
    type: CharacterType.UPPER_T,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.STRAIGHT_LINE_DOWN,
    display: 'T',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M38 -6L38 126',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 29, y: -2 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 29, y: 50 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 29, y: 108 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M-10 6L92 6',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: -5 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: -5 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 72, y: -5 },
            },
            radius: 20,
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -35,
        frames: [251, 490],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 25,
    },
  },
  [CharacterType.UPPER_U]: {
    type: CharacterType.UPPER_U,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.DOWN_AND_CURVE,
    display: 'U',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M76.5008.8821V72.4661C76.5008 97.3347 59.3274 117.8677 38.419 117.8677 17.5106 117.8677.3372 97.3347.3372 72.4661V.8821H6.6998V72.4661C6.6998 94.0877 20.8702 111.5051 38.4004 111.5051 55.9304 111.5051 70.1006 94.2003 70.1006 72.4661V.8821H76.4633 76.5008Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 85 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 105 },
            },
            orderId: 2,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 60, y: 85 },
            },
            orderId: 3,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 64, y: 0 },
            },
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -35,
        frames: [251, 479],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_V]: {
    type: CharacterType.UPPER_V,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.DOWN_AND_UP_PALS,
    display: 'V',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M79.2008 1.5332 39.6004 119.3739 0 1.5332H6.6124L39.6004 99.7583 72.5698 1.5332H79.1822 79.2008Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: -2 },
            },
            rotationDegrees: 155,
            rotationOffsetX: 12,
            rotationOffsetY: 16,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 8, y: 30 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 18, y: 65 },
            },
            orderId: 2,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 34, y: 100 },
            },
            rotationDegrees: 20,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            visible: true,
            orderId: 3,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 65 },
            },
            orderId: 4,
            radius: 15,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 58, y: 30 },
            },
            orderId: 5,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: -4 },
            },
            orderId: 6,
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -35,
        frames: [251, 475],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_W]: {
    type: CharacterType.UPPER_W,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.DOWN_AND_UP_PALS,
    display: 'W',
    positioning: {
      [DifficultyLevel.EASY]: {
        scaffold: {
          xOffset: 0,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_LARGE,
        },
      },
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 0,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M270.242 0.0276718L209.387 258.297L134.863 33.9978L57.0119 256.5L0 0.0276718H13.7082L59.8645 207.441L132.328 0.0276718H137.914L206.891 208.719L256.336 0.0276718H270.282H270.242Z',
          [DifficultyLevel.NORMAL]: 'M125.8476 1.5247 97.6324 120.3741 63.0797 17.1571 26.984 119.5475.5508 1.5247H6.9066L28.3066 96.9717 61.904 1.5247H64.4942L96.4752 97.5595 119.3999 1.5247H125.8659 125.8476Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -7, y: 0 },
              [DifficultyLevel.NORMAL]: { x: -3, y: -2 },
            },
            rotationDegrees: 157,
            rotationOffsetX: 12,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 15, y: 90 },
              [DifficultyLevel.NORMAL]: { x: 8, y: 40 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 30, y: 167 },
              [DifficultyLevel.NORMAL]: { x: 15, y: 80 },
            },
            orderId: 2,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 45, y: 225 },
              [DifficultyLevel.NORMAL]: { x: 20, y: 100 },
            },
            rotationDegrees: 20,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            visible: true,
            orderId: 3,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 60, y: 167 },
              [DifficultyLevel.NORMAL]: { x: 30, y: 80 },
            },
            orderId: 4,
            radius: 15,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 95, y: 55 },
              [DifficultyLevel.NORMAL]: { x: 45, y: 20 },
            },
            orderId: 5,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 118, y: 0 },
              [DifficultyLevel.NORMAL]: { x: 55, y: -2 },
            },
            rotationDegrees: 157,
            rotationOffsetX: 12,
            rotationOffsetY: 16,
            visible: true,
            orderId: 6,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 140, y: 55 },
              [DifficultyLevel.NORMAL]: { x: 65, y: 20 },
            },
            orderId: 7,
            radius: 15,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 175, y: 167 },
              [DifficultyLevel.NORMAL]: { x: 80, y: 80 },
            },
            orderId: 8,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 195, y: 225 },
              [DifficultyLevel.NORMAL]: { x: 90, y: 100 },
            },
            rotationDegrees: 20,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            visible: true,
            orderId: 9,
            radius: 12,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 205, y: 167 },
              [DifficultyLevel.NORMAL]: { x: 100, y: 80 },
            },
            orderId: 10,
            radius: 15,
            allowIntersectionAfterOrderId: 9,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 220, y: 90 },
              [DifficultyLevel.NORMAL]: { x: 105, y: 40 },
            },
            orderId: 11,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 245, y: 0 },
              [DifficultyLevel.NORMAL]: { x: 112, y: -4 },
            },
            orderId: 12,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -35,
        frames: [251, 610],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.UPPER_X]: {
    type: CharacterType.UPPER_X,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.UPPER_CRISS_CROSS,
    display: 'X',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 22,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 -10 L75 125',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -4 },
            },
            rotationDegrees: 150,
            rotationOffsetX: 12,
            rotationOffsetY: 18,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 60, y: 105 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M80 -10 L0 125',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 60, y: -4 },
            },
            rotationDegrees: -150,
            rotationOffsetX: 20,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 50 },
            },
            radius: 20,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 105 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -35,
        frames: [251, 495],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 30,
    },
  },
  [CharacterType.UPPER_Y]: {
    type: CharacterType.UPPER_Y,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.UPPER_CRISS_CROSS,
    display: 'Y',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 18,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 4,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M0 -10 L48 75',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: -4 },
            },
            rotationDegrees: 150,
            rotationOffsetX: 12,
            rotationOffsetY: 18,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 18, y: 25 },
            },
            radius: 20,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 33, y: 50 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M80 -10 L40 65 L40 130',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 65, y: -4 },
            },
            rotationDegrees: -150,
            rotationOffsetX: 20,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 25 },
            },
            radius: 15,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 33, y: 50 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 12,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 33, y: 75 },
            },
            radius: 15,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 33, y: 105 },
            },
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -7,
        positionY: -37,
        frames: [251, 535],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 30,
    },
  },
  [CharacterType.UPPER_Z]: {
    type: CharacterType.UPPER_Z,
    category: CharacterCategory.LETTER_UPPER,
    kinestheticGroupType: KinestheticSetType.ZIP_FORWARD_BACK,
    display: 'Z',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 20,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M-10 5 L75 5 L5 115 L100 115',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: -4 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: -4 },
            },
            radius: 20,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 70, y: -4 },
            },
            rotationDegrees: 210,
            rotationOffsetX: 18,
            rotationOffsetY: 12,
            visible: true,
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 20 },
            },
            radius: 15,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 50 },
            },
            radius: 20,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: 80 },
            },
            radius: 15,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 105 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            radius: 15,
            orderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 105 },
            },
            radius: 20,
            orderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 70, y: 105 },
            },
            orderId: 8,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -38,
        frames: [251, 543],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_A]: {
    type: CharacterType.LOWER_A,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LINE_COMES_LATER,
    display: 'a',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.START_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M56 59H52.3809V48.5453C49.4718 53.3089 40.0536 59.2544 29.5809 59.2544 13.6355 59.2544.4901 45.9816.4901 30.1635.4901 14.3453 13.6355 1.1816 29.5809 1.1816 40.0536 1.1816 49.4718 6.9998 52.3809 11.7635V1.2907H56ZM52.3809 30.1635C52.3809 17.4725 42.2536 7.3635 29.5809 7.3635 16.9082 7.3635 6.6537 17.4907 6.6537 30.1635 6.6537 42.8362 16.781 53.0907 29.5809 53.0907 42.3809 53.0907 52.3809 42.9635 52.3809 30.1635Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 13 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 4,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                xMin: 82,
              },
            },
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 25 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 46, y: 25 },
            },
            orderId: 2,
            radius: 10,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: -4 },
            },
            visible: true,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 3,
            radius: 15,
            allowIntersectionAfterOrderId: 2,
            visibleAfterOrderId: 1, // Midpoint dot should only appear after stroke begins to loop.
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 46, y: 25 },
            },
            orderId: 4,
            radius: 10,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 50 },
            },
            orderId: 5,
            radius: 20,
            allowIntersectionAfterOrderId: 4,
            visibleAfterOrderId: 3, // Stop dot should only appear after stroke passes the closest midpoint dot.
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 104,
                yMax: 118,
                xMin: 90,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: 0,
        positionY: -39,
        frames: [251, 490],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
    },
  },
  [CharacterType.LOWER_B]: {
    type: CharacterType.LOWER_B,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_STARTERS,
    display: 'b',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M61.1193 88.2191C61.1193 104.8039 47.3333 118.4764 30.7485 118.4764 19.7424 118.4764 9.9278 112.4249 6.902 107.3379V118.2305H.4912V.1135H6.902V69.1003C9.9278 64.1457 19.7235 57.9618 30.7485 57.9618 47.3333 57.9618 61.1193 71.6343 61.1193 88.2191ZM54.5761 88.2191C54.5761 74.9058 44.1752 64.3726 30.7296 64.3726 17.284 64.3726 6.8831 74.9058 6.8831 88.2191 6.8831 101.5323 17.4164 112.0656 30.7296 112.0656 44.0428 112.0656 54.5761 101.5323 54.5761 88.2191Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            radius: 15,
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 65 },
            },
            radius: 10,
            visible: false,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 55 },
            },
            radius: 10,
            visible: false,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 75 },
            },
            radius: 15,
            visible: false,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            radius: 15,
            visible: false,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 3, y: WRITING_LINE_HEIGHT_NORMAL - 27 },
            },
            allowIntersectionAfterOrderId: 5,
            visibleAfterOrderId: 2, // Stop dot should only appear after stroke passes the closest midpoint dot.
            orderId: 6,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                xMin: 25,
                xMax: 50,
                yMin: 80,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -38,
        frames: [251, 493],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
  },
  [CharacterType.LOWER_C]: {
    type: CharacterType.LOWER_C,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_CIRCLE_STARTER,
    display: 'c',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M47.1273 43.6545L51.8909 47.7273C45.6 54.9454 37.4545 58.7818 29.0909 58.7818C13.2727 58.7818 0 45.5273 0 29.5818C0 13.6364 13.2727 0.490936 29.0909 0.490936C37.4727 0.490936 45.6182 4.21821 51.8909 11.5455L47.1273 15.5091C42 9.58185 35.8364 6.67275 29.0909 6.67275C16.2909 6.67275 6.16364 16.8 6.16364 29.6C6.16364 42.4 16.2909 52.6364 29.0909 52.6364C35.8364 52.6364 42 49.7273 47.1273 43.6727V43.6545Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 6 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 4,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: -2 },
            },
            orderId: 1,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 25 },
            },
            orderId: 2,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 45 },
            },
            orderId: 3,
            radius: 15,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 42 },
            },
            orderId: 4,
            radius: 20,
            allowIntersectionAfterOrderId: 3,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 95,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 411],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
      strokePathAccuracyThreshold: 100,
    },
  },
  [CharacterType.LOWER_D]: {
    type: CharacterType.LOWER_D,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LINE_COMES_LATER,
    display: 'd',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 27,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 4,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.START_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M56.9533 118.971H55.3628V108.114C52.4295 113.105 42.5628 119.2 31.3438 119.2 14.7723 119.2.8676 105.429.8676 88.7238.8676 72.0191 14.7533 58.2476 31.3438 58.2476 42.5628 58.2476 52.4295 64.4571 55.3628 69.4667V0H56.9533V118.971ZM55.3628 88.7429C55.3628 75.3333 44.8867 64.7238 31.3438 64.7238 17.8009 64.7238 7.3247 75.3333 7.3247 88.7429 7.3247 102.152 17.9342 112.762 31.3438 112.762 44.7533 112.762 55.3628 102.152 55.3628 88.7429Z',
        },
        points: [
          { // start dot is stricter to encourage touching the vertical line
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 48, y: 69 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 4,
            rotationOffsetY: 0,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 85 },
            },
            orderId: 1,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 35, y: 100 },
            },
            orderId: 2,
            radius: 15,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 85 },
            },
            orderId: 3,
            radius: 10,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 20 },
            },
            orderId: 4,
            radius: 20,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: -4 },
            },
            visible: true,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 5,
            radius: 20,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 85 },
            },
            orderId: 6,
            radius: 10,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 110 },
            },
            orderId: 7,
            radius: 20,
            allowIntersectionAfterOrderId: 6,
            visibleAfterOrderId: 5, // Stop dot should only appear after stroke passes the closest midpoint dot.
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 104,
                yMax: 122,
                xMin: 76,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 570],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
      strokePathAccuracyThreshold: 70,
    },
  },
  [CharacterType.LOWER_E]: {
    type: CharacterType.LOWER_E,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOOP_AROUND,
    display: 'e',
    positioning: {
      [DifficultyLevel.EASY]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_SMALL / 2,
          height: WRITING_LINE_HEIGHT_SMALL / 2,
        },
        scaffold: {
          xOffset: 75,
          yOffset: (WRITING_LINE_HEIGHT_LARGE / 2) - 7,
          height: (WRITING_LINE_HEIGHT_LARGE / 2) + 5,
        },
      },
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 32,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M 131.1828 62.8722 V 72.303 H 14.0349 C 14.7153 80.2376 17.5054 87.8457 22.1162 94.3388 C 26.7271 100.832 32.9908 105.9729 40.2578 109.2306 C 61.9047 119.4172 89.401 115.3582 111.5653 96.7359 L 120.2404 107.201 C 105.105 120.4135 85.8107 127.8909 65.7248 128.331 C 54.9981 128.35 44.3997 125.9992 34.687 121.4468 C 24.0492 116.5397 15.0905 108.6108 8.9274 98.6476 C 2.7644 88.6848 -0.3307 77.1285 0.028 65.4189 C 0.1941 48.1133 7.1452 31.5641 19.3862 19.3304 C 31.6272 7.0966 48.1805 0.1556 65.486 0 C 101.2988 0 130.9442 29.7649 130.9442 62.8722 H 131.1828 Z M 117.1757 58.8929 C 115.1462 34.4603 93.3006 13.5693 65.4462 13.5693 C 37.5917 13.5693 15.7856 34.4603 13.995 58.8929 H 117.1757 Z',
          [DifficultyLevel.NORMAL]: 'M61.7757 29.3953V33.8046H7.00372C7.32184 37.5144 8.62638 41.0714 10.7822 44.1073C12.9379 47.143 15.8665 49.5469 19.2641 51.0697C29.3851 55.8325 42.2409 53.9348 52.6036 45.2279L56.6595 50.1209C49.583 56.298 40.5622 59.7944 31.1711 59.9999C26.1559 60.0091 21.2007 58.9099 16.6595 56.7813C11.6859 54.487 7.49727 50.7799 4.61574 46.1218C1.73422 41.4638 0.287157 36.0608 0.454861 30.586C0.532522 22.4949 3.78246 14.7575 9.50571 9.03774C15.229 3.31797 22.9683 0.0727428 31.0595 0C47.8036 0 61.6641 13.9163 61.6641 29.3953H61.7757ZM55.2269 27.5349C54.2781 16.1116 44.0641 6.34418 31.0409 6.34418C18.0176 6.34418 7.82231 16.1116 6.9851 27.5349H55.2269Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -2, y: 52 },
              [DifficultyLevel.NORMAL]: { x: -2, y: 22 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 64, y: 52 },
              [DifficultyLevel.NORMAL]: { x: 25, y: 21 },
            },
            radius: 12,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 117, y: 52 },
              [DifficultyLevel.NORMAL]: { x: 50, y: 20 },
            },
            rotationDegrees: -2,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            radius: 14,
            visible: true,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 55, y: -4 },
              [DifficultyLevel.NORMAL]: { x: 24, y: -4 },
            },
            radius: 15,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -2, y: 52 },
              [DifficultyLevel.NORMAL]: { x: -2, y: 22 },
            },
            radius: 15,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 94, y: 99 },
              [DifficultyLevel.NORMAL]: { x: 40, y: 44 },
            },
            radius: 20,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
            minMax: {
              [DifficultyLevel.EASY]: {
                yMin: 196,
              },
              [DifficultyLevel.NORMAL]: {
                yMin: 98,
              },
            },
          },
        ],
        restrictedAreas: [
          {
            id: '1',
            type: RestrictedAreaType.END_STROKE,
            coordinates: {
              [DifficultyLevel.EASY]: { x: 105, y: 85 },
              [DifficultyLevel.NORMAL]: { x: 46, y: 37 },
            },
            points: [
              0, 0,
              20, 0,
              20, 6,
              0, 6,
              0, 0,
            ],
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -39,
        frames: [251, 485],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 12,
      strokePathAccuracyThreshold: 98,
    },
  },
  [CharacterType.LOWER_F]: {
    type: CharacterType.LOWER_F,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_HOOK,
    display: 'f',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 35,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M65.911 23.971 59.47 25.187C57.893 16.922 55.822 9.873 49.381 7.308 45.619 5.731 41.477 6.092 38.076 8.163 34.922 10.234 32.357 13.635 30.666 18.252 29.336 22.755 28.481 28.227 28.481 34.06V118.325H22.021V34.06C22.021 27.619 22.876 21.406 24.567 16.314 26.505 10.234 30.286 5.256 34.789 2.577 39.767-.349 46.455-.824 51.927 1.361 61.047 5.123 64.201 14.984 65.911 23.971Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 54, y: 15 },
            },
            rotationDegrees: -10,
            radius: 27,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 17, y: 50 },
            },
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 17, y: 108 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M-14 60H70',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 51 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 51 },
            },
            radius: 15,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 51 },
            },
            orderId: 2,
            allowIntersectionAfterOrderId: 1,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: 2,
        positionY: -37,
        frames: [251, 530],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 23,
      strokePathAccuracyThreshold: 70,
    },
  },
  [CharacterType.LOWER_G]: {
    type: CharacterType.LOWER_G,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HOOK,
    display: 'g',
    practiceLinesRelationship: PracticeLinesRelationship.BELOW_LINES,
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 1.33,
        },
        scaffold: {
          xOffset: 30,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 1.33,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.START_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 54.6752 14.0063 C 51.9781 10.2952 48.4427 7.2741 44.3568 5.1888 C 40.2708 3.1034 35.7498 2.013 31.1626 2.0062 C 23.3055 2.0062 15.7704 5.1274 10.2146 10.6831 C 4.6589 16.239 1.5376 23.7743 1.5376 31.6312 C 1.5376 39.4883 4.6589 47.0235 10.2146 52.5794 C 15.7704 58.1352 23.3055 61.2562 31.1626 61.2562 C 35.7498 61.2495 40.2708 60.159 44.3568 58.0738 C 48.4427 55.9884 51.9781 52.9673 54.6752 49.2562 V 56.2312 C 54.6752 85.9688 34.5564 87.4875 30.5252 87.4875 C 21.3189 87.4875 14.2876 83.175 9.6189 74.6813 L 4.2752 77.6062 C 10.0126 88.05 19.0877 93.5813 30.5252 93.5813 C 35.6175 93.7621 40.6566 92.4924 45.0549 89.9197 C 49.4535 87.3469 53.0304 83.5773 55.3689 79.05 C 58.9596 71.982 60.8107 64.1589 60.7689 56.2312 V 1.8563 H 54.6752 V 14.0063 Z M 31.1626 55.1625 C 26.4954 55.2257 21.915 53.8995 18.0036 51.3527 C 14.0922 48.8056 11.0267 45.1531 9.1966 40.8593 C 7.3666 36.5655 6.855 31.8243 7.7266 27.2391 C 8.5983 22.6536 10.8138 18.4309 14.0916 15.1082 C 17.3696 11.7852 21.5616 9.5122 26.1347 8.5782 C 30.7079 7.644 35.4554 8.0911 39.7736 9.8624 C 44.0921 11.6337 47.7861 14.6491 50.386 18.5254 C 52.9862 22.4017 54.3745 26.9635 54.3752 31.6312 C 54.4101 37.8277 51.9858 43.785 47.6341 48.1963 C 43.2825 52.6077 37.359 55.113 31.1626 55.1625 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 20 },
            },
            rotationDegrees: -10,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 0 },
            },
            radius: 15,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 25 },
            },
            radius: 15,
            orderId: 2,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 50 },
            },
            radius: 15,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 20 },
            },
            radius: 10,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 49, y: -1 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 17,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
            visibleAfterOrderId: 2, // Midpoint dot should only appear after stroke begins to loop.
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 49, y: 45 },
            },
            radius: 10,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 70 },
            },
            radius: 15,
            orderId: 7,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 80 },
            },
            radius: 15,
            orderId: 8,
            allowIntersectionAfterOrderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 3, y: 71 },
            },
            orderId: 9,
            allowIntersectionAfterOrderId: 8,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: WRITING_LINE_HEIGHT_NORMAL - 4,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 542],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
    },
  },
  [CharacterType.LOWER_H]: {
    type: CharacterType.LOWER_H,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_STARTERS,
    display: 'h',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M63.6724 88.9106V118.1076H57.1506V88.9106C57.1506 75.0378 46.2937 64.1808 32.4208 64.1808 18.5482 64.1808 7.6911 75.0378 7.6911 88.9106V118.1076H1.3015V.1132H7.6911V69.4774C10.8201 64.162 20.9607 57.7721 32.4208 57.7721 49.4227 57.7721 63.6724 71.8899 63.6724 88.8918V88.9106Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            radius: 15,
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 65 },
            },
            radius: 10,
            visible: false,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 55 },
            },
            radius: 10,
            visible: false,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 75 },
            },
            radius: 15,
            visible: false,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            allowIntersectionAfterOrderId: 4,
            orderId: 5,
          },
        ],
      },
    ],
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -38,
        frames: [251, 493],
      },
    },
  },
  [CharacterType.LOWER_I]: {
    type: CharacterType.LOWER_I,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.DOTS_ON_TOP,
    display: 'i',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 46,
        },
        scaffold: {
          xOffset: 50,
          yOffset: 37,
          height: WRITING_LINE_HEIGHT_NORMAL - 39,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M3.3311 77.2301V16.5512H9.9046V77.2113H3.3311V77.2301Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -1, y: 18 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            radius: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -1, y: 65 },
            },
            orderId: 1,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M10.9111 5.251C10.9111 7.9432 8.3525 10.5019 5.6601 10.5019 2.9678 10.5019.4091 7.9432.4091 5.251.4091 2.5587 2.9678 0 5.6601 0 8.3525 0 10.9111 2.5587 10.9111 5.251Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -1, y: -4 },
            },
            radius: 20,
            orderId: 0,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                xMin: 45,
                xMax: 75,
                yMin: 20,
                yMax: 50,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -16,
        positionY: -39,
        frames: [251, 400],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_J]: {
    type: CharacterType.LOWER_J,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.DOTS_ON_TOP,
    display: 'j',
    practiceLinesRelationship: PracticeLinesRelationship.BELOW_LINES,
    positioning: {
      [DifficultyLevel.EASY]: {
        model: {
          yOffset: (WRITING_LINE_HEIGHT_SMALL / 2) + 10,
          height: WRITING_LINE_HEIGHT_SMALL,
        },
        scaffold: {
          xOffset: 115,
          yOffset: 85,
          height: 245,
        },
      },
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 1.5,
        },
        scaffold: {
          xOffset: 50,
          yOffset: 40,
          height: WRITING_LINE_HEIGHT_NORMAL - 10,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M 40.297 40.0111 H 55.6461 V 176.651 C 55.6461 192.437 53.6086 207.309 49.5789 219.582 C 44.9606 234.181 35.9504 246.134 25.2196 252.567 C 18.8355 256.354 11.3194 258.681 1.1772 259 L 0 243.808 C 8.1047 243.215 13.0399 241.755 17.3866 239.154 C 24.9026 234.774 31.0151 226.015 34.7731 214.609 C 38.2595 203.797 40.297 190.657 40.297 176.651 V 40.0111 Z',
          [DifficultyLevel.NORMAL]: 'M16.994 16.7457H23.467V73.9335C23.467 80.5402 22.6078 86.765 20.9084 91.9014 18.9608 98.0116 15.161 103.0143 10.6355 105.7066 7.9432 107.2915 4.7736 108.2652.4964 108.3989L0 102.0405C3.4179 101.7922 5.4992 101.1812 7.3323 100.0928 10.5019 98.2598 13.0797 94.5937 14.6645 89.8201 16.1348 85.2946 16.994 79.7955 16.994 73.9335V16.7457Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 29, y: 32 },
              [DifficultyLevel.NORMAL]: { x: 12, y: 16 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 29, y: 130 },
              [DifficultyLevel.NORMAL]: { x: 12, y: 65 },
            },
            visible: false,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 0, y: 220 },
              [DifficultyLevel.NORMAL]: { x: -7, y: 95 },
            },
            radius: 20,
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.EASY]: 'M 61.4416 12.5462 C 61.4416 18.979 55.3744 25.0925 48.9903 25.0925 C 42.6062 25.0925 36.539 18.979 36.539 12.5462 C 36.539 6.1134 42.6062 0 48.9903 0 C 55.3744 0 61.4416 6.1134 61.4416 12.5462 Z',
          [DifficultyLevel.NORMAL]: 'M25.9111 5.251C25.9111 7.9432 23.3525 10.5019 20.6601 10.5019 17.9678 10.5019 15.4091 7.9432 15.4091 5.251 15.4091 2.5587 17.9678 0 20.6601 0 23.3525 0 25.9111 2.5587 25.9111 5.251Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 30, y: -6 },
              [DifficultyLevel.NORMAL]: { x: 13, y: -5 },
            },
            radius: 20,
            orderId: 0,
            minMax: {
              [DifficultyLevel.EASY]: {
                xMin: 100,
                xMax: 180,
                yMin: 50,
                yMax: 108,
              },
              [DifficultyLevel.NORMAL]: {
                xMin: 50,
                xMax: 90,
                yMin: 25,
                yMax: 50,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -37,
        frames: [251, 428],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_K]: {
    type: CharacterType.LOWER_K,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_STARTERS,
    display: 'k',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 30,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M6.5167 118.691H.0943V.1137H6.5167Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 4 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 50 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            radius: 20,
            orderId: 2,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 103,
              },
            },
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M52.0985 118.6911 6.5167 90.5577 6.5167 90.5577V78.9253L40.58 58.3132H53.0647L9.5479 84.7415 64.356 118.6911H52.1175 52.0985Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 36, y: 53 },
            },
            rotationDegrees: -123,
            rotationOffsetX: 16,
            rotationOffsetY: 6,
            orderId: 0,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 49,
              },
            },
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 75 },
            },
            visible: true,
            rotationDegrees: 130,
            rotationOffsetX: 12,
            rotationOffsetY: 24,
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: WRITING_LINE_HEIGHT_NORMAL - 17 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -38,
        frames: [251, 496],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
  },
  [CharacterType.LOWER_L]: {
    type: CharacterType.LOWER_L,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_STARTERS,
    display: 'l',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 58,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 7,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M7 122V0H0V122H7Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 12,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 50 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: WRITING_LINE_HEIGHT_NORMAL - 20 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -4,
        positionY: -36,
        frames: [251, 373],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_M]: {
    type: CharacterType.LOWER_M,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HUMP,
    display: 'm',
    positioning: {
      [DifficultyLevel.EASY]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_SMALL / 2,
          height: (WRITING_LINE_HEIGHT_SMALL / 2),
        },
        scaffold: {
          xOffset: 60,
          yOffset: WRITING_LINE_HEIGHT_LARGE / 2,
          height: WRITING_LINE_HEIGHT_LARGE / 2,
        },
      },
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 20,
          yOffset: 53,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.EASY]: 'M167.331 48.6437V135H153.078V48.6437C153.078 29.2619 139.625 14.2105 121.883 14.2105C104.14 14.2105 90.939 29.2619 90.939 48.6437V135H76.3921V48.6437C76.3921 29.2619 62.9384 14.2105 45.4485 14.2105C27.9586 14.2105 14.2526 29.2619 14.2526 48.6437V135H0V48.6437C0 45.953 0.25228 43.8088 0.25228 44.6076L0 0.756775L14.2526 0.504515L14.5048 12.3606C17.7422 7.52569 30.9016 0 45.4485 0C62.9384 0 78.8306 11.3096 83.6655 22.0726C88.5005 11.3096 104.393 0 121.883 0C146.646 0 167.331 22.0726 167.331 48.6858V48.6437Z',
          [DifficultyLevel.NORMAL]: 'M75.6354 21.8876V58.8596H69.2588V21.8876C69.2588 13.5897 63.2396 7.1457 55.3018 7.1457 47.364 7.1457 41.4576 13.5897 39 22V58.8596H38V22C37 13.5897 28.9302 7.1457 21.945 7 13.2801 7.1457 7.1481 13.5897 7.1481 21.8876V58.8596H.7715V21.8876C.7715 20.7356.8844 19.8176.8844 20.1596L.7715 1.3857 7.1481 1.2777 7.2609 6.3537C8.7093 4.2837 14.5968 1.0617 21.1052 1.0617 28.9302 1.0617 36.0403 5.9037 38.2035 10.5117 40.3666 5.9037 47.4768 1.0617 55.3018 1.0617 66.3809 1.0617 75.6354 10.5117 75.6354 21.9056V21.8876Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -8, y: 0 },
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            radius: 15,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: -8, y: 100 },
              [DifficultyLevel.NORMAL]: { x: -4, y: 47 },
            },
            radius: 15,
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 30, y: 0 },
              [DifficultyLevel.NORMAL]: { x: 16, y: 0 },
            },
            radius: 15,
            visible: false,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 65, y: 100 },
              [DifficultyLevel.NORMAL]: { x: 30, y: 47 },
            },
            radius: 15,
            visible: true,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 65, y: 25 },
              [DifficultyLevel.NORMAL]: { x: 30, y: 15 },
            },
            radius: 6,
            visible: false,
            allowIntersectionAfterOrderId: 3,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 108, y: 0 },
              [DifficultyLevel.NORMAL]: { x: 47, y: 0 },
            },
            radius: 15,
            visible: false,
            allowIntersectionAfterOrderId: 3,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.EASY]: { x: 138, y: 100 },
              [DifficultyLevel.NORMAL]: { x: 65, y: 47 },
            },
            orderId: 6,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -8,
        positionY: -39,
        frames: [251, 579],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
      strokePathAccuracyThreshold: 90,
    },
  },
  [CharacterType.LOWER_N]: {
    type: CharacterType.LOWER_N,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HUMP,
    display: 'n',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: 53,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M43.0207 22.122V58.86H36.7927V22.122C36.7927 13.95 30.2227 7.614 21.5827 7.614 12.9427 7.614 6.3727 13.95 6.3727 22.122V58.86H.2707V22.122C.2707 21.438.3787 20.97.3787 21.654H.2707V1.494H6.3727V7.254C8.1007 4.95 14.5447 1.386 21.5827 1.386 33.2107 1.386 43.0027 10.836 43.0027 22.122H43.0207Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -5, y: 0 },
            },
            radius: 15,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 47 },
            },
            radius: 15,
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 0 },
            },
            radius: 15,
            visible: false,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 34, y: 47 },
            },
            radius: 15,
            orderId: 3,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -9,
        positionY: -39,
        frames: [251, 463],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 7,
    },
  },
  [CharacterType.LOWER_O]: {
    type: CharacterType.LOWER_O,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_CIRCLE_STARTER,
    display: 'o',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 32,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M57.7091 29.1636C57.7091 44.8727 44.5636 58.0182 28.8545 58.0182C13.1455 58.0182 0 44.8727 0 29.1636C0 13.4545 13.1455 0.309052 28.8545 0.309052C44.5636 0.309052 57.7091 13.4545 57.7091 29.1636ZM51.5455 29.1636C51.5455 16.4727 41.5455 6.47269 28.8545 6.47269C16.1636 6.47269 6.16364 16.4727 6.16364 29.1636C6.16364 41.8546 16.1636 51.8546 28.8545 51.8546C41.5455 51.8546 51.5455 41.8546 51.5455 29.1636Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 18, y: -4 },
            },
            rotationDegrees: -90,
            rotationOffsetX: 16,
            rotationOffsetY: 0,
            orderId: 0,
            radius: 20,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 20 },
            },
            orderId: 1,
            radius: 20,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 49 },
            },
            orderId: 2,
            radius: 8,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 44, y: 20 },
            },
            orderId: 3,
            radius: 20,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 26, y: -4 },
            },
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
            visibleAfterOrderId: 0, // Stop dot should only appear after stroke passes the start dot.
            radius: 20,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 442],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_P]: {
    type: CharacterType.LOWER_P,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.CIRCLE_COMES_LATER,
    display: 'p',
    practiceLinesRelationship: PracticeLinesRelationship.BELOW_LINES,
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 91,
        },
        scaffold: {
          xOffset: 30,
          yOffset: 53,
          height: WRITING_LINE_HEIGHT_NORMAL - 26,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.END_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M59.2312 30.3426C59.2312 46.1498 46.0949 59.2861 30.3967 59.2861 19.8222 59.2861 10.5196 53.363 7.6125 48.5845V89.1563H1.4531V1.2717H7.6125V12.0824C10.5196 7.3221 19.8222 1.5079 30.3967 1.5079 46.0949 1.5079 59.2312 14.6443 59.2312 30.3426ZM53.0719 30.3426C53.0719 17.6604 43.0788 7.6673 30.3967 7.6673 17.7146 7.6673 7.6125 17.6604 7.6125 30.3426 7.6125 43.0246 17.6056 53.0177 30.3967 53.0177 43.1878 53.0177 53.0719 43.0246 53.0719 30.3426Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            radius: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -3, y: 80 },
            },
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 15 },
            },
            radius: 15,
            orderId: 2,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 0 },
            },
            radius: 15,
            visible: false,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 25 },
            },
            radius: 15,
            visible: false,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 48 },
            },
            radius: 15,
            visible: false,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 3, y: 38 },
            },
            allowIntersectionAfterOrderId: 5,
            visibleAfterOrderId: 2, // Stop dot should only appear after stroke passes the closest midpoint dot.
            orderId: 6,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                xMin: 25,
                xMax: 51,
                yMin: 92,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -38,
        frames: [251, 500],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 7,
    },
  },
  [CharacterType.LOWER_Q]: {
    type: CharacterType.LOWER_Q,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HOOK,
    display: 'q',
    practiceLinesRelationship: PracticeLinesRelationship.BELOW_LINES,
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 90,
        },
        scaffold: {
          xOffset: 20,
          yOffset: 55,
          height: WRITING_LINE_HEIGHT_NORMAL - 30,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        requireIntersection: StrokeIntersectionType.START_POINT_INTERSECTION,
        paths: {
          [DifficultyLevel.NORMAL]: 'M80.4571 75.8099C80.4571 80.569 79.1561 84.2498 76.6465 86.6293 74.2669 89.2505 70.8089 90.7935 67.3697 90.7935 60.3427 90.7935 54.3939 84.3613 54.3939 76.9997V50.1185C51.4195 54.9891 41.9014 60.9379 31.1936 60.9379 15.0204 60.9379 1.5612 47.4973 1.5612 31.4356 1.5612 15.3738 15.0018 1.8031 31.1936 1.8031 41.9014 1.8031 51.4195 7.8635 54.3939 12.7526V1.2082H58L58 32C60.6959 81.2754 63.5591 84.3799 67.3515 84.3799 69.2476 84.3799 70.6791 83.785 72.1105 82.3536 73.4118 80.9222 74.1368 79.1375 74.1368 75.8099H80.4385 80.4571ZM31.082 8.2353C18.1063 8.2353 7.8818 18.4598 7.8818 31.4356 7.8818 44.4114 18.1063 54.6359 31.082 54.6359 44.0579 54.6359 54.3939 44.4114 54.3939 31.4356 54.3939 18.4598 44.1694 8.2353 31.082 8.2353Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 20 },
            },
            rotationDegrees: -10,
            rotationOffsetX: 0,
            rotationOffsetY: 0,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 0 },
            },
            radius: 15,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 25 },
            },
            radius: 15,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 50 },
            },
            radius: 15,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 20 },
            },
            radius: 10,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 49, y: -1 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            visible: true,
            radius: 17,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
            visibleAfterOrderId: 2, // Midpoint dot should only appear after stroke begins to loop.
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 47, y: 45 },
            },
            radius: 10,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 50, y: 75 },
            },
            radius: 15,
            orderId: 7,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 68, y: 70 },
            },
            radius: 15,
            orderId: 8,
            allowIntersectionAfterOrderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 67, y: 70 },
            },
            orderId: 9,
            allowIntersectionAfterOrderId: 8,
            radius: 30,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: WRITING_LINE_HEIGHT_NORMAL - 4,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -16,
        positionY: -40,
        frames: [251, 520],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 10,
    },
  },
  [CharacterType.LOWER_R]: {
    type: CharacterType.LOWER_R,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HUMP,
    display: 'r',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: 53,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M41.3925 11.7216 36.1178 15C33.0775 10.2015 28.9749 7.967 24 7H21C13 8 7.6378 13.4798 7.6378 20.5128V59.5421H1.4291V1.4103H7.6378V6.5751C9.158 4.707 14.7807 1.7766 20.7698 1.7766H24.2863C30.9713 1.7766 37.2899 5.1831 41.3925 11.7399V11.7216Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 0 },
            },
            radius: 15,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 47 },
            },
            radius: 15,
            visible: true,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: -3 },
            },
            orderId: 2,
            radius: 8,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 34, y: 8 },
            },
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -9,
        positionY: -39,
        frames: [251, 427],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 7,
    },
  },
  [CharacterType.LOWER_S]: {
    type: CharacterType.LOWER_S,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_SUPER_SQUIGGLY,
    display: 's',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 40,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M37.2706 50.2762C34.6765 54.5644 29.5942 57.5114 23.4883 58.2879C22.1294 58.5173 20.7706 58.5173 19.5353 58.5173C12.7589 58.5173 5.98244 56.2585 1.00598 51.7409L4.95891 47.3291C9.58244 51.3879 16.3589 53.2056 22.6942 52.3056C27.3177 51.635 30.4942 49.9409 32.2942 47.2232C33.1942 45.5291 33.5294 44.1703 32.9647 42.4762C32.2942 39.7585 29.9118 37.0585 26.0824 35.135C23.9294 34.0056 21.5647 33.1056 18.8471 32.082C14.5589 30.4938 9.91774 28.9232 6.42362 25.9761C2.36479 22.5879 0.441267 18.0703 1.11186 13.6585C2.13538 8.5761 6.65303 4.05845 12.7413 2.02904C21.4412 -1.12978 32.5059 0.899627 39.3883 7.57022L35.2059 11.8585C29.7883 6.54669 21.653 5.08198 14.6471 7.57022C10.2354 9.26433 7.53538 11.7526 7.07656 14.8055C6.61774 17.1703 7.64126 19.2173 10.1295 21.2467C12.953 23.6114 16.6766 24.9703 20.753 26.5585C23.4706 27.4585 26.2942 28.482 28.9942 29.8409C33.9706 32.435 37.6942 36.6173 38.8236 40.7997C39.7236 43.9585 39.053 47.5762 37.2353 50.2938L37.2706 50.2762Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 26, y: 2 },
            },
            rotationDegrees: -30,
            rotationOffsetX: 5,
            rotationOffsetY: -2,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: -6 },
            },
            radius: 8,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 0 },
            },
            radius: 8,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 16 },
            },
            radius: 8,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 26, y: 28 },
            },
            radius: 8,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 26, y: 46 },
            },
            radius: 8,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 42 },
            },
            radius: 20,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 96,
              },
            },
          },
        ],
        restrictedAreas: [
          {
            id: '1',
            type: RestrictedAreaType.MID_STROKE,
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -6, y: 8 },
            },
            points: [
              0, 0,
              5, 0,
              5, 15,
              0, 15,
              0, 0,
            ],
          },
          {
            id: '2',
            type: RestrictedAreaType.MID_STROKE,
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 41, y: 38 },
            },
            points: [
              0, 0,
              5, 0,
              5, 15,
              0, 15,
              0, 0,
            ],
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 463],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
      strokePathAccuracyThreshold: 100,
    },
  },
  [CharacterType.LOWER_T]: {
    type: CharacterType.LOWER_T,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.HIGH_STARTERS,
    display: 't',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 38,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 6,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M28.8036 0H28.8036V118.4085H22.3965V0Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 50 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: WRITING_LINE_HEIGHT_NORMAL - 20 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M50.3307 56.0007V62.4078H.8693V56.0007H50.3307Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -10, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 16, y: 50 },
            },
            radius: 15,
            allowIntersectionAfterOrderId: 0,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 42, y: 50 },
            },
            orderId: 2,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                xMin: 76,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -3,
        positionY: -39,
        frames: [251, 475],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_U]: {
    type: CharacterType.LOWER_U,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_DOWN_AND_UP,
    display: 'u',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 38,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M46.6915 1.41867V29.346C46.6915 45.6369 36.3279 59.3642 23.6551 59.3642C10.9824 59.3642 0.509644 45.6369 0.509644 29.346V1.41867H6.67327V29.346C6.67327 42.8369 14.2369 53.2005 23.6551 53.2005C33.0733 53.2005 40.5279 42.8369 40.5279 29.346V1.41867H46.6915Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: -2 },
            },
            radius: 20,
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 45 },
            },
            radius: 8,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: 48 },
            },
            radius: 10,
            orderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: 45 },
            },
            radius: 8,
            orderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 36, y: -2 },
            },
            radius: 20,
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -5,
        positionY: -38,
        frames: [251, 442],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      strokePathAccuracyThreshold: 95,
    },
  },
  [CharacterType.LOWER_V]: {
    type: CharacterType.LOWER_V,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_DOWN_AND_UP,
    display: 'v',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 38,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M49.6412 1.37695L24.9177 59.4182L0.0706787 1.37695H6.61772L24.9177 44.1711L43.0941 1.37695H49.6412Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -2 },
            },
            radius: 20,
            rotationDegrees: 160,
            rotationOffsetX: 14,
            rotationOffsetY: 18,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 12, y: 35 },
            },
            radius: 8,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: 50 },
            },
            rotationDegrees: 20,
            rotationOffsetX: -2,
            rotationOffsetY: 4,
            radius: 12,
            orderId: 2,
            visible: true,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 35 },
            },
            radius: 8,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 36, y: -2 },
            },
            radius: 20,
            orderId: 4,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -38,
        frames: [251, 446],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.LOWER_W]: {
    type: CharacterType.LOWER_W,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_DOWN_AND_UP,
    display: 'w',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 27,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M69.1062 1.37617L51.4944 59.982L34.7827 16.4997L17.1709 59.982L0.794434 1.37617H7.11205L18.3003 41.3467L34.9062 0.12323L50.8239 41.3467L62.9121 1.37617H69.1239H69.1062Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -3, y: -2 },
            },
            radius: 20,
            rotationDegrees: 160,
            rotationOffsetX: 14,
            rotationOffsetY: 18,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 6, y: 35 },
            },
            radius: 12,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 8, y: 50 },
            },
            rotationDegrees: 10,
            rotationOffsetX: -2,
            rotationOffsetY: 2,
            radius: 12,
            visible: true,
            orderId: 2,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 15, y: 30 },
            },
            radius: 12,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 10 },
            },
            radius: 12,
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: -2 },
            },
            radius: 12,
            orderId: 5,
            allowIntersectionAfterOrderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 32, y: 18 },
            },
            radius: 12,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 35 },
            },
            radius: 12,
            orderId: 7,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 42, y: 50 },
            },
            rotationDegrees: 10,
            rotationOffsetX: -2,
            rotationOffsetY: 2,
            radius: 12,
            visible: true,
            orderId: 8,
            allowIntersectionAfterOrderId: 7,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 30 },
            },
            radius: 12,
            orderId: 9,
            allowIntersectionAfterOrderId: 8,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 56, y: -2 },
            },
            radius: 20,
            orderId: 10,
            allowIntersectionAfterOrderId: 9,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -38,
        frames: [251, 600],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
  },
  [CharacterType.LOWER_X]: {
    type: CharacterType.LOWER_X,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_CRISS_CROSS,
    display: 'x',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M52.236 57.7555H44.514L.8459.4975H8.568Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: -2 },
            },
            radius: 20,
            rotationDegrees: 145,
            rotationOffsetX: 14,
            rotationOffsetY: 17,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 19, y: 20 },
            },
            radius: 15,
            visible: false,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 42, y: 48 },
            },
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M8.8019 57.7555H1.08L44.748.4975H52.47Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: -2 },
            },
            radius: 20,
            rotationDegrees: -145,
            rotationOffsetX: 18,
            rotationOffsetY: 10,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 19, y: 20 },
            },
            radius: 15,
            visible: false,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -4, y: 48 },
            },
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -3,
        positionY: -39,
        frames: [251, 459],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
  },
  [CharacterType.LOWER_Y]: {
    type: CharacterType.LOWER_Y,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.LOWER_CRISS_CROSS,
    display: 'y',
    practiceLinesRelationship: PracticeLinesRelationship.BELOW_LINES,
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 95,
          height: 93,
        },
        scaffold: {
          xOffset: 30,
          yOffset: 55,
          height: 90,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M26.4701 58.154 1.2866.5079H8.1064L29.981 50.3784Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -3, y: -2 },
            },
            rotationDegrees: 152,
            rotationOffsetX: 13,
            rotationOffsetY: 17,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 23, y: 48 },
            },
            orderId: 1,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 58.4548 0.5079 L 19.7607 89.0909 H 12.9408 L 51.6167 0.5079 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: -2 },
            },
            rotationDegrees: -157,
            rotationOffsetX: 17,
            rotationOffsetY: 13,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 23, y: 48 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 8, y: 82 },
            },
            orderId: 2,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 129,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [251, 485],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
    },
  },
  [CharacterType.LOWER_Z]: {
    type: CharacterType.LOWER_Z,
    category: CharacterCategory.LETTER_LOWER,
    kinestheticGroupType: KinestheticSetType.ZIG_ZAG,
    display: 'z',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
        scaffold: {
          xOffset: 35,
          yOffset: (WRITING_LINE_HEIGHT_NORMAL / 2) - 5,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M13.1761 51.8692H50.8502V58.0972H.1622L43.1282 6.7251H5.5801V.6231H56.2682L13.1941 51.8872 13.1761 51.8692Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: -4 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 30, y: -4 },
            },
            radius: 10,
            orderId: 1,
            allowIntersectionAfterOrderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: -4 },
            },
            rotationDegrees: -140,
            rotationOffsetX: 19,
            rotationOffsetY: 9,
            visible: true,
            radius: 12,
            orderId: 2,
            allowIntersectionAfterOrderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 38, y: 4 },
            },
            radius: 10,
            orderId: 3,
            allowIntersectionAfterOrderId: 2,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 20, y: 25 },
            },
            visible: false,
            radius: 12,
            allowIntersectionAfterOrderId: 3,
            orderId: 4,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 6, y: 40 },
            },
            radius: 8,
            orderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -3, y: 50 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            visible: true,
            radius: 12,
            orderId: 6,
            allowIntersectionAfterOrderId: 5,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 10, y: 50 },
            },
            orderId: 7,
            radius: 10,
            allowIntersectionAfterOrderId: 6,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 50 },
            },
            orderId: 8,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -2,
        positionY: -39,
        frames: [241, 511],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 15,
      strokePathAccuracyThreshold: 90,
    },
  },
  [CharacterType.SYMBOL_PLUS]: {
    type: CharacterType.SYMBOL_PLUS,
    category: CharacterCategory.SYMBOL,
    display: '+',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 2,
        },
        scaffold: {
          xOffset: 30,
          yOffset: 26,
          height: WRITING_LINE_HEIGHT_NORMAL / 2,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 33.8746 25.0892 V 0 H 25.1593 V 58.8695 H 33.8746 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 22 },
            },
            radius: 15,
            allowIntersectionAfterOrderId: 0,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 45 },
            },
            radius: 20,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 33.8746 25.0892 H 0 V 33.7803 H 58.8695 V 25.0892 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 0, y: 22 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 22 },
            },
            radius: 15,
            allowIntersectionAfterOrderId: 0,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 45, y: 22 },
            },
            radius: 20,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [249, 452],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.SYMBOL_EQUAL]: {
    type: CharacterType.SYMBOL_EQUAL,
    category: CharacterCategory.SYMBOL,
    display: '=',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        model: {
          yOffset: 2,
        },
        scaffold: {
          xOffset: 30,
          yOffset: 41,
          height: 30,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 70 5.5481 V 0 H -6 V 5.5481 H 70 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: -5 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: -5 },
            },
            radius: 20,
            allowIntersectionAfterOrderId: 0,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: -5 },
            },
            radius: 25,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
        ],
        restrictedAreas: [{
          id: '1',
          type: RestrictedAreaType.MID_STROKE,
          coordinates: {
            [DifficultyLevel.NORMAL]: { x: 10, y: 20 },
          },
          points: [
            0, 0,
            45, 0,
            45, 2,
            0, 2,
            0, 0,
          ],
        }],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 70 30 V 23 H -6 V 30 H 70 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 19 },
            },
            rotationDegrees: 90,
            rotationOffsetX: 0,
            rotationOffsetY: 16,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 22, y: 19 },
            },
            radius: 20,
            allowIntersectionAfterOrderId: 0,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 55, y: 19 },
            },
            radius: 25,
            allowIntersectionAfterOrderId: 1,
            orderId: 2,
          },
        ],
        restrictedAreas: [{
          id: '1',
          type: RestrictedAreaType.MID_STROKE,
          coordinates: {
            [DifficultyLevel.NORMAL]: { x: 10, y: 8 },
          },
          points: [
            0, 0,
            45, 0,
            45, 2,
            0, 2,
            0, 0,
          ],
        }],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -6,
        positionY: -39,
        frames: [246, 450],
      },
    },
    gradingSettings: {
      ...gradingDefaults,
      pathStrokeWidth: 30,
    },
  },
  [CharacterType.SYMBOL_QUESTION]: {
    type: CharacterType.SYMBOL_QUESTION,
    category: CharacterCategory.SYMBOL,
    display: '?',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 25,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 33.33 0 C 15.1965 0 0 15.3686 0 34.999 H 6.7682 C 6.7682 18.7264 18.5166 6.974 33.33 6.974 C 48.1434 6.974 59.8918 18.7264 59.8918 33.7075 C 59.8918 41.5855 56.6993 48.6887 50.825 53.9837 C 46.1001 58.2455 39.8428 60.5702 33.33 60.5702 H 29.8821 V 98.7979 H 36.7779 V 67.2859 C 42.7799 66.8985 49.9312 63.9281 55.4223 58.8913 C 62.4459 52.6922 66.66 43.2645 66.66 33.7075 C 66.66 15.3686 51.4635 0 33.33 0 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -3, y: 20 },
            },
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 28, y: -4 },
            },
            orderId: 1,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 52, y: 18 },
            },
            orderId: 2,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 40, y: 50 },
            },
            orderId: 3,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 60 },
            },
            orderId: 4,
            allowIntersectionAfterOrderId: 3,
            radius: 10,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 85 },
            },
            radius: 20,
            orderId: 5,
          },
        ],
        restrictedAreas: [
          {
            id: '1',
            type: RestrictedAreaType.MID_STROKE,
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 17, y: 50 },
            },
            points: [
              12, -10,
              -16, 18,
              -18, 16,
              10, -12,
              10, -10,
            ],
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 33.33 107.1923 C 30.5206 107.1923 27.9666 109.9042 27.9666 112.7463 C 27.9666 115.4582 30.5206 118.17 33.33 118.17 C 36.1394 118.17 38.6934 115.4582 38.6934 112.7463 C 38.6934 109.9042 36.1394 107.1923 33.33 107.1923 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: 25, y: 105 },
            },
            radius: 20,
            orderId: 0,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 105,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -9,
        positionY: -35,
        frames: [248, 524],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
  [CharacterType.SYMBOL_EXCLAMATION]: {
    type: CharacterType.SYMBOL_EXCLAMATION,
    category: CharacterCategory.SYMBOL,
    display: '!',
    positioning: {
      [DifficultyLevel.NORMAL]: {
        scaffold: {
          xOffset: 60,
          yOffset: 0,
          height: WRITING_LINE_HEIGHT_NORMAL - 5,
        },
      },
    },
    strokes: [
      {
        id: 'stroke-1',
        order: 1,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 1.9839 98.7342 V 0 H 8.9938 V 98.7342 H 1.9839 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 0 },
            },
            rotationDegrees: 180,
            rotationOffsetX: 16,
            rotationOffsetY: 15,
            radius: 20,
            orderId: 0,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 43 },
            },
            radius: 15,
            orderId: 1,
          },
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 85 },
            },
            radius: 20,
            orderId: 2,
          },
        ],
      },
      {
        id: 'stroke-2',
        order: 2,
        paths: {
          [DifficultyLevel.NORMAL]: 'M 5.555 107.156 C 8.4648 107.156 11.11 109.8769 11.11 112.7281 C 11.11 115.4491 8.4648 118.17 5.555 118.17 C 2.6452 118.17 0 115.4491 0 112.7281 C 0 109.8769 2.6452 107.156 5.555 107.156 Z',
        },
        points: [
          {
            coordinates: {
              [DifficultyLevel.NORMAL]: { x: -2, y: 100 },
            },
            radius: 20,
            orderId: 0,
            minMax: {
              [DifficultyLevel.NORMAL]: {
                yMin: 105,
              },
            },
          },
        ],
      },
    ],
    lottieSettings: {
      [ActivityType.CHASE_STAR]: {
        positionX: -1,
        positionY: -35,
        frames: [248, 423],
      },
    },
    gradingSettings: { ...gradingDefaults },
  },
};

export const characterList = Object.keys(characters);

export const numberList = characterList.filter(
  (key) => characters[key as CharacterType]?.category === CharacterCategory.NUMBER,
);
export const upperLetterList = characterList.filter(
  (key) => characters[key as CharacterType]?.category === CharacterCategory.LETTER_UPPER,
);
export const lowerLetterList = characterList.filter(
  (key) => characters[key as CharacterType]?.category === CharacterCategory.LETTER_LOWER,
);
export const symbolList = characterList.filter(
  (key) => characters[key as CharacterType]?.category === CharacterCategory.SYMBOL,
);
