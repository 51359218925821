import React, { useState, useCallback, useMemo } from 'react';
import { Layer, Line } from 'react-konva';
import {
  DRAWABLE_HEIGHT_NORMAL, DRAWABLE_WIDTH_NORMAL, DRAWN_STROKE_WIDTH, SCALE_LARGE,
} from '../../shared/constants';
import { DrawingStroke } from './types';
import envSettings from '../../data/envSettings';
import { DifficultyLevel } from '../../data/types';

interface LineLayerProps {
  strokes: DrawingStroke[],
  id: string,
  difficultyLevel: DifficultyLevel,
  strokeOpacity: number,
}

// Layer listening is false, so handleTouchMove doesn't register freedraw layer
export default function LineLayer({
  strokes, id, difficultyLevel, strokeOpacity,
}: LineLayerProps) {
  const [strokeColors, setStrokeColors] = useState<any[]>([]);

  const strokeWidth = useMemo(() => (difficultyLevel === DifficultyLevel.EASY
    ? DRAWN_STROKE_WIDTH * SCALE_LARGE
    : DRAWN_STROKE_WIDTH), [difficultyLevel]);

  const gradientRef = useCallback((node: any) => {
    if (node !== null) {
      const ctx = node.getContext();
      const strokeColorsOrGradients = strokes.map((stroke: DrawingStroke) => {
        if (stroke.pen.colors.length > 1) {
          ctx.clear();
          const gradient = ctx.createLinearGradient(0, 0, DRAWABLE_WIDTH_NORMAL, DRAWABLE_HEIGHT_NORMAL);
          for (let i = 0; i < stroke.pen.colors.length; i++) {
            gradient.addColorStop(i, stroke.pen.colors[i]);
          }
          return gradient;
        }
        return stroke.pen.colors[0];
      });
      setStrokeColors(strokeColorsOrGradients);
    }
  }, [strokes]);

  if (!strokes?.length) return null;

  if (envSettings.enableNewTouchSettings) {
    return (
      <Layer listening={false} id={id} ref={gradientRef}>
        {strokeColors.length ? strokes.map(({ touches }, index) => {
          const touchIds = Object.keys(touches);
          return touchIds.map((touchId: string) => (
            <Line
              key={touchId}
              points={touches[touchId]}
              stroke={strokeColors[index]}
              strokeWidth={strokeWidth}
              tension={0.5}
              lineCap="round"
              opacity={strokeOpacity}
            />
          ));
        }) : null}
      </Layer>
    );
  }

  return (
    <Layer listening={false} id={id} ref={gradientRef}>
      {strokeColors.length ? strokes.map((line, index) => (
        <Line
          key={line.touchId}
          points={line.points}
          stroke={strokeColors[index]}
          strokeWidth={strokeWidth}
          tension={0.5}
          lineCap="round"
          opacity={strokeOpacity}
        />
      )) : null}
    </Layer>
  );
}
