import React from 'react';
import { DemoAreaWrapper } from '../styles/components/ContentWrappers';
import { FlexColumnCenter100Percent } from '../styles/components/FlexSpacers';
import pencil from '../assets/images/pencil.png';
import dashedSquiggles1 from '../assets/images/backgrounds/dashed-squiggles-1.svg';
import dashedSquiggles2 from '../assets/images/backgrounds/dashed-squiggles-2.svg';
import useOnlineStatus from '../hooks/useOnlineStatus';
import NoInternetOverlay from './NoInternet/NoInternetOverlay';

type DemoVideoWrapperProps = {
  headerText: string,
  nextButton: React.ReactNode,
  children: React.ReactNode,
}

export default function DemoVideoWrapper({ headerText, nextButton, children }: DemoVideoWrapperProps) {
  const isOnline = useOnlineStatus();

  return (
    <FlexColumnCenter100Percent>
      <h1 className="visually-hidden">{headerText}</h1>
      <DemoAreaWrapper>
        <img className="squiggles top" src={dashedSquiggles1} alt="" />
        {!isOnline ? (
          <NoInternetOverlay />
        ) : children }
        <div className="button-container bottom">
          {nextButton}
        </div>
        <img className="pencil" src={pencil} alt="" />
        <img className="squiggles bottom" src={dashedSquiggles2} alt="" />
      </DemoAreaWrapper>
    </FlexColumnCenter100Percent>
  );
}
