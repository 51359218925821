import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { Stage } from 'react-konva';
import {
  Character,
  DifficultyLevel,
  Pen,
  PracticeLinesRelationship,
  StrokeOutcome,
  StrokeOutcomeType,
} from '../../data/types';
import {
  TARGET_WIDTH_NORMAL,
  DRAWABLE_MARGIN,
  TARGET_HEIGHT_NORMAL_EXTENDED,
  TARGET_HEIGHT_NORMAL,
  TARGET_HEIGHT_LARGE_EXTENDED,
  TARGET_WIDTH_LARGE,
} from '../../shared/constants';
import { DrawingStroke } from './types';
import LineLayer from './LineLayer';
import GuidePointsLayer from './GuidePointsLayer';
import envSettings from '../../data/envSettings';
import useKonvaGrading from '../../hooks/useKonvaGrading';
import useKonvaPalmRejection from '../../hooks/useKonvaPalmRejection';
import { ActivityProgressState } from '../../context/ActivityProgressContext';
import { FeedbackActionType, useFeedbackContext } from '../../context/FeedbackContext';
import useDifficultyLevel from '../../hooks/useDifficultyLevel';
import VisibleTargetZone from './VisibleTargetZone';
import CharacterModelPath from './CharacterModelPath';
import { strokeOutcomes } from '../../data/stroke-outcomes';
import useCanvasVisibleCorrectiveFeedback from '../../hooks/useCanvasVisibleCorrectiveFeedback';
import ModelPathLayer from './ModelPathLayer';
import useDirectionalStartDot from '../../hooks/useDirectionalStartDot';
import useRequiredPoints from '../../hooks/useRequiredPoints';

interface WritingAreaProps {
  width: number,
  height: number,
  character: Character,
  activePen: Pen,
  canWrite: boolean,
  doReset: boolean,
  isActivityComplete: boolean,
  showGuideDots: boolean,
  showTargetZone: boolean,
  activityInProgress: ActivityProgressState|null,
  currentIteration: number,
  drawnStrokeOpacity: number|null,
  allowFreeDrawGrading: boolean,
  saveIteration: (strokes: DrawingStroke[]) => void,
  handleStrokeOutcome: (
    result: StrokeOutcome, hasMoreStrokes: boolean, currentStrokeCount: number, strokes: DrawingStroke[]
  ) => void,
  handleFirstStrokeStart: () => void,
  handleTapOffStart: (currentStrokeIndex: number) => void,
  handleRemainingStrokeStart: () => void,
}

export default function WritingAreaStage({
  width,
  height,
  activePen,
  character,
  canWrite,
  doReset,
  isActivityComplete,
  showGuideDots,
  showTargetZone,
  activityInProgress,
  currentIteration,
  drawnStrokeOpacity,
  allowFreeDrawGrading,
  saveIteration,
  handleStrokeOutcome,
  handleFirstStrokeStart,
  handleTapOffStart,
  handleRemainingStrokeStart,
}: WritingAreaProps) {
  const {
    strokes,
    setStrokes,
    intersectedPoints,
    strokeEndedAtEndPoint,
    resetGradingForNextStroke,
    calculateStrokeOutcome,
    updateIntersectionsFromActivePointer,
    updateLastStrokeFromActivePointer,
    initializeStroke,
    calculateFreeDrawStrokeOutcome,
  } = useKonvaGrading({ width, height });
  const {
    isTouchTypeAllowed,
    allowActiveStylusOnly,
    getActivePointerFromKonva,
    getAllowedStartingPointerFromKonva,
    touchInterferenceDetected,
    setTouchInterferenceDetected,
  } = useKonvaPalmRejection();
  /* Known issue with accessing context within Konva Stage:
  ** https://github.com/facebook/react/issues/13336
  ** An option is to update Konva to 18.2.2:
  ** https://www.npmjs.com/package/react-konva/v/18.2.2 -> "Using With React Context"
  ** but updating is out of scope for this project right now.
  ** A workaround will be accessing this hook that requires the GameContext
  ** in this component and sending specific data to GuidePointLayer
  */
  const {
    beginStartDotAnimation,
    removeStartDotAfterAnimation,
    beginStopDotAnimation,
    shouldShowOnlyStartDotOnLoad,
  } = useCanvasVisibleCorrectiveFeedback();
  const isStartDotDirectional = useDirectionalStartDot();
  const { difficultyLevel } = useDifficultyLevel();
  const feedbackCtx = useFeedbackContext();
  const [currentCharacterStrokeIndex, setCurrentCharacterStrokeIndex] = useState<number>(0);
  const [lastTouch, setLastTouch] = useState<Date|null>(null);
  const isDrawing = useRef(false);
  const currentStroke = character?.strokes[currentCharacterStrokeIndex] || null;
  const isDotStroke = currentStroke?.points.length === 1;
  const restrictedAreas = currentStroke?.restrictedAreas || [];
  const currentStartPoint = currentStroke?.points?.[0] || { x: 0, y: 0 };
  const currentEndPoint = currentStroke?.points?.[currentStroke?.points?.length
    ? Number(currentStroke?.points?.length) - 1 : 0] || { x: 0, y: 0 };

  const { requiredPointsCharacter } = useRequiredPoints(
    currentStroke,
    character,
    difficultyLevel,
    allowFreeDrawGrading,
  );

  const targetAreaHeight = useMemo(() => {
    // easy level always has extended drawable area.
    if (difficultyLevel === DifficultyLevel.EASY) return TARGET_HEIGHT_LARGE_EXTENDED;
    return (character.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES)
      ? TARGET_HEIGHT_NORMAL_EXTENDED
      : TARGET_HEIGHT_NORMAL;
  }, [character, difficultyLevel]);

  const targetAreaWidth = useMemo(() => {
    if (difficultyLevel === DifficultyLevel.EASY) return TARGET_WIDTH_LARGE;
    return TARGET_WIDTH_NORMAL;
  }, [character, difficultyLevel]);

  const positionX = useMemo(() => {
    const charPositionX = character.positioning[difficultyLevel]?.scaffold.xOffset || 0;
    return charPositionX + DRAWABLE_MARGIN;
  }, [character, difficultyLevel]);

  const positionY = useMemo(() => {
    const charPositionY = character.positioning[difficultyLevel]?.scaffold.yOffset || 0;
    return charPositionY + DRAWABLE_MARGIN;
  }, [character, difficultyLevel]);

  const resetForNextStrokeAtIndex = (index: number) => {
    resetGradingForNextStroke();
    setCurrentCharacterStrokeIndex(index);
  };

  const createFeedbackMessages = (strokeOutcome: StrokeOutcome) => {
    const msgs: string[] = [];
    if (touchInterferenceDetected && strokeOutcome !== StrokeOutcome.SUCCESS) {
      msgs.push('Palm rejection may be interfering with Starwriter performance.');
    }
    if (!touchInterferenceDetected
        && strokeOutcome === StrokeOutcome.SUCCESS
        && Object.keys(strokes[currentCharacterStrokeIndex].touches).length > 1) {
      msgs.push('Feedback: Don\'t lift your stylus during the stroke');
    }
    if (msgs.length) {
      feedbackCtx.dispatch({ type: FeedbackActionType.ADD_MESSAGES, payload: msgs });
    }
  };

  useEffect(() => {
    if (activityInProgress && activityInProgress.strokes) {
      setStrokes([...activityInProgress.strokes]);
    }
  }, []);

  useEffect(() => {
    if (isActivityComplete) {
      saveIteration(strokes);
    }
  }, [isActivityComplete]);

  useEffect(() => {
    if (doReset) {
      setStrokes([]);
      resetForNextStrokeAtIndex(0);
    }
  }, [doReset]);

  const startNewStroke = (e: any) => {
    const stage = e.target.getStage();
    const requireStrokeFromStartZone = !allowActiveStylusOnly;
    const activePointer = getAllowedStartingPointerFromKonva(stage, e);

    // ignore disallowed touch type based on settings
    if (!isTouchTypeAllowed(e.evt, activePointer?.id)) return;

    // only initialize stroke if valid pointer exists
    if (activePointer) {
      isDrawing.current = true;
      setLastTouch(new Date());

      if (isDotStroke) {
        updateIntersectionsFromActivePointer(stage, activePointer, 1);
      }

      initializeStroke(activePointer, isDotStroke, activePen);

      if (currentCharacterStrokeIndex === 0) {
        handleFirstStrokeStart();
      } else {
        handleRemainingStrokeStart();
      }
    } else if (requireStrokeFromStartZone) {
      handleTapOffStart(currentCharacterStrokeIndex);
    }
  };

  const addDataToCurrentStroke = (e: any) => {
    const stage = e.target.getStage();
    const lastStroke = strokes[strokes.length - 1];
    const activePointer = getActivePointerFromKonva(e, stage, lastStroke);

    if (!lastStroke || !activePointer) {
      return;
    }

    updateLastStrokeFromActivePointer(lastStroke, activePointer, e);
    updateIntersectionsFromActivePointer(
      stage,
      activePointer,
      requiredPointsCharacter[requiredPointsCharacter.length - 1].orderId || 1,
    );
  };

  const handleExperimentalTouchStart = (e: any) => {
    if (!canWrite || strokes.length > currentCharacterStrokeIndex) {
      return;
    }

    startNewStroke(e);

    // touches with new ids when the above conditions aren't met will fall through
    // and touch data will be captured in touchmove
  };

  const handleTouchStart = (e: any) => {
    // prevent creation of a second mistake stroke from palm interference
    if (!canWrite || isDrawing.current || strokes.length > currentCharacterStrokeIndex) {
      return;
    }

    const stage = e.target.getStage();
    const requireStrokeFromStartZone = !allowActiveStylusOnly;
    const activePointer = getAllowedStartingPointerFromKonva(stage, e);

    // ignore disallowed touch type based on settings
    if (!isTouchTypeAllowed(e.evt, activePointer?.id)) return;

    // only initialize stroke if valid pointer exists
    if (activePointer) {
      isDrawing.current = true;

      if (isDotStroke) {
        setLastTouch(new Date());
        updateIntersectionsFromActivePointer(stage, activePointer, 1);
      }

      initializeStroke(activePointer, isDotStroke, activePen);

      if (currentCharacterStrokeIndex === 0) {
        handleFirstStrokeStart();
      } else {
        handleRemainingStrokeStart();
      }
    } else if (requireStrokeFromStartZone) {
      handleTapOffStart(currentCharacterStrokeIndex);
    }
  };

  const handleTouchMove = (e: any) => {
    // note a touch event anytime it happens while writing
    // even if not valid to prevent grading while hand is moving
    if (canWrite) {
      setLastTouch(new Date());
    }

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }

    addDataToCurrentStroke(e);
  };

  const evaluateStrokeEnd = (lastStroke: DrawingStroke, shouldProvideFeedback: boolean) => {
    // we want to allow kids to make a single tap/false start without penalty (unless it's a dot)
    // but if longer than 1 point, the stroke should be graded
    const isSinglePointFalseStartStroke = !isDotStroke && lastStroke.points?.length <= 2;
    if (isSinglePointFalseStartStroke) {
      setStrokes((currentStrokes) => [...currentStrokes.slice(0, -1)]);
    } else {
      const strokeOutcome = allowFreeDrawGrading
        ? calculateFreeDrawStrokeOutcome(requiredPointsCharacter)
        : calculateStrokeOutcome(
          strokes[currentCharacterStrokeIndex],
          isDotStroke,
          requiredPointsCharacter,
          character,
          currentStroke,
        );
      if (shouldProvideFeedback) {
        createFeedbackMessages(strokeOutcome);
      }
      const hasMoreStrokes = currentCharacterStrokeIndex + 1 < character.strokes.length;
      handleStrokeOutcome(strokeOutcome, hasMoreStrokes, currentCharacterStrokeIndex + 1, strokes);

      // resets to prepare for next stroke
      setLastTouch(null);
      setTouchInterferenceDetected(false);
      if ((strokeOutcomes[strokeOutcome].type === StrokeOutcomeType.SUCCESS)
          && hasMoreStrokes) {
        resetForNextStrokeAtIndex(currentCharacterStrokeIndex + 1);
      }
    }
    isDrawing.current = false;
  };

  const handleTouchEndWithTouchSettings = () => {
    if (!isDrawing.current || !canWrite || !strokes.length) return;
    const lastStroke = strokes[strokes.length - 1];
    evaluateStrokeEnd(lastStroke, true);
  };

  const handleTouchEnd = (e: any, forceEnd?: boolean) => {
    // not drawing - skipping
    if (!isDrawing.current || !canWrite) return;

    // unless forced by timer or cancellation
    // ignore a touchend that's not from active stroke
    const lastStroke = strokes[strokes.length - 1];
    if (!lastStroke || (!forceEnd && e.pointerId !== lastStroke?.touchId)) {
      return;
    }

    evaluateStrokeEnd(lastStroke, false);
  };

  // reset timer anytime there's a touch event while user can write
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lastTouch) {
      const timer = setTimeout(() => (
        envSettings?.enableNewTouchSettings
          ? handleTouchEndWithTouchSettings()
          : handleTouchEnd(null, true)
      ), 800);
      return () => clearTimeout(timer);
    }
  }, [lastTouch]);

  return (
    <div style={{
      position: 'absolute',
      border: touchInterferenceDetected && envSettings?.enableNewTouchSettings ? '1px solid red' : 'none',
    }}
    >
      <Stage
        width={width}
        height={height}
        onTouchStart={envSettings?.enableNewTouchSettings ? handleExperimentalTouchStart : handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={envSettings?.enableNewTouchSettings ? () => {} : handleTouchEnd}
        onTouchCancel={envSettings?.enableNewTouchSettings ? () => {} : (e: any) => handleTouchEnd(e, true)}
      >
        <ModelPathLayer canWrite={canWrite}>
          <VisibleTargetZone
            show={showTargetZone}
            positioning={{
              width: targetAreaWidth,
              height: targetAreaHeight,
              xOffset: (width - targetAreaWidth) / 2,
            }}
          />
          <CharacterModelPath
            character={character}
            difficultyLevel={difficultyLevel}
            requiredPoints={requiredPointsCharacter}
            intersectedPoints={intersectedPoints}
            restrictedAreas={restrictedAreas}
            stroke={currentStroke}
            positioning={{ xOffset: positionX, yOffset: positionY }}
          />
        </ModelPathLayer>
        <LineLayer
          id="freedraw"
          strokes={strokes}
          difficultyLevel={difficultyLevel}
          strokeOpacity={drawnStrokeOpacity || 1}
        />
        <GuidePointsLayer
          showGuideDots={showGuideDots}
          showStartDotOnLoad={shouldShowOnlyStartDotOnLoad()}
          layerPositionX={positionX}
          layerPositionY={positionY}
          visibleMidPoints={currentStroke?.points?.filter((point) => point.visible) || []}
          startPoint={currentStartPoint}
          isDirectionalStart={isStartDotDirectional(currentStroke)}
          endPoint={requiredPointsCharacter?.length > 1 ? currentEndPoint : undefined}
          intersectedPoints={intersectedPoints}
          strokeEndedAtEndPoint={strokeEndedAtEndPoint}
          difficultyLevel={difficultyLevel}
          beginStartDotAnimation={beginStartDotAnimation()}
          removeStartDotAfterAnimation={removeStartDotAfterAnimation}
          beginStopDotAnimation={beginStopDotAnimation}
          currentActivityIteration={currentIteration}
        />
      </Stage>
    </div>
  );
}
