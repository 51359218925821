import React from 'react';
import { SpacingItem } from '../data/types';
import spacingScaffolds from '../assets/spacing-scaffolds';
import { ModelSpacingArea } from '../styles/components/ModelAreas';

interface ModelSpacingItemProps {
  currentItem: SpacingItem|null;
  showItem: boolean;
}

export default function ModelSpacingItem({ currentItem, showItem }: ModelSpacingItemProps) {
  if (!currentItem) return null;
  return (
    <ModelSpacingArea hide={!showItem}>
      <img
        alt={`practice character ${currentItem.display}`}
        src={spacingScaffolds[currentItem.type]?.MODEL || ''}
        height={currentItem.positioning.model.height || 0}
        style={{ bottom: `${currentItem.positioning.model.yOffset || 0}px`, position: 'relative' }}
      />
    </ModelSpacingArea>
  );
}
