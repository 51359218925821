import { useMemo } from 'react';
import {
  Character, CharacterType, DifficultyLevel, Point, Stroke,
} from '../data/types';

export default function useRequiredPoints(
  currentStroke: Stroke,
  currentChar: Character,
  difficultyLevel: DifficultyLevel = DifficultyLevel.NORMAL,
  allowFreeDrawGrading: boolean = false,
) {
  const getLoopChars = (): CharacterType[] => (
    [CharacterType.UPPER_O, CharacterType.UPPER_Q, CharacterType.NUMBER_0, CharacterType.LOWER_O]
  );

  const getStopDotDelayChars = (): CharacterType[] => (
    [CharacterType.LOWER_P, CharacterType.LOWER_G, CharacterType.LOWER_D]
  );

  // Loop letters do not have a visible midpoint. Which means it's possible to draw the stroke the wrong way
  // when removing all of the invisible midpoints from grading. To fix this, we add two invisible midpoints
  // that must be passed before the stop dot can be counted.
  const getRequiredPointsForLoopChar = () => (
    [
      currentStroke.points[0],
      currentStroke.points[1],
      {
        ...currentStroke.points[currentStroke.points.length - 2],
        allowIntersectionAfterOrderId: currentStroke.points[1].orderId,
      },
      {
        ...currentStroke.points[currentStroke.points.length - 1],
        allowIntersectionAfterOrderId: currentStroke.points[currentStroke.points.length - 2].orderId,
      },
    ]
  );

  // For characters where the stop dot is only shown after passing a certain point
  const getRequiredPointsForStopDotDelayChars = () => {
    if (currentChar.type === CharacterType.LOWER_P) {
      return [
        currentStroke.points[0],
        currentStroke.points[1],
        {
          ...currentStroke.points[2],
          allowIntersectionAfterOrderId: currentStroke.points[1].orderId,
        },
        currentStroke.points[currentStroke.points.length - 2],
        {
          ...currentStroke.points[currentStroke.points.length - 1],
          visibleAfterOrderId: currentStroke.points[2].orderId,
          allowIntersectionAfterOrderId: currentStroke.points[currentStroke.points.length - 2].orderId,
        },
      ];
    }

    if (currentChar.type === CharacterType.LOWER_G) {
      return [
        currentStroke.points[0],
        {
          ...currentStroke.points[2],
          allowIntersectionAfterOrderId: currentStroke.points[0].orderId,
        },
        {
          ...currentStroke.points[5],
          allowIntersectionAfterOrderId: currentStroke.points[2].orderId,
        },
        {
          ...currentStroke.points[currentStroke.points.length - 1],
          allowIntersectionAfterOrderId: currentStroke.points[5].orderId,
        },
      ];
    }

    if (currentChar.type === CharacterType.LOWER_D) {
      return [
        currentStroke.points[0],
        currentStroke.points[1],
        {
          ...currentStroke.points[5],
          allowIntersectionAfterOrderId: currentStroke.points[1].orderId,
        },
        {
          ...currentStroke.points[currentStroke.points.length - 1],
          visibleAfterOrderId: currentStroke.points[5].orderId,
          allowIntersectionAfterOrderId: currentStroke.points[5].orderId,
        },
      ];
    }
    return [];
  };

  const getReducedRequiredPoints = () => {
    const visibleMidPoints = currentStroke?.points?.filter((point) => point.visible) || [];

    return [
      currentStroke.points[0],
      ...visibleMidPoints,
      currentStroke.points[currentStroke.points.length - 1],
    ];
  };

  // Because we're filtering point data from characters,
  // we need to clean up the data to ensure order and ids are correct.
  const cleanPointData = (allReducedPoints: Point[]) => allReducedPoints.map((point, index) => {
    // if start dot, no changes are necessary
    if (index === 0) return point;
    // for mid and end points, update orderId data to ensure proper order
    const newPointData = {
      ...point,
      orderId: index,
      allowIntersectionAfterOrderId: index - 1,
    };

    if (newPointData.visibleAfterOrderId) {
      return {
        ...newPointData,
        visibleAfterOrderId: index - 1,
      };
    }
    return newPointData;
  });

  const requiredPointsSpacing = useMemo(() => {
    if (!currentStroke) return [];
    if (currentStroke.points.length === 1) return currentStroke.points || [];

    if (getLoopChars().includes(currentChar.type) && currentStroke.order === 1) {
      return cleanPointData(getRequiredPointsForLoopChar());
    }

    if (getStopDotDelayChars().includes(currentChar.type)) {
      return getRequiredPointsForStopDotDelayChars();
    }

    return cleanPointData(getReducedRequiredPoints());
  }, [currentStroke]);

  const requiredPointsCharacter = useMemo(() => {
    // For easy level we only want to grade start, stop, and visible mid points, if applicable.
    // During "free draw" for the Independent activity's 3rd iteration, we only want to grade start dot.
    if (!currentStroke) return [];
    // If we're not using Easy mode, "free draw", or stroke point length is only one, return all points for that stroke.
    if ((difficultyLevel !== DifficultyLevel.EASY
      || currentStroke.points.length === 1) && !allowFreeDrawGrading) return currentStroke.points || [];

    if (getLoopChars().includes(currentChar.type) && currentStroke.order === 1) {
      return cleanPointData(getRequiredPointsForLoopChar());
    }

    if (difficultyLevel === DifficultyLevel.EASY) {
      return cleanPointData(getReducedRequiredPoints());
    }

    if (allowFreeDrawGrading) {
      return [
        { ...currentStroke.points[0], allowIntersectionAfterOrderId: undefined },
      ];
    }

    return [];
  }, [currentStroke, difficultyLevel, allowFreeDrawGrading]);

  return {
    requiredPointsSpacing,
    requiredPointsCharacter,
  };
}
