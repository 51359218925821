import errorTryAgainTraceLine from './VO6-tryagain.m4a';
import errorTryAgain from './VO34.m4a';
import errorFindStartDot from './VO6-74-startdot.m4a';
import errorFindStartDotArrow from './VO6-72-startdot-arrow.m4a';
import errorStartAtStartDot from './VO6-75-startdot.m4a';
import errorStartAtStartDotArrow from './VO6-76-startdot-arrow.m4a';
import errorEndDot from './VO6-enddot.m4a';
import errorMultiTooClose from './VO54.m4a';
import errorMultiTooWide from './VO53.m4a';

import errorMakeLikeLetter from './VO83-letter.m4a';
import errorMakeLikeNumber from './VO83-number.m4a';
import errorMakeLikeSymbol from './VO83-symbol.m4a';

import errorStayOnSolidPath from './VO81-solid.m4a';
import errorStayOnFadedPath from './VO81-faded.m4a';
import errorStayOnDottedPath from './VO81-dotted.m4a';
import errorStayOnStarPath from './VO82.m4a';

import {
  ActivityType, CharacterCategory, SpacingActivityType, StrokeOutcome,
} from '../../../data/types';

const strokeErrors = {
  [StrokeOutcome.ERROR_GENERAL_TRACING]: {
    default: errorTryAgainTraceLine,
    solidPath: errorStayOnSolidPath,
    fadedPath: errorStayOnFadedPath,
    dottedPath: errorStayOnDottedPath,
    starPath: errorStayOnStarPath,
    noPath: errorTryAgain,
  },
  [StrokeOutcome.ERROR_START]: {
    withoutArrow: errorStartAtStartDot,
    withArrow: errorStartAtStartDotArrow,
  },
  [StrokeOutcome.ERROR_END]: errorEndDot,
  [StrokeOutcome.ERROR_TIME]: errorTryAgain,
  [StrokeOutcome.WARNING_INTERSECTION]: null,
  [StrokeOutcome.WARNING_INCORRECT_START_POINT]: {
    withoutArrow: errorFindStartDot,
    withArrow: errorFindStartDotArrow,
  },
  [StrokeOutcome.WARNING_REMINDER]: null,
  [StrokeOutcome.SUCCESS]: null,
};

export const multiChoiceSpacingErrors = {
  TOO_CLOSE: errorMultiTooClose,
  TOO_WIDE: errorMultiTooWide,
  CORRECT: null,
};

export const getStrokeErrorVO = (
  strokeOutcome: StrokeOutcome | null | undefined,
  isDirectionalStroke: boolean,
  activityType: ActivityType | SpacingActivityType | null | undefined,
) => {
  switch (strokeOutcome) {
    case StrokeOutcome.ERROR_GENERAL_TRACING:
      if (activityType === ActivityType.SOLID) {
        return strokeErrors[strokeOutcome].solidPath;
      }
      if (activityType === ActivityType.DOTTED) {
        return strokeErrors[strokeOutcome].dottedPath;
      }
      if (activityType === ActivityType.FADED || activityType === SpacingActivityType.TRACE_FADED) {
        return strokeErrors[strokeOutcome].fadedPath;
      }
      if (activityType === ActivityType.CHASE_STAR || activityType === SpacingActivityType.TRACE_SHOOTING_STAR) {
        return strokeErrors[strokeOutcome].starPath;
      }
      if (activityType === ActivityType.INDEPENDENT || activityType === SpacingActivityType.SPACE_INDEPENDENT) {
        return strokeErrors[strokeOutcome].noPath;
      }
      // all remaining spacing activity errors
      return strokeErrors[strokeOutcome].default;
    case StrokeOutcome.ERROR_END:
      if (activityType === ActivityType.INDEPENDENT || activityType === SpacingActivityType.SPACE_INDEPENDENT) {
        return strokeErrors[StrokeOutcome.ERROR_GENERAL_TRACING].noPath;
      }
      return strokeErrors[strokeOutcome];
    case StrokeOutcome.ERROR_TIME:
      return strokeErrors[strokeOutcome];
    case StrokeOutcome.WARNING_INCORRECT_START_POINT:
    case StrokeOutcome.ERROR_START:
      return isDirectionalStroke
        ? strokeErrors[strokeOutcome].withArrow
        : strokeErrors[strokeOutcome].withoutArrow;
    default:
      return null;
  }
};

export const getStrokeErrorVOByCharCategory = (
  characterCategory: CharacterCategory | undefined | null,
) => {
  switch (characterCategory) {
    case CharacterCategory.LETTER_LOWER:
    case CharacterCategory.LETTER_UPPER:
      return errorMakeLikeLetter;
    case CharacterCategory.NUMBER:
      return errorMakeLikeNumber;
    case CharacterCategory.SYMBOL:
      return errorMakeLikeSymbol;
    default:
      return null;
  }
};
