import { v4 as uuidv4 } from 'uuid';
import { DrawingStroke } from '../components/Konva/types';
import { ActionType, useGameContext } from '../context/GameContext';
import { ActivityType, DifficultyLevel, DrawnCharacter } from '../data/types';
import useDifficultyLevel from './useDifficultyLevel';

export default function useDrawnCharacters() {
  const ctx = useGameContext();
  const { difficultyLevel } = useDifficultyLevel();

  const allDrawnCharsForCurrentChar = (): DrawnCharacter[] => {
    if (!ctx) return [];
    return ctx.drawnCharacters
      .filter((drawnChar) => drawnChar.sequenceItemOrder === ctx?.currentSequenceItemOrder
        && drawnChar.characterId === ctx.currentCharacter?.type);
  };

  const currentSelectionForCurrentChar = (): DrawnCharacter | undefined => allDrawnCharsForCurrentChar()
    .find((drawnChar) => drawnChar.sequenceItemOrder === ctx?.currentSequenceItemOrder
      && drawnChar.characterId === ctx?.currentCharacter?.type
      && drawnChar.isSelected);

  const dispatchDrawnCharacters = (
    strokes: DrawingStroke[],
  ): void => {
    if (ctx && ctx.currentCharacter
      && ctx.currentSequence
      && ctx.currentActivity?.type === ActivityType.INDEPENDENT) {
      const currentIteration = ctx.currentActivity.currentIteration || 1;
      const currentIterationIndex = currentIteration - 1;
      const drawnCharacter: DrawnCharacter = {
        id: uuidv4(),
        order: currentIterationIndex,
        characterId: ctx.currentCharacter.type,
        sequenceId: ctx.currentSequence.id,
        sequenceItemOrder: ctx.currentSequenceItemOrder,
        drawingStrokes: [...strokes],
        isSelected: false,
      };

      const currentCharCount = ctx.drawnCharacters
        .filter((drawnChar) => drawnChar.sequenceItemOrder === ctx?.currentSequenceItemOrder
          && drawnChar.characterId === drawnCharacter.characterId)
        .length;
      const requiredIterations = difficultyLevel === DifficultyLevel.EASY
        ? 1
        : ctx.currentActivity.requiredIterations || 1;
      if (currentCharCount === requiredIterations) {
        ctx.dispatch({ type: ActionType.DELETE_DRAWN_CHARACTERS, payload: ctx.currentCharacter?.type });
      }
      ctx.dispatch({ type: ActionType.ADD_DRAWN_CHARACTERS, payload: drawnCharacter });
    }
  };

  return {
    allDrawnCharsForCurrentChar,
    currentSelectionForCurrentChar,
    dispatchDrawnCharacters,
  };
}
