/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { captureMessage } from '@sentry/react';
import VideoContainer from '../styles/components/VideoContainer';
import starLoader from '../assets/lotties/loader-star.json';
import LottiePlayer from './LottiePlayer';

interface VideoProps {
  path: string,
  start: boolean,
  onLoad: () => void,
  onComplete: () => void,
  onError: () => void,
}

export default function Video({
  path, start, onLoad, onComplete, onError,
}: VideoProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const videoRef = useRef<any>(null);

  useEffect(() => {
    if (videoRef && videoRef.current && start) {
      videoRef.current.play();
    }
  }, [start]);

  const handleCanPlayThrough = () => {
    setIsLoading(false);
    onLoad();
  };

  const handleError = () => {
    // If there's an error loading, retry
    if (videoRef && videoRef.current) {
      videoRef.current.src = path;
      videoRef.current.load();
    }

    // Allow one reload, then send error
    if (isLoading) {
      setIsLoading(false);
    } else {
      captureMessage(`Video failed to load: ${path}`);
      setHasError(true);
      onError();
    }
  };

  return (
    <VideoContainer>
      {isLoading && (
        <div className="loading-container">
          <LottiePlayer
            data={starLoader}
            width={290}
            shouldAutoplay
            shouldLoop
          />
        </div>
      )}
      {!hasError ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            onEnded={() => onComplete()}
            onCanPlayThrough={() => handleCanPlayThrough()}
            onError={() => handleError()}
            src={path}
          >
            <source
              type="video/mp4"
            />
          </video>
        </>
      ) : (
        <span className="warning">Video not available</span>)}
    </VideoContainer>
  );
}
