import { SpacingActivityCategory, SpacingActivityType, SpacingItemType } from '../../../data/types';
import amFadedIntro from './VO55-AM.m4a';
import amShootingStarIntro from './VO59-AM.m4a';
import amIndeIntro from './VO63-AM.m4a';

import atFadedIntro from './VO55-AT.m4a';
import atShootingStarIntro from './VO59-AT.m4a';
import atIndeIntro from './VO63-AT.m4a';

import bagFadedIntro from './VO55-BAG.m4a';
import bagShootingStarIntro from './VO59-BAG.m4a';
import bagIndeIntro from './VO63-BAG.m4a';

import chFadedIntro from './VO56-CH.m4a';
import chShootingStarIntro from './VO60-CH.m4a';
import chIndeIntro from './VO64-CH.m4a';

import ifFadedIntro from './VO55-IF.m4a';
import ifShootingStarIntro from './VO59-IF.m4a';
import ifIndeIntro from './VO63-IF.m4a';

import inFadedIntro from './VO55-IN.m4a';
import inShootingStarIntro from './VO59-IN.m4a';
import inIndeIntro from './VO63-IN.m4a';

import itFadedIntro from './VO55-IT.m4a';
import itShootingStarIntro from './VO59-IT.m4a';
import itIndeIntro from './VO63-IT.m4a';

import noFadedIntro from './VO55-NO.m4a';
import noShootingStarIntro from './VO59-NO.m4a';
import noIndeIntro from './VO63-NO.m4a';

import redFadedIntro from './VO55-RED.m4a';
import redShootingStarIntro from './VO59-RED.m4a';
import redIndeIntro from './VO63-RED.m4a';

import shFadedIntro from './VO56-SH.m4a';
import shShootingStarIntro from './VO60-SH.m4a';
import shIndeIntro from './VO64-SH.m4a';

import thFadedIntro from './VO56-TH.m4a';
import thShootingStarIntro from './VO60-TH.m4a';
import thIndeIntro from './VO64-TH.m4a';

import upFadedIntro from './VO55-UP.m4a';
import upShootingStarIntro from './VO59-UP.m4a';
import upIndeIntro from './VO63-UP.m4a';

import usFadedIntro from './VO55-US.m4a';
import usShootingStarIntro from './VO59-US.m4a';
import usIndeIntro from './VO63-US.m4a';

import yesFadedIntro from './VO55-YES.m4a';
import yesShootingStarIntro from './VO59-YES.m4a';
import yesIndeIntro from './VO63-YES.m4a';

import multiChoiceIntro from './VO51.m4a';

import planet1Intro from './VO44.m4a';
import planet2Intro from './VO46.m4a';

type SpacingActivityIntros = {
  [typeKey in SpacingActivityType | SpacingActivityCategory]?: any
}

const spacingActivityIntros: SpacingActivityIntros = {
  [SpacingActivityType.TRACE_FADED]: {
    [SpacingItemType.AM]: amFadedIntro,
    [SpacingItemType.AT]: atFadedIntro,
    [SpacingItemType.BAG]: bagFadedIntro,
    [SpacingItemType.CH]: chFadedIntro,
    [SpacingItemType.IF]: ifFadedIntro,
    [SpacingItemType.IN]: inFadedIntro,
    [SpacingItemType.IT]: itFadedIntro,
    [SpacingItemType.NO]: noFadedIntro,
    [SpacingItemType.RED]: redFadedIntro,
    [SpacingItemType.SH]: shFadedIntro,
    [SpacingItemType.TH]: thFadedIntro,
    [SpacingItemType.UP]: upFadedIntro,
    [SpacingItemType.US]: usFadedIntro,
    [SpacingItemType.YES]: yesFadedIntro,
  },

  [SpacingActivityCategory.MULTI_CHOICE]: multiChoiceIntro,

  [SpacingActivityType.TRACE_SHOOTING_STAR]: {
    [SpacingItemType.AM]: amShootingStarIntro,
    [SpacingItemType.AT]: atShootingStarIntro,
    [SpacingItemType.BAG]: bagShootingStarIntro,
    [SpacingItemType.CH]: chShootingStarIntro,
    [SpacingItemType.IF]: ifShootingStarIntro,
    [SpacingItemType.IN]: inShootingStarIntro,
    [SpacingItemType.IT]: itShootingStarIntro,
    [SpacingItemType.NO]: noShootingStarIntro,
    [SpacingItemType.RED]: redShootingStarIntro,
    [SpacingItemType.SH]: shShootingStarIntro,
    [SpacingItemType.TH]: thShootingStarIntro,
    [SpacingItemType.UP]: upShootingStarIntro,
    [SpacingItemType.US]: usShootingStarIntro,
    [SpacingItemType.YES]: yesShootingStarIntro,
  },

  [SpacingActivityType.SPACE_INDEPENDENT]: {
    [SpacingItemType.AM]: amIndeIntro,
    [SpacingItemType.AT]: atIndeIntro,
    [SpacingItemType.BAG]: bagIndeIntro,
    [SpacingItemType.CH]: chIndeIntro,
    [SpacingItemType.IF]: ifIndeIntro,
    [SpacingItemType.IN]: inIndeIntro,
    [SpacingItemType.IT]: itIndeIntro,
    [SpacingItemType.NO]: noIndeIntro,
    [SpacingItemType.RED]: redIndeIntro,
    [SpacingItemType.SH]: shIndeIntro,
    [SpacingItemType.TH]: thIndeIntro,
    [SpacingItemType.UP]: upIndeIntro,
    [SpacingItemType.US]: usIndeIntro,
    [SpacingItemType.YES]: yesIndeIntro,
  },

  [SpacingActivityType.PLANET_1_GAME]: planet1Intro,
  [SpacingActivityType.PLANET_2_GAME]: planet2Intro,
};

export default spacingActivityIntros;
