import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ZoomOutAnimation from '../../styles/components/ZoomOutAnimation';
import yellowStar from '../../assets/images/star-yellow-black-outline.png';
import { useAudioContext } from '../../context/AudioContext';
import ready from '../../assets/audio/instructions/VO49-readysetgo-ready.m4a';

type ReadySetGoStarProps = {
  start: boolean,
  animationData: { translate: [number, number] },
  onAnimComplete: () => void,
}

export default function ReadySetGoStarAnimation({
  start, animationData, onAnimComplete,
}: ReadySetGoStarProps) {
  const audioContext = useAudioContext();
  const [showStar, setShowStar] = useState(false);
  const { translate } = animationData;
  const ref = useRef(null);

  useEffect(() => {
    if (start) {
      // triggers onEnter, onEntering, onEntered in CSSTransition
      setShowStar(true);
      audioContext?.handlePlay({
        src: ready,
        onEnd: () => {
          setTimeout(() => {
          // triggers onExit, onExited in CSSTransition
            setShowStar(false);
            onAnimComplete();
          }, 500);
        },
      });
    }
  }, [start]);

  return (
    <div>
      <CSSTransition
        nodeRef={ref}
        in={showStar}
        timeout={1700}
        classNames="zoomOut"
        unmountOnExit /* unmount the animation when it's finished (so it doesn't show when writing) */
      >
        <ZoomOutAnimation tranlsatePx={[`${translate[0]}px`, `${translate[1]}px`]} ref={ref}>
          <img src={yellowStar} alt="" width="130px" />
        </ZoomOutAnimation>
      </CSSTransition>

    </div>
  );
}
