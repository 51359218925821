import React, { useState, useRef, useEffect } from 'react';
import { Stage } from 'react-konva';
import {
  Character, DifficultyLevel, Pen, PracticeLinesRelationship, SpacingItem, StrokeOutcome,
} from '../../data/types';
import {
  DRAWABLE_MARGIN,
  TARGET_HEIGHT_NORMAL_EXTENDED,
  TARGET_HEIGHT_NORMAL,
} from '../../shared/constants';
import LineLayer from './LineLayer';
import GuidePointsLayer from './GuidePointsLayer';
import useKonvaGrading from '../../hooks/useKonvaGrading';
import useKonvaPalmRejection from '../../hooks/useKonvaPalmRejection';
import { SpacingActionOutcome } from '../../stateMachines/spacingMachine';
import { DrawingStroke } from './types';
import VisibleTargetZone from './VisibleTargetZone';
import CharacterModelPath from './CharacterModelPath';
import useCanvasVisibleCorrectiveFeedback from '../../hooks/useCanvasVisibleCorrectiveFeedback';
import SpacingRestrictedAreas from './SpacingRestrictedAreas';
import ModelPathLayer from './ModelPathLayer';
import useDirectionalStartDot from '../../hooks/useDirectionalStartDot';
import useRequiredPoints from '../../hooks/useRequiredPoints';

interface WritingAreaProps {
  width: number,
  height: number,
  spacingItem: SpacingItem,
  character: Character,
  activePen: Pen,
  canWrite: boolean,
  doReset: boolean,
  showGuideDots: boolean,
  showTargetZone: boolean,
  activityInProgressStrokes: DrawingStroke[]|null,
  currentIteration: number,
  handleSpacingOutcome: (
    result: SpacingActionOutcome,
    charStrokeOutcome: StrokeOutcome,
    hasMoreStrokes: boolean,
    strokeCount: number,
    errorLocations: string,
    strokes: DrawingStroke[],
  ) => void,
  handleTapOffStart: (currentStrokeIndex: number) => void,
}

export default function SpacingWritingAreaStage({
  width,
  height,
  spacingItem,
  character,
  activePen,
  canWrite,
  doReset,
  showGuideDots,
  showTargetZone,
  activityInProgressStrokes,
  currentIteration,
  handleSpacingOutcome,
  handleTapOffStart,
}: WritingAreaProps) {
  const {
    strokes,
    setStrokes,
    intersectedPoints,
    resetGradingForNextStroke,
    updateIntersectionsFromActivePointer,
    updateLastStrokeFromActivePointer,
    initializeStroke,
    calculateSpacingOutcome,
    spacingIntersectionErrorsToString,
    calculateSpacingCharFormationStrokeOutcome,
  } = useKonvaGrading({ width, height });
  const {
    isTouchTypeAllowed,
    allowActiveStylusOnly,
    getActivePointerFromKonva,
    getAllowedStartingPointerFromKonva,
    setTouchInterferenceDetected,
  } = useKonvaPalmRejection();
  const {
    beginStartDotAnimation,
    removeStartDotAfterAnimation,
    beginStopDotAnimation,
  } = useCanvasVisibleCorrectiveFeedback();
  const isStartDotDirectional = useDirectionalStartDot();
  const [currentCharacterStrokeIndex, setCurrentCharacterStrokeIndex] = useState<number>(0);
  const [lastTouch, setLastTouch] = useState<Date|null>(null);
  const isDrawing = useRef(false);
  const currentStroke = character.strokes[currentCharacterStrokeIndex] || null;
  const isDotStroke = currentStroke?.points.length === 1;
  const restrictedAreas = currentStroke?.restrictedAreas || [];
  const currentStartPoint = currentStroke?.points?.[0] || { x: 0, y: 0 };
  const currentEndPoint = currentStroke?.points?.[currentStroke?.points?.length
    ? Number(currentStroke?.points?.length) - 1 : 0] || { x: 0, y: 0 };
  const { requiredPointsSpacing } = useRequiredPoints(currentStroke, character);

  const resetForNextStrokeAtIndex = (index: number) => {
    resetGradingForNextStroke();
    setCurrentCharacterStrokeIndex(index);
  };

  useEffect(() => {
    if (doReset) {
      setStrokes([]);
      resetForNextStrokeAtIndex(0);
    }
  }, [doReset]);

  useEffect(() => {
    if (activityInProgressStrokes) {
      setStrokes([...activityInProgressStrokes]);
    }
  }, []);

  const addDataToCurrentStroke = (e: any) => {
    const stage = e.target.getStage();
    const lastStroke = strokes[strokes.length - 1];
    const activePointer = getActivePointerFromKonva(e, stage, lastStroke);

    if (!lastStroke || !activePointer) {
      return;
    }
    updateLastStrokeFromActivePointer(lastStroke, activePointer, e);
    updateIntersectionsFromActivePointer(
      stage,
      activePointer,
      requiredPointsSpacing[requiredPointsSpacing.length - 1].orderId || 1,
      true,
    );
  };

  const handleTouchStart = (e: any) => {
    // prevent creation of a second mistake stroke from palm interference
    if (!canWrite || isDrawing.current || strokes.length > currentCharacterStrokeIndex) {
      return;
    }

    const stage = e.target.getStage();
    const requireStrokeFromStartZone = !allowActiveStylusOnly;
    const activePointer = getAllowedStartingPointerFromKonva(stage, e);
    // ignore disallowed touch type based on settings
    if (!isTouchTypeAllowed(e.evt, activePointer?.id)) return;

    // only initialize stroke if valid pointer exists
    if (activePointer) {
      isDrawing.current = true;

      if (isDotStroke) {
        setLastTouch(new Date());
        updateIntersectionsFromActivePointer(stage, activePointer, 1, true);
      }

      initializeStroke(activePointer, isDotStroke, activePen);
    } else if (requireStrokeFromStartZone) {
      handleTapOffStart(currentCharacterStrokeIndex);
    }
  };

  const handleTouchMove = (e: any) => {
    // note a touch event anytime it happens while writing
    // even if not valid to prevent grading while hand is moving
    if (canWrite) {
      setLastTouch(new Date());
    }

    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }

    addDataToCurrentStroke(e);
  };

  const handleTouchEnd = (e: any, forceEnd?: boolean) => {
    // not drawing - skipping
    if (!isDrawing.current || !canWrite || !character || !currentStroke) return;

    // unless forced by timer or cancellation
    // ignore a touchend that's not from active stroke
    const lastStroke = strokes[strokes.length - 1];
    if (!lastStroke || (!forceEnd && e.pointerId !== lastStroke?.touchId)) {
      return;
    }

    isDrawing.current = false;

    // we want to allow kids to make a single tap/false start without penalty (unless it's a dot)
    // but if longer than 1 point, the stroke should be graded
    const isSinglePointFalseStartStroke = !isDotStroke && lastStroke.points?.length <= 2;
    if (isSinglePointFalseStartStroke) {
      setStrokes((currentStrokes) => [...currentStrokes.slice(0, -1)]);
    } else {
      const hasMoreStrokes = currentCharacterStrokeIndex + 1 < character.strokes.length;
      const charStrokeOutcome = calculateSpacingCharFormationStrokeOutcome(
        character,
        requiredPointsSpacing,
      );
      handleSpacingOutcome(
        calculateSpacingOutcome(),
        charStrokeOutcome,
        hasMoreStrokes,
        currentCharacterStrokeIndex + 1,
        spacingIntersectionErrorsToString(),
        strokes,
      );

      // resets to prepare for next stroke
      setLastTouch(null);
      setTouchInterferenceDetected(false);
      if (hasMoreStrokes) {
        resetForNextStrokeAtIndex(currentCharacterStrokeIndex + 1);
      }
    }
  };

  // reset timer anytime there's a touch event while user can write
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lastTouch) {
      const timer = setTimeout(() => (
        handleTouchEnd(null, true)
      ), 800);
      return () => clearTimeout(timer);
    }
  }, [lastTouch]);

  if (!character) return null;

  return (
    <div style={{
      position: 'absolute',
    }}
    >
      <Stage
        width={width}
        height={height}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={(e: any) => handleTouchEnd(e, true)}
      >
        <ModelPathLayer canWrite={canWrite}>
          <VisibleTargetZone
            show={showTargetZone}
            positioning={{
              width,
              height: character.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES
                ? TARGET_HEIGHT_NORMAL_EXTENDED
                : TARGET_HEIGHT_NORMAL,
              xOffset: 0,
            }}
          />
          <CharacterModelPath
            character={character}
            difficultyLevel={DifficultyLevel.NORMAL}
            requiredPoints={requiredPointsSpacing}
            intersectedPoints={intersectedPoints}
            restrictedAreas={restrictedAreas}
            stroke={currentStroke}
            positioning={{
              xOffset: spacingItem.positioning.scaffold.xOffset || 0,
              yOffset: DRAWABLE_MARGIN + (character.positioning[DifficultyLevel.NORMAL]?.scaffold.yOffset || 0),
            }}
          >
            <SpacingRestrictedAreas gradingPositions={spacingItem.positioning.grading} />
          </CharacterModelPath>
        </ModelPathLayer>
        <LineLayer
          id="freedraw"
          strokes={strokes}
          difficultyLevel={DifficultyLevel.NORMAL}
          strokeOpacity={1}
        />
        <GuidePointsLayer
          showGuideDots={showGuideDots}
          showStartDotOnLoad={false}
          layerPositionX={spacingItem.positioning.scaffold.xOffset || 0}
          layerPositionY={DRAWABLE_MARGIN + (character.positioning[DifficultyLevel.NORMAL]?.scaffold.yOffset || 0)}
          visibleMidPoints={currentStroke?.points?.filter((point) => point.visible) || []}
          startPoint={currentStartPoint}
          isDirectionalStart={isStartDotDirectional(currentStroke)}
          endPoint={requiredPointsSpacing?.length > 1 ? currentEndPoint : undefined}
          intersectedPoints={intersectedPoints}
          strokeEndedAtEndPoint
          difficultyLevel={DifficultyLevel.NORMAL}
          beginStartDotAnimation={beginStartDotAnimation()}
          removeStartDotAfterAnimation={removeStartDotAfterAnimation}
          beginStopDotAnimation={beginStopDotAnimation}
          currentActivityIteration={currentIteration}
        />
      </Stage>
    </div>
  );
}
