import { CorrectiveFeedback, CorrectiveLegibilityTypes, StrokeOutcome } from '../data/types';
import { SpacingActionOutcome } from '../stateMachines/spacingMachine';

const getAnalyticsStrokeOutcomeMessage = (strokeOutcome: StrokeOutcome): string => {
  switch (strokeOutcome) {
    case StrokeOutcome.ERROR_GENERAL_TRACING:
      return 'Error';
    case StrokeOutcome.ERROR_START:
      return 'Error - Start Dot';
    case StrokeOutcome.ERROR_END:
      return 'Error - End Dot';
    case StrokeOutcome.ERROR_TIME:
      return 'Error - Too much time';
    case StrokeOutcome.WARNING_INTERSECTION:
      return 'Error - Did not intersect';
    default:
      return 'Success';
  }
};

export const getCorrectiveFeedbackMessage = (
  correctiveFeedback: CorrectiveFeedback | CorrectiveLegibilityTypes,
): string => {
  switch (correctiveFeedback) {
    case CorrectiveFeedback.AUTOMATICITY_MEMORY:
      return 'Automaticity: Memory';
    case CorrectiveFeedback.AUTOMATICITY_SPEED:
      return 'Automaticity: Rate of speed';
    case CorrectiveLegibilityTypes.CLOSE_CIRCLE:
      return 'Legibility: Circle was not closed';
    case CorrectiveLegibilityTypes.CLOSE_GAP:
      return 'Legibility: Gap was present';
    default:
      return 'Default corrective feedback';
  }
};

export const getAnalyticsSpacingOutcomeMessage = (spacingOutcome: SpacingActionOutcome): string => {
  switch (spacingOutcome) {
    case SpacingActionOutcome.FAIL:
      return 'Failed';
    default:
      return 'Passed';
  }
};

export default getAnalyticsStrokeOutcomeMessage;
