export enum ActivityType {
  SOLID = 'SOLID',
  FADED = 'FADED',
  DOTTED = 'DOTTED',
  CHASE_STAR = 'CHASE_STAR',
  INDEPENDENT = 'INDEPENDENT',
  STAR_GAME = 'STAR_GAME',
}

export type Activity = {
  type: ActivityType,
  currentIteration: number,
  requiredIterations: number,
  nextActivityType: ActivityType | null,
  // each child array represents an iteration of the activity
  starMeterFrames?: [number, number][],
}

export enum SpacingActivityType {
  TRACE_FADED = 'TRACE_FADED',
  MULTI_CHOICE_CLOSE = 'MULTI_CHOICE_CLOSE',
  MULTI_CHOICE_WIDE = 'MULTI_CHOICE_WIDE',
  MULTI_CHOICE_ALL = 'MULTI_CHOICE_ALL',
  TRACE_SHOOTING_STAR = 'TRACE_SHOOTING_STAR',
  SPACE_INDEPENDENT = 'SPACE_INDEPENDENT',
  SELF_ASSESSMENT = 'SELF_ASSESSMENT',
  PLANET_1_GAME = 'PLANET_1_GAME',
  PLANET_2_GAME = 'PLANET_2_GAME'
}

export enum SpacingActivityCategory {
  WRITING = 'WRITING',
  MULTI_CHOICE = 'MULTI_CHOICE',
  PLANET_GAME = 'PLANET_GAME'
}

export type SpacingActivity = {
  type: SpacingActivityType,
  requiredIterations: number,
  category: SpacingActivityCategory,
  nextActivityType: SpacingActivityType | null,
  title: string,
  // each child array represents an iteration of the activity
  starMeterFrames?: [number, number][],
}
