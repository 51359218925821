import React, {
  createContext, useContext, useReducer, useMemo,
} from 'react';
import {
  Character,
  UnlockedCharacters,
  InstructionalSequence,
  Activity,
  SequenceCategory,
  StrokeOutcome,
  DrawnCharacter,
  CharacterType,
  ActivityType,
  DifficultyLevel,
  StrokeOutcomeType,
  InstructionalSequenceType,
} from '../data/types';
import activities from '../data/activities';
import deepCopy from '../shared/deepCopy';
import envSettings from '../data/envSettings';
import { strokeOutcomes } from '../data/stroke-outcomes';
import { getNextCharacterSequenceInOrder } from '../data/sequences';

/* GameFlowStep:
*   1) reflects changes in navigation/views similar to routes
*   2) it's only used for the activity flow (vs. the other user views made available as routes).
*/
export enum GameFlowStep {
  INIT = 'INIT', // before sequence begins -> select sequence
  CREATE_CUSTOM_SET = 'CREATE_CUSTOM_SET', // used by teachers before sequence flow begins
  INTRO_CHARACTER = 'INTRO_CHARACTER', // confirm next letter in sequence
  SELECT_PRACTICE_CHARACTER = 'SELECT_PRACTICE_CHARACTER', // practice sequence, no next character
  VIEW_KINESTHETIC_GROUP = 'VIEW_KINESTHETIC_GROUP', // view kinesthetic group before writing
  WATCH_CHARACTER_DEMO_VIDEO = 'WATCH_CHARACTER_DEMO_VIDEO', // watch demo video before activities, and during
  WATCH_GRIP_DEMO_VIDEO = 'WATCH_GRIP_DEMO_VIDEO', // watch grip demo video before activities, and during
  WRITING = 'WRITING', // sequence is in progress
  SEQUENCE_COMPLETED = 'SEQUENCE_COMPLETED', // sequence complete -> select custom sequence/character
  SELF_ASSESSMENT = 'SELF_ASSESSMENT', // select easiest to read drawn character from independent or practice activity
}

export enum GameMode {
  ACTIVE = 'ACTIVE',
  FEEDBACK = 'FEEDBACK',
}

type GameState = {
  flowStep: GameFlowStep,
  currentSequence: InstructionalSequence|null,
  currentSequenceItemOrder: number,
  currentCharacter: Character|null,
  currentActivity: Activity|null,
  errorCount: number,
  lastStrokeOutcome: StrokeOutcome|null,
  activityStrokesCompleted: number,
  gameMode: GameMode,
  unlockedCharacters: UnlockedCharacters,
  shouldRequireAccessToSequenceScreen: boolean,
  canSkipDemoVideo: boolean,
  hasCompletedSequence: boolean,
  drawnCharacters: DrawnCharacter[],
}

export enum ActionType {
  RESET = 'RESET',
  SELECT_SEQUENCE = 'SELECT_SEQUENCE',
  SELECT_PRACTICE_SEQUENCE = 'SELECT_PRACTICE_SEQUENCE',
  SELECT_CUSTOM_CHARACTER = 'SELECT_CUSTOM_CHARACTER',
  CREATE_CUSTOM_SET = 'CREATE_CUSTOM_SET',
  START_WRITING = 'START_WRITING',
  DRAW_STROKE = 'DRAW_STROKE',
  STROKE_FALSE_START = 'STROKE_FALSE_START',
  COMPLETE_ACTIVITY_LEVEL = 'COMPLETE_ACTIVITY_LEVEL',
  LEAVE_END_OF_SET_PAGE = 'LEAVE_END_OF_SET_PAGE',
  SKIP_TO_ACTIVITY = 'SKIP_TO_ACTIVITY',
  SKIP_CHARACTER = 'SKIP_CHARACTER',
  RESET_ACTIVITY = 'RESET_ACTIVITY',
  SKIP_CUSTOM_SEQUENCE = 'SKIP_CUSTOM_SEQUENCE',
  GO_TO_KINESTHETIC_GROUP_PAGE = 'GO_TO_KINESTHETIC_GROUP_PAGE',
  GO_TO_CHARACTER_DEMO_VIDEO = 'GO_TO_CHARACTER_DEMO_PAGE',
  GO_TO_GRIP_DEMO_VIDEO = 'GO_TO_GRIP_DEMO_PAGE',
  GO_TO_SELF_ASSESS_PAGE = 'GO_TO_SELF_ASSESS_PAGE',
  LEAVE_SELF_ASSESS_PAGE = 'LEAVE_SELF_ASSESS_PAGE',
  ADD_DRAWN_CHARACTERS = 'ADD_DRAWN_CHARACTERS',
  UPDATE_DRAWN_CHARACTERS = 'UPDATE_DRAWN_CHARACTERS',
  DELETE_DRAWN_CHARACTERS = 'DELETE_DRAWN_CHARACTERS',
  RESUME_GAME_PLAY = 'RESUME_GAME_PLAY',
}

type Action =
  { type: ActionType.RESET }
  | { type: ActionType.SELECT_SEQUENCE, payload: InstructionalSequence }
  | { type: ActionType.SELECT_PRACTICE_SEQUENCE, payload: InstructionalSequence }
  | { type: ActionType.SELECT_CUSTOM_CHARACTER, payload: Character }
  | { type: ActionType.CREATE_CUSTOM_SET }
  | { type: ActionType.START_WRITING }
  | { type: ActionType.DRAW_STROKE, payload: StrokeOutcome }
  | { type: ActionType.COMPLETE_ACTIVITY_LEVEL }
  | { type: ActionType.LEAVE_END_OF_SET_PAGE }
  | { type: ActionType.SKIP_TO_ACTIVITY, payload: Activity }
  | { type: ActionType.SKIP_CHARACTER }
  | { type: ActionType.RESET_ACTIVITY, payload: GameMode }
  | { type: ActionType.SKIP_CUSTOM_SEQUENCE }
  | { type: ActionType.GO_TO_KINESTHETIC_GROUP_PAGE }
  | { type: ActionType.GO_TO_CHARACTER_DEMO_VIDEO, payload: boolean }
  | { type: ActionType.GO_TO_GRIP_DEMO_VIDEO }
  | { type: ActionType.GO_TO_SELF_ASSESS_PAGE }
  | { type: ActionType.LEAVE_SELF_ASSESS_PAGE }
  | { type: ActionType.ADD_DRAWN_CHARACTERS, payload: DrawnCharacter }
  | { type: ActionType.UPDATE_DRAWN_CHARACTERS, payload: DrawnCharacter }
  | { type: ActionType.DELETE_DRAWN_CHARACTERS, payload: CharacterType }
  | { type: ActionType.RESUME_GAME_PLAY, payload: { currentIteration: number } }
  | { type: ActionType.STROKE_FALSE_START, payload: StrokeOutcome };

export const initialState: GameState = {
  flowStep: GameFlowStep.INIT,
  currentSequence: null,
  currentSequenceItemOrder: 0,
  currentCharacter: null,
  currentActivity: null,
  errorCount: 0,
  lastStrokeOutcome: null,
  activityStrokesCompleted: 0,
  unlockedCharacters: {},
  gameMode: GameMode.ACTIVE,
  shouldRequireAccessToSequenceScreen: false,
  canSkipDemoVideo: false,
  hasCompletedSequence: false,
  drawnCharacters: [],
};

export const getDifficultyLevel = (currentActivityType: ActivityType|undefined, currentCharacter: Character|null) => {
  if (!currentActivityType || !currentCharacter) return DifficultyLevel.NORMAL;
  if (currentActivityType === ActivityType.CHASE_STAR) return DifficultyLevel.NORMAL;
  const isLevelAvailable = currentCharacter.positioning[envSettings.difficultyLevel];
  if (!isLevelAvailable) return DifficultyLevel.NORMAL;
  return envSettings.difficultyLevel;
};

interface GameContextInterface extends GameState {
  dispatch: React.Dispatch<any>,
}

const GameContext = createContext<GameContextInterface | null>(null);

type GameContextProviderProps = {
  children: React.ReactNode,
}

export const reducer = (currentState: GameState, action: Action): GameState => {
  switch (action.type) {
    case ActionType.RESET:
      return initialState;
    case ActionType.SELECT_SEQUENCE: {
      const nextMode = GameFlowStep.INTRO_CHARACTER;
      const nextSequence = deepCopy(action.payload);
      const nextCharacter = nextSequence.characters.shift() as Character;
      const isCustomSet = nextSequence?.id === InstructionalSequenceType.CUSTOM_1;
      return {
        ...currentState,
        flowStep: nextMode,
        currentSequence: nextSequence,
        currentCharacter: nextCharacter,
        currentActivity: activities.SOLID,
        gameMode: GameMode.ACTIVE,
        shouldRequireAccessToSequenceScreen: isCustomSet,
      };
    }
    case ActionType.SELECT_CUSTOM_CHARACTER: {
      return {
        ...currentState,
        flowStep: GameFlowStep.INTRO_CHARACTER,
        currentCharacter: { ...action.payload },
        currentActivity: activities.SOLID,
        gameMode: GameMode.ACTIVE,
      };
    }
    case ActionType.SELECT_PRACTICE_SEQUENCE: {
      const sequence = action.payload;
      return {
        ...currentState,
        flowStep: GameFlowStep.SELECT_PRACTICE_CHARACTER,
        currentSequence: deepCopy(action.payload),
        unlockedCharacters: sequence?.unlockedCharacters,
      };
    }
    case ActionType.CREATE_CUSTOM_SET: {
      return {
        ...currentState,
        flowStep: GameFlowStep.CREATE_CUSTOM_SET,
      };
    }
    case ActionType.START_WRITING:
      return {
        ...currentState,
        flowStep: GameFlowStep.WRITING,
        shouldRequireAccessToSequenceScreen: true,
        canSkipDemoVideo: initialState.canSkipDemoVideo,
      };
    case ActionType.DRAW_STROKE: {
      if (strokeOutcomes[action.payload as StrokeOutcome].type === StrokeOutcomeType.SUCCESS) {
        const finalStrokeCompleted = (
          currentState.activityStrokesCompleted + 1 === currentState.currentCharacter?.strokes.length
        );
        return {
          ...currentState,
          activityStrokesCompleted: currentState.activityStrokesCompleted + 1,
          gameMode: finalStrokeCompleted ? GameMode.FEEDBACK : currentState.gameMode,
          lastStrokeOutcome: action.payload,
        };
      }
      return {
        ...currentState,
        errorCount: currentState.errorCount + 1,
        gameMode: GameMode.FEEDBACK,
        lastStrokeOutcome: action.payload,
      };
    }
    case ActionType.STROKE_FALSE_START:
      return {
        ...currentState,
        lastStrokeOutcome: action.payload,
      };
    case ActionType.COMPLETE_ACTIVITY_LEVEL: {
      const { currentActivity } = currentState;
      const difficultyLevel = getDifficultyLevel(currentActivity?.type, currentState.currentCharacter);

      const requiredIterations = (currentActivity?.type === ActivityType.INDEPENDENT
        && difficultyLevel === DifficultyLevel.EASY)
        ? 1
        : currentActivity?.requiredIterations || 1;
      // if another iteration required, increment iteration and reset stroke count
      const hasNextIteration = currentActivity
        ? currentActivity?.currentIteration < requiredIterations
        : null;
      if (currentActivity && hasNextIteration) {
        const currentIteration = currentActivity.currentIteration || 0;
        return {
          ...currentState,
          lastStrokeOutcome: null,
          activityStrokesCompleted: 0,
          currentActivity: {
            ...currentActivity,
            currentIteration: currentIteration + 1,
          },
          gameMode: GameMode.ACTIVE,
        };
      }

      // else if there is a next activity, advance activity
      if (currentActivity?.nextActivityType) {
        return {
          ...currentState,
          currentActivity: activities[currentActivity.nextActivityType],
          errorCount: 0,
          activityStrokesCompleted: 0,
          lastStrokeOutcome: null,
          gameMode: GameMode.ACTIVE,
        };
      }

      // else if there is a next character, advance character
      const updatedSequence = deepCopy(currentState.currentSequence) as InstructionalSequence;
      if (updatedSequence && updatedSequence.characters.length) {
        const nextCharacter = updatedSequence.characters.shift() as Character;
        const updatedSequenceItemOrder = currentState.currentSequenceItemOrder + 1;
        return {
          ...currentState,
          flowStep: GameFlowStep.INTRO_CHARACTER,
          currentSequence: updatedSequence,
          currentSequenceItemOrder: updatedSequenceItemOrder,
          currentCharacter: nextCharacter,
          currentActivity: activities.SOLID,
          errorCount: 0,
          activityStrokesCompleted: 0,
          lastStrokeOutcome: null,
          gameMode: GameMode.ACTIVE,
        };
      }

      // else mark sequence complete
      const nextUnlockedCharacters = (
        currentState?.currentSequence?.unlockedCharacters
        && Object.keys(currentState?.currentSequence?.unlockedCharacters).length > 0
      )
        ? currentState?.currentSequence?.unlockedCharacters
        : currentState.unlockedCharacters;
      return {
        ...currentState,
        flowStep: (currentState?.currentSequence?.category === SequenceCategory.PRACTICE
          || currentState.hasCompletedSequence)
          ? GameFlowStep.SELECT_PRACTICE_CHARACTER : GameFlowStep.SEQUENCE_COMPLETED,
        currentSequenceItemOrder: 0,
        currentCharacter: null,
        currentActivity: null,
        activityStrokesCompleted: 0,
        lastStrokeOutcome: null,
        errorCount: 0,
        unlockedCharacters: nextUnlockedCharacters,
        hasCompletedSequence: currentState?.currentSequence?.category !== SequenceCategory.PRACTICE,
        drawnCharacters: (currentState?.currentSequence?.category === SequenceCategory.PRACTICE
          || currentState.hasCompletedSequence)
          ? []
          : [...currentState.drawnCharacters],
      };
    }
    case ActionType.LEAVE_END_OF_SET_PAGE: {
      return {
        ...currentState,
        drawnCharacters: [],
        flowStep: GameFlowStep.SELECT_PRACTICE_CHARACTER,
        hasCompletedSequence: true,
      };
    }
    case ActionType.SKIP_TO_ACTIVITY: {
      return {
        ...currentState,
        currentActivity: action.payload,
        errorCount: 0,
        activityStrokesCompleted: 0,
        lastStrokeOutcome: null,
        gameMode: GameMode.ACTIVE,
      };
    }
    case ActionType.SKIP_CHARACTER: {
      // if the current activity is not chase or independent
      if (currentState.currentActivity?.type === ActivityType.SOLID
        || currentState.currentActivity?.type === ActivityType.FADED
        || currentState.currentActivity?.type === ActivityType.DOTTED) {
        // if user is in a practice set or practice mode, then send back to Character selection screen
        // also, if user is at the end of a custom set
        if ((currentState.currentSequence?.category === SequenceCategory.PRACTICE
            || currentState.hasCompletedSequence)
            || (currentState.currentSequence?.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS
            && currentState.currentSequence.characters.length === 0 && currentState.hasCompletedSequence)
            || (currentState.currentSequence?.id === InstructionalSequenceType.CUSTOM_1
            && currentState.currentSequence.characters.length === 0 && !currentState.hasCompletedSequence)) {
          const nextUnlockedCharacters = (
            currentState?.currentSequence?.unlockedCharacters
            && Object.keys(currentState?.currentSequence?.unlockedCharacters).length > 0
          )
            ? currentState?.currentSequence?.unlockedCharacters
            : currentState.unlockedCharacters;
          return {
            ...currentState,
            currentSequenceItemOrder: 0,
            currentCharacter: null,
            currentActivity: null,
            errorCount: 0,
            activityStrokesCompleted: 0,
            lastStrokeOutcome: null,
            gameMode: GameMode.ACTIVE,
            flowStep: GameFlowStep.SELECT_PRACTICE_CHARACTER,
            unlockedCharacters: nextUnlockedCharacters,
          };
        // In a kinesthetic set when you are on the last character and you trigger 5 consecutive errors
        // on the solid, faded, or dotted line activities, then bring the user to the first letter in
        // the next set.
        } if (currentState.currentSequence?.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS
          && currentState.currentSequence.characters.length === 0 && !currentState.hasCompletedSequence) {
          // get next sequence
          const nextSeq: InstructionalSequence = deepCopy(
            getNextCharacterSequenceInOrder(currentState.currentSequence),
          );
          if (nextSeq) {
            const nextFlowStep = (nextSeq.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS)
              ? GameFlowStep.INTRO_CHARACTER
              : GameFlowStep.SELECT_PRACTICE_CHARACTER;
            const nextCharacter = (nextSeq.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS)
              ? nextSeq.characters.shift() as Character
              : null;
            const nextActivity = (nextSeq.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS)
              ? activities.SOLID
              : null;
            return {
              ...currentState,
              activityStrokesCompleted: 0,
              currentActivity: nextActivity,
              currentCharacter: nextCharacter,
              currentSequence: nextSeq,
              currentSequenceItemOrder: 0,
              drawnCharacters: [],
              errorCount: 0,
              flowStep: nextFlowStep,
              gameMode: GameMode.ACTIVE,
              hasCompletedSequence: false,
              lastStrokeOutcome: null,
              shouldRequireAccessToSequenceScreen: (nextSeq.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS),
              unlockedCharacters: (nextSeq.category === SequenceCategory.INSTRUCTIONAL_CHARACTERS)
                ? {}
                : nextSeq.unlockedCharacters,
            };
          }
        }
      }
      // if there is a next character, advance character and move current character to end of sequence
      if (currentState.currentSequence?.characters?.length) {
        const currentCharacter = currentState.currentCharacter as Character;
        const updatedSequence = deepCopy(currentState.currentSequence) as InstructionalSequence;
        updatedSequence.characters.push(currentCharacter);
        const nextCharacter = updatedSequence.characters.shift() as Character;
        const updatedSequenceItemOrder = currentState.currentSequenceItemOrder + 1;
        return {
          ...currentState,
          flowStep: GameFlowStep.WATCH_CHARACTER_DEMO_VIDEO,
          currentSequence: updatedSequence,
          currentSequenceItemOrder: updatedSequenceItemOrder,
          currentCharacter: nextCharacter,
          currentActivity: activities.SOLID,
          errorCount: 0,
          activityStrokesCompleted: 0,
          lastStrokeOutcome: null,
          gameMode: GameMode.ACTIVE,
          canSkipDemoVideo: false,
        };
      }
      return currentState;
    }
    case ActionType.SKIP_CUSTOM_SEQUENCE: {
      return {
        ...currentState,
        currentSequenceItemOrder: 0,
        currentCharacter: null,
        currentActivity: null,
        errorCount: 0,
        activityStrokesCompleted: 0,
        lastStrokeOutcome: null,
        gameMode: GameMode.ACTIVE,
        flowStep: GameFlowStep.SELECT_PRACTICE_CHARACTER,
      };
    }
    case ActionType.RESET_ACTIVITY: {
      return {
        ...currentState,
        gameMode: action.payload,
        activityStrokesCompleted: 0,
        lastStrokeOutcome: null,
      };
    }
    case ActionType.GO_TO_KINESTHETIC_GROUP_PAGE: {
      return {
        ...currentState,
        flowStep: GameFlowStep.VIEW_KINESTHETIC_GROUP,
      };
    }
    case ActionType.GO_TO_CHARACTER_DEMO_VIDEO: {
      return {
        ...currentState,
        flowStep: GameFlowStep.WATCH_CHARACTER_DEMO_VIDEO,
        canSkipDemoVideo: action.payload,
      };
    }
    case ActionType.GO_TO_GRIP_DEMO_VIDEO: {
      return {
        ...currentState,
        flowStep: GameFlowStep.WATCH_GRIP_DEMO_VIDEO,
      };
    }
    case ActionType.GO_TO_SELF_ASSESS_PAGE: {
      return {
        ...currentState,
        flowStep: GameFlowStep.SELF_ASSESSMENT,
      };
    }
    case ActionType.LEAVE_SELF_ASSESS_PAGE: {
      return {
        ...currentState,
        flowStep: GameFlowStep.WRITING,
      };
    }
    case ActionType.ADD_DRAWN_CHARACTERS: {
      return {
        ...currentState,
        drawnCharacters: [...currentState.drawnCharacters, action.payload],
      };
    }
    case ActionType.UPDATE_DRAWN_CHARACTERS: {
      const selectedDrawnChar: DrawnCharacter = action.payload;
      const selectedIndex = currentState.drawnCharacters
        .findIndex((drawnChar) => drawnChar.id === selectedDrawnChar.id);
      const newDrawnCharacters = [...currentState.drawnCharacters];
      newDrawnCharacters[selectedIndex] = { ...selectedDrawnChar };
      return { ...currentState, drawnCharacters: newDrawnCharacters };
    }
    case ActionType.DELETE_DRAWN_CHARACTERS: {
      const charToDelete: CharacterType = action.payload;
      const newDrawnCharacters = currentState.drawnCharacters
        .filter((drawnChar) => drawnChar.sequenceItemOrder !== currentState.currentSequenceItemOrder
          && drawnChar.characterId !== charToDelete);
      return { ...currentState, drawnCharacters: newDrawnCharacters };
    }
    case ActionType.RESUME_GAME_PLAY: {
      const { currentActivity } = currentState;

      if (currentActivity) {
        return {
          ...currentState,
          currentActivity: {
            ...currentActivity,
            currentIteration: action.payload.currentIteration,
          },
        };
      }
      return currentState;
    }
    default:
      return currentState;
  }
};

export default function GameContextProvider({ children }: GameContextProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const providerValue = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return (
    <GameContext.Provider value={providerValue}>
      {children}
    </GameContext.Provider>
  );
}

export function useGameContext() {
  const context = useContext(GameContext);

  if (context === undefined) {
    throw new Error('useGameContext must be used within GameContextProvider');
  }

  return context;
}
