import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import SelectCustomSet from '../components/SelectCustomSet';
import ConfirmCustomSet from '../components/ConfirmCustomSet';
import LaunchCustomSet from '../components/LaunchCustomSet';
import { AnalyticsEventCategory } from '../data/types/analytics';
import {
  CharacterCategory,
  CharacterType, InstructionalSequence, InstructionalSequenceType, SequenceCategory, UnlockedCharacters,
} from '../data/types';
import { characters } from '../data/characters';
import { ActionType, useGameContext } from '../context/GameContext';
import Modal from '../components/Modal';
import TeacherConfirmButton from '../components/Buttons/TeacherConfirmButton';
import { TextButton } from '../styles/components/Buttons';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { instructionalSequences } from '../data/sequences';

export enum CustomSetViews {
  SELECT = 'SELECT',
  CONFIRM = 'CONFIRM',
  LAUNCH = 'LAUNCH',
}

export enum Modals {
  CONFIRM_ADULT = 'CONFIRM_ADULT',
  CONFIRM_LEAVING = 'CONFIRM_LEAVING',
}

export type SelectedChar = {
  type: CharacterType,
  category: CharacterCategory,
  id: string,
};

export default function CreateCustomCharacterSet() {
  const { trackEvent } = useMatomo();
  const ctx = useGameContext();
  const isOnline = useOnlineStatus();
  const [view, setView] = useState<CustomSetViews>(CustomSetViews.SELECT);
  const [selectedChars, setSelectedChars] = useState<SelectedChar[]>([]);
  const [showModal, setShowModal] = useState<Modals | null>(Modals.CONFIRM_ADULT);

  const setDisplayLabel = selectedChars.map((char: SelectedChar) => characters[char.type].display).join(' ');

  const showConfirmLeavingModal = () => setShowModal(Modals.CONFIRM_LEAVING);
  const hideModal = () => setShowModal(null);
  const goToHomeScreen = () => ctx?.dispatch({ type: ActionType.RESET });
  const goToSelectScreen = () => setView(CustomSetViews.SELECT);
  const goToConfirmScreen = () => setView(CustomSetViews.CONFIRM);
  const goToLaunchScreen = () => setView(CustomSetViews.LAUNCH);

  const selectChar = (key: CharacterType, category: CharacterCategory) => {
    if (selectedChars.length < 5) {
      setSelectedChars((currentChars) => [...currentChars, { type: key, category, id: uuidv4() }]);
    }
  };

  const removeChar = (index: number) => {
    setSelectedChars((currentChars) => [...currentChars].filter((item, i) => i !== index));
  };

  const makeUnlockedChars = () => {
    const unlocked: UnlockedCharacters = {};
    selectedChars.forEach((char: SelectedChar) => {
      unlocked[char.type] = true;
    });
    return unlocked;
  };

  const launchCustomSequence = () => {
    const customSequence: InstructionalSequence = {
      id: InstructionalSequenceType.CUSTOM_1,
      display: setDisplayLabel,
      order: Object.keys(instructionalSequences).length + 1,
      category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
      characters: selectedChars.map((char: SelectedChar) => characters[char.type]),
      unlockedCharacters: makeUnlockedChars(),
    };
    ctx?.dispatch({ type: ActionType.SELECT_SEQUENCE, payload: customSequence });
    trackEvent({
      category: AnalyticsEventCategory.SET,
      action: 'Started',
      name: `Custom: ${customSequence.display}`,
    });
  };

  return (
    <>
      {!isOnline && <NoInternetToast />}
      {view === CustomSetViews.SELECT ? (
        <SelectCustomSet
          selectedChars={selectedChars}
          selectChar={selectChar}
          removeChar={removeChar}
          goToConfirmScreen={goToConfirmScreen}
          showConfirmLeavingModal={showConfirmLeavingModal}
        />
      ) : null}
      {view === CustomSetViews.CONFIRM ? (
        <ConfirmCustomSet
          selectedChars={selectedChars}
          setSelectedChars={setSelectedChars}
          goToSelectScreen={goToSelectScreen}
          goToLaunchScreen={goToLaunchScreen}
          showConfirmLeavingModal={showConfirmLeavingModal}
        />
      ) : null}
      {view === CustomSetViews.LAUNCH ? (
        <LaunchCustomSet
          setDisplayLabel={setDisplayLabel}
          launchCustomSequence={launchCustomSequence}
        />
      ) : null}
      <Modal
        title="Are you sure you want to go back?"
        isShown={showModal === Modals.CONFIRM_LEAVING}
        onClose={hideModal}
      >
        <h1>wait!</h1>
        <p>
          Are you sure you want to go back?
          <br />
          Your work will not be saved.
        </p>

        <TeacherConfirmButton
          buttonTouch={goToHomeScreen}
          buttonText="BACK TO SETS"
        />
        <TextButton style={{ marginTop: '3em' }} onClick={hideModal}>
          Cancel
        </TextButton>
      </Modal>
    </>
  );
}
