import { ActivityType, CharacterType } from '../../../data/types';
import solid from './VO5.m4a';
import faded from './VO14.m4a';
import dotted from './VO16.m4a';

// Chase the star
import chaseLowerA from './VO48-LOWER_A.m4a';
import chaseLowerB from './VO48-LOWER_B.m4a';
import chaseLowerC from './VO48-LOWER_C.m4a';
import chaseLowerD from './VO48-LOWER_D.m4a';
import chaseLowerE from './VO48-LOWER_E.m4a';
import chaseLowerF from './VO48-LOWER_F.m4a';
import chaseLowerG from './VO48-LOWER_G.m4a';
import chaseLowerH from './VO48-LOWER_H.m4a';
import chaseLowerI from './VO48-LOWER_I.m4a';
import chaseLowerJ from './VO48-LOWER_J.m4a';
import chaseLowerK from './VO48-LOWER_K.m4a';
import chaseLowerL from './VO48-LOWER_L.m4a';
import chaseLowerM from './VO48-LOWER_M.m4a';
import chaseLowerN from './VO48-LOWER_N.m4a';
import chaseLowerO from './VO48-LOWER_O.m4a';
import chaseLowerP from './VO48-LOWER_P.m4a';
import chaseLowerQ from './VO48-LOWER_Q.m4a';
import chaseLowerR from './VO48-LOWER_R.m4a';
import chaseLowerS from './VO48-LOWER_S.m4a';
import chaseLowerT from './VO48-LOWER_T.m4a';
import chaseLowerU from './VO48-LOWER_U.m4a';
import chaseLowerV from './VO48-LOWER_V.m4a';
import chaseLowerW from './VO48-LOWER_W.m4a';
import chaseLowerX from './VO48-LOWER_X.m4a';
import chaseLowerY from './VO48-LOWER_Y.m4a';
import chaseLowerZ from './VO48-LOWER_Z.m4a';

import chaseNumber0 from './VO48-NUMBER_0.m4a';
import chaseNumber1 from './VO48-NUMBER_1.m4a';
import chaseNumber2 from './VO48-NUMBER_2.m4a';
import chaseNumber3 from './VO48-NUMBER_3.m4a';
import chaseNumber4 from './VO48-NUMBER_4.m4a';
import chaseNumber5 from './VO48-NUMBER_5.m4a';
import chaseNumber6 from './VO48-NUMBER_6.m4a';
import chaseNumber7 from './VO48-NUMBER_7.m4a';
import chaseNumber8 from './VO48-NUMBER_8.m4a';
import chaseNumber9 from './VO48-NUMBER_9.m4a';

import chaseUpperA from './VO48-UPPER_A.m4a';
import chaseUpperB from './VO48-UPPER_B.m4a';
import chaseUpperC from './VO48-UPPER_C.m4a';
import chaseUpperD from './VO48-UPPER_D.m4a';
import chaseUpperE from './VO48-UPPER_E.m4a';
import chaseUpperF from './VO48-UPPER_F.m4a';
import chaseUpperG from './VO48-UPPER_G.m4a';
import chaseUpperH from './VO48-UPPER_H.m4a';
import chaseUpperI from './VO48-UPPER_I.m4a';
import chaseUpperJ from './VO48-UPPER_J.m4a';
import chaseUpperK from './VO48-UPPER_K.m4a';
import chaseUpperL from './VO48-UPPER_L.m4a';
import chaseUpperM from './VO48-UPPER_M.m4a';
import chaseUpperN from './VO48-UPPER_N.m4a';
import chaseUpperO from './VO48-UPPER_O.m4a';
import chaseUpperP from './VO48-UPPER_P.m4a';
import chaseUpperQ from './VO48-UPPER_Q.m4a';
import chaseUpperR from './VO48-UPPER_R.m4a';
import chaseUpperS from './VO48-UPPER_S.m4a';
import chaseUpperT from './VO48-UPPER_T.m4a';
import chaseUpperU from './VO48-UPPER_U.m4a';
import chaseUpperV from './VO48-UPPER_V.m4a';
import chaseUpperW from './VO48-UPPER_W.m4a';
import chaseUpperX from './VO48-UPPER_X.m4a';
import chaseUpperY from './VO48-UPPER_Y.m4a';
import chaseUpperZ from './VO48-UPPER_Z.m4a';

import chaseSymbolPlus from './VO48-SYMBOL_PLUS.m4a';
import chaseSymbolEqual from './VO48-SYMBOL_EQUAL.m4a';
import chaseSymbolQuestion from './VO48-SYMBOL_QUESTION.m4a';
import chaseSymbolExclamation from './VO48-SYMBOL_EXCLAMATION.m4a';

// Independent
import lowerA from './VO24-27-LOWER_A.m4a';
import lowerB from './VO24-27-LOWER_B.m4a';
import lowerC from './VO24-27-LOWER_C.m4a';
import lowerD from './VO24-27-LOWER_D.m4a';
import lowerE from './VO24-27-LOWER_E.m4a';
import lowerF from './VO24-27-LOWER_F.m4a';
import lowerG from './VO24-27-LOWER_G.m4a';
import lowerH from './VO24-27-LOWER_H.m4a';
import lowerI from './VO24-27-LOWER_I.m4a';
import lowerJ from './VO24-27-LOWER_J.m4a';
import lowerK from './VO24-27-LOWER_K.m4a';
import lowerL from './VO24-27-LOWER_L.m4a';
import lowerM from './VO24-27-LOWER_M.m4a';
import lowerN from './VO24-27-LOWER_N.m4a';
import lowerO from './VO24-27-LOWER_O.m4a';
import lowerP from './VO24-27-LOWER_P.m4a';
import lowerQ from './VO24-27-LOWER_Q.m4a';
import lowerR from './VO24-27-LOWER_R.m4a';
import lowerS from './VO24-27-LOWER_S.m4a';
import lowerT from './VO24-27-LOWER_T.m4a';
import lowerU from './VO24-27-LOWER_U.m4a';
import lowerV from './VO24-27-LOWER_V.m4a';
import lowerW from './VO24-27-LOWER_W.m4a';
import lowerX from './VO24-27-LOWER_X.m4a';
import lowerY from './VO24-27-LOWER_Y.m4a';
import lowerZ from './VO24-27-LOWER_Z.m4a';

import number0 from './VO24-27-NUMBER_0.m4a';
import number1 from './VO24-27-NUMBER_1.m4a';
import number2 from './VO24-27-NUMBER_2.m4a';
import number3 from './VO24-27-NUMBER_3.m4a';
import number4 from './VO24-27-NUMBER_4.m4a';
import number5 from './VO24-27-NUMBER_5.m4a';
import number6 from './VO24-27-NUMBER_6.m4a';
import number7 from './VO24-27-NUMBER_7.m4a';
import number8 from './VO24-27-NUMBER_8.m4a';
import number9 from './VO24-27-NUMBER_9.m4a';

import upperA from './VO24-27-UPPER_A.m4a';
import upperB from './VO24-27-UPPER_B.m4a';
import upperC from './VO24-27-UPPER_C.m4a';
import upperD from './VO24-27-UPPER_D.m4a';
import upperE from './VO24-27-UPPER_E.m4a';
import upperF from './VO24-27-UPPER_F.m4a';
import upperG from './VO24-27-UPPER_G.m4a';
import upperH from './VO24-27-UPPER_H.m4a';
import upperI from './VO24-27-UPPER_I.m4a';
import upperJ from './VO24-27-UPPER_J.m4a';
import upperK from './VO24-27-UPPER_K.m4a';
import upperL from './VO24-27-UPPER_L.m4a';
import upperM from './VO24-27-UPPER_M.m4a';
import upperN from './VO24-27-UPPER_N.m4a';
import upperO from './VO24-27-UPPER_O.m4a';
import upperP from './VO24-27-UPPER_P.m4a';
import upperQ from './VO24-27-UPPER_Q.m4a';
import upperR from './VO24-27-UPPER_R.m4a';
import upperS from './VO24-27-UPPER_S.m4a';
import upperT from './VO24-27-UPPER_T.m4a';
import upperU from './VO24-27-UPPER_U.m4a';
import upperV from './VO24-27-UPPER_V.m4a';
import upperW from './VO24-27-UPPER_W.m4a';
import upperX from './VO24-27-UPPER_X.m4a';
import upperY from './VO24-27-UPPER_Y.m4a';
import upperZ from './VO24-27-UPPER_Z.m4a';

import symbolPlus from './VO24-27-SYMBOL_PLUS.m4a';
import symbolEqual from './VO24-27-SYMBOL_EQUAL.m4a';
import symbolQuestion from './VO24-27-SYMBOL_QUESTION.m4a';
import symbolExclamation from './VO24-27-SYMBOL_EXCLAMATION.m4a';

type ActivityIntros = {
  [key in Exclude<ActivityType, ActivityType.STAR_GAME>]: any;
}

const activityIntros: ActivityIntros = {
  [ActivityType.SOLID]: solid,
  [ActivityType.FADED]: faded,
  [ActivityType.DOTTED]: dotted,
  [ActivityType.CHASE_STAR]: {
    [CharacterType.LOWER_A]: chaseLowerA,
    [CharacterType.LOWER_B]: chaseLowerB,
    [CharacterType.LOWER_C]: chaseLowerC,
    [CharacterType.LOWER_D]: chaseLowerD,
    [CharacterType.LOWER_E]: chaseLowerE,
    [CharacterType.LOWER_F]: chaseLowerF,
    [CharacterType.LOWER_G]: chaseLowerG,
    [CharacterType.LOWER_H]: chaseLowerH,
    [CharacterType.LOWER_I]: chaseLowerI,
    [CharacterType.LOWER_J]: chaseLowerJ,
    [CharacterType.LOWER_K]: chaseLowerK,
    [CharacterType.LOWER_L]: chaseLowerL,
    [CharacterType.LOWER_M]: chaseLowerM,
    [CharacterType.LOWER_N]: chaseLowerN,
    [CharacterType.LOWER_O]: chaseLowerO,
    [CharacterType.LOWER_P]: chaseLowerP,
    [CharacterType.LOWER_Q]: chaseLowerQ,
    [CharacterType.LOWER_R]: chaseLowerR,
    [CharacterType.LOWER_S]: chaseLowerS,
    [CharacterType.LOWER_T]: chaseLowerT,
    [CharacterType.LOWER_U]: chaseLowerU,
    [CharacterType.LOWER_V]: chaseLowerV,
    [CharacterType.LOWER_W]: chaseLowerW,
    [CharacterType.LOWER_X]: chaseLowerX,
    [CharacterType.LOWER_Y]: chaseLowerY,
    [CharacterType.LOWER_Z]: chaseLowerZ,

    [CharacterType.NUMBER_0]: chaseNumber0,
    [CharacterType.NUMBER_1]: chaseNumber1,
    [CharacterType.NUMBER_2]: chaseNumber2,
    [CharacterType.NUMBER_3]: chaseNumber3,
    [CharacterType.NUMBER_4]: chaseNumber4,
    [CharacterType.NUMBER_5]: chaseNumber5,
    [CharacterType.NUMBER_6]: chaseNumber6,
    [CharacterType.NUMBER_7]: chaseNumber7,
    [CharacterType.NUMBER_8]: chaseNumber8,
    [CharacterType.NUMBER_9]: chaseNumber9,

    [CharacterType.UPPER_A]: chaseUpperA,
    [CharacterType.UPPER_B]: chaseUpperB,
    [CharacterType.UPPER_C]: chaseUpperC,
    [CharacterType.UPPER_D]: chaseUpperD,
    [CharacterType.UPPER_E]: chaseUpperE,
    [CharacterType.UPPER_F]: chaseUpperF,
    [CharacterType.UPPER_G]: chaseUpperG,
    [CharacterType.UPPER_H]: chaseUpperH,
    [CharacterType.UPPER_I]: chaseUpperI,
    [CharacterType.UPPER_J]: chaseUpperJ,
    [CharacterType.UPPER_K]: chaseUpperK,
    [CharacterType.UPPER_L]: chaseUpperL,
    [CharacterType.UPPER_M]: chaseUpperM,
    [CharacterType.UPPER_N]: chaseUpperN,
    [CharacterType.UPPER_O]: chaseUpperO,
    [CharacterType.UPPER_P]: chaseUpperP,
    [CharacterType.UPPER_Q]: chaseUpperQ,
    [CharacterType.UPPER_R]: chaseUpperR,
    [CharacterType.UPPER_S]: chaseUpperS,
    [CharacterType.UPPER_T]: chaseUpperT,
    [CharacterType.UPPER_U]: chaseUpperU,
    [CharacterType.UPPER_V]: chaseUpperV,
    [CharacterType.UPPER_W]: chaseUpperW,
    [CharacterType.UPPER_X]: chaseUpperX,
    [CharacterType.UPPER_Y]: chaseUpperY,
    [CharacterType.UPPER_Z]: chaseUpperZ,

    [CharacterType.SYMBOL_EQUAL]: chaseSymbolEqual,
    [CharacterType.SYMBOL_PLUS]: chaseSymbolPlus,
    [CharacterType.SYMBOL_QUESTION]: chaseSymbolQuestion,
    [CharacterType.SYMBOL_EXCLAMATION]: chaseSymbolExclamation,
  },
  [ActivityType.INDEPENDENT]: {
    [CharacterType.LOWER_A]: lowerA,
    [CharacterType.LOWER_B]: lowerB,
    [CharacterType.LOWER_C]: lowerC,
    [CharacterType.LOWER_D]: lowerD,
    [CharacterType.LOWER_E]: lowerE,
    [CharacterType.LOWER_F]: lowerF,
    [CharacterType.LOWER_G]: lowerG,
    [CharacterType.LOWER_H]: lowerH,
    [CharacterType.LOWER_I]: lowerI,
    [CharacterType.LOWER_J]: lowerJ,
    [CharacterType.LOWER_K]: lowerK,
    [CharacterType.LOWER_L]: lowerL,
    [CharacterType.LOWER_M]: lowerM,
    [CharacterType.LOWER_N]: lowerN,
    [CharacterType.LOWER_O]: lowerO,
    [CharacterType.LOWER_P]: lowerP,
    [CharacterType.LOWER_Q]: lowerQ,
    [CharacterType.LOWER_R]: lowerR,
    [CharacterType.LOWER_S]: lowerS,
    [CharacterType.LOWER_T]: lowerT,
    [CharacterType.LOWER_U]: lowerU,
    [CharacterType.LOWER_V]: lowerV,
    [CharacterType.LOWER_W]: lowerW,
    [CharacterType.LOWER_X]: lowerX,
    [CharacterType.LOWER_Y]: lowerY,
    [CharacterType.LOWER_Z]: lowerZ,

    [CharacterType.NUMBER_0]: number0,
    [CharacterType.NUMBER_1]: number1,
    [CharacterType.NUMBER_2]: number2,
    [CharacterType.NUMBER_3]: number3,
    [CharacterType.NUMBER_4]: number4,
    [CharacterType.NUMBER_5]: number5,
    [CharacterType.NUMBER_6]: number6,
    [CharacterType.NUMBER_7]: number7,
    [CharacterType.NUMBER_8]: number8,
    [CharacterType.NUMBER_9]: number9,

    [CharacterType.UPPER_A]: upperA,
    [CharacterType.UPPER_B]: upperB,
    [CharacterType.UPPER_C]: upperC,
    [CharacterType.UPPER_D]: upperD,
    [CharacterType.UPPER_E]: upperE,
    [CharacterType.UPPER_F]: upperF,
    [CharacterType.UPPER_G]: upperG,
    [CharacterType.UPPER_H]: upperH,
    [CharacterType.UPPER_I]: upperI,
    [CharacterType.UPPER_J]: upperJ,
    [CharacterType.UPPER_K]: upperK,
    [CharacterType.UPPER_L]: upperL,
    [CharacterType.UPPER_M]: upperM,
    [CharacterType.UPPER_N]: upperN,
    [CharacterType.UPPER_O]: upperO,
    [CharacterType.UPPER_P]: upperP,
    [CharacterType.UPPER_Q]: upperQ,
    [CharacterType.UPPER_R]: upperR,
    [CharacterType.UPPER_S]: upperS,
    [CharacterType.UPPER_T]: upperT,
    [CharacterType.UPPER_U]: upperU,
    [CharacterType.UPPER_V]: upperV,
    [CharacterType.UPPER_W]: upperW,
    [CharacterType.UPPER_X]: upperX,
    [CharacterType.UPPER_Y]: upperY,
    [CharacterType.UPPER_Z]: upperZ,

    [CharacterType.SYMBOL_EQUAL]: symbolEqual,
    [CharacterType.SYMBOL_PLUS]: symbolPlus,
    [CharacterType.SYMBOL_QUESTION]: symbolQuestion,
    [CharacterType.SYMBOL_EXCLAMATION]: symbolExclamation,
  },
};

export default activityIntros;
