import React, { useEffect, useState } from 'react';
import NextButton from '../components/Buttons/NextButton';
import DrawnCharCardButton from '../components/Buttons/DrawnCharCardButton';
import TopNavigation from '../components/TopNavigation';
import { ActionType, useGameContext } from '../context/GameContext';
import { CharacterCategory, DrawnCharacter, GameScreens } from '../data/types';
import { SelfAssessmentAreaWrapper } from '../styles/components/ContentWrappers';
import chooseLetter from '../assets/audio/selfAssessment/VO43-letters.m4a';
import chooseNumber from '../assets/audio/selfAssessment/VO43-numbers.m4a';
import chooseSymbol from '../assets/audio/selfAssessment/VO43-symbols.m4a';
import { useAudioContext } from '../context/AudioContext';
import useDrawnCharacters from '../hooks/useDrawnCharacters';
import { useActivityProgressContext, ActionType as ProgressActionType } from '../context/ActivityProgressContext';
import useDifficultyLevel from '../hooks/useDifficultyLevel';

export default function CharacterSelfAssessment() {
  const ctx = useGameContext();
  const audioContext = useAudioContext();
  const activityProgress = useActivityProgressContext();
  const { difficultyLevel } = useDifficultyLevel();
  const { allDrawnCharsForCurrentChar, currentSelectionForCurrentChar } = useDrawnCharacters();
  const [selected, setSelected] = useState<DrawnCharacter|undefined>(currentSelectionForCurrentChar());

  const getAudioFile = () => {
    if (!ctx || !ctx.currentCharacter || selected) return null;
    switch (ctx.currentCharacter.category) {
      case CharacterCategory.NUMBER:
        return chooseNumber;
      case CharacterCategory.SYMBOL:
        return chooseSymbol;
      default:
        return chooseLetter;
    }
  };

  useEffect(() => {
    audioContext?.handlePlay({ src: getAudioFile() });
    return () => audioContext?.handleComplete();
  }, []);

  const handleNextTouch = (): void => {
    ctx?.dispatch({ type: ActionType.LEAVE_SELF_ASSESS_PAGE });
  };

  const handleSelection = (drawnChar: DrawnCharacter): void => {
    const selection = { ...drawnChar, isSelected: true };
    setSelected(selection);
    ctx?.dispatch({ type: ActionType.UPDATE_DRAWN_CHARACTERS, payload: selection });
  };

  const saveActivityProgress = () => {
    activityProgress?.dispatch({
      type: ProgressActionType.SAVE_ACTIVITY_PROGRESS,
      payload: { redirectedFrom: GameScreens.SELF_ASSESSMENT },
    });
  };

  const viewDemoScreen = (): void => {
    saveActivityProgress();
    ctx?.dispatch({ type: ActionType.GO_TO_CHARACTER_DEMO_VIDEO, payload: true });
  };

  const viewGripDemoScreen = (): void => {
    saveActivityProgress();
    ctx?.dispatch({ type: ActionType.GO_TO_GRIP_DEMO_VIDEO });
  };

  if (!ctx) return null;
  return (
    <div style={{ width: '80%' }}>
      <SelfAssessmentAreaWrapper>
        <h1 className="visually-hidden">
          Choose the
          {' '}
          {ctx.currentCharacter?.display}
          {' '}
          that is easiest to read.
        </h1>
        <TopNavigation
          onDemoButtonTouch={() => viewDemoScreen()}
          itemLabel={ctx?.currentCharacter?.display || ''}
          showHomeButton={!!Object.keys(ctx.unlockedCharacters).length}
          onHomeButtonTouch={() => ctx?.dispatch({ type: ActionType.SKIP_CUSTOM_SEQUENCE })}
          showGripDemoButton
          onGripDemoButtonTouch={() => viewGripDemoScreen()}
        />
        <div className="card-container">
          {allDrawnCharsForCurrentChar().map((drawnCharacter) => (
            <DrawnCharCardButton
              key={`${drawnCharacter.characterId}-${drawnCharacter.order}`}
              onTouch={() => handleSelection(drawnCharacter)}
              isDisabled={!!selected}
              drawnCharacter={drawnCharacter}
              difficultyLevel={difficultyLevel}
            />
          ))}
          <div className="spacer" />
        </div>
        <div className="button-container">
          {selected && (
            <NextButton
              isDisabled={false}
              buttonTouch={() => handleNextTouch()}
            />
          )}
        </div>
      </SelfAssessmentAreaWrapper>
    </div>
  );
}
