import { useMemo } from 'react';
import { useGameContext } from '../context/GameContext';
import envSettings from '../data/envSettings';
import {
  ActivityType, DifficultyLevel,
} from '../data/types';
import { SCALE_LARGE } from '../shared/constants';

/* This hook handles the logic for providing the difficulty level
at which an activity should be played. */
export default function useDifficultyLevel() {
  const ctx = useGameContext();

  const difficultyLevel = useMemo(() => {
    if (ctx?.currentActivity?.type === ActivityType.CHASE_STAR) return DifficultyLevel.NORMAL;
    const isLevelAvailable = ctx?.currentCharacter?.positioning[envSettings.difficultyLevel];
    // always default to normal difficulty
    if (!isLevelAvailable) return DifficultyLevel.NORMAL;
    return envSettings.difficultyLevel;
  }, [ctx?.currentCharacter, ctx?.currentActivity]);

  const getRadiusForDifficultyLevel = (radius: number) => {
    if (difficultyLevel !== DifficultyLevel.EASY) return radius;
    return radius * SCALE_LARGE;
  };

  return {
    difficultyLevel,
    getRadiusForDifficultyLevel,
  };
}
