import React, { useMemo, useState } from 'react';
import NextButton from '../components/Buttons/NextButton';
import { ActionType, useGameContext } from '../context/GameContext';
import { ActivityType, GameScreens } from '../data/types';
import { useActivityProgressContext, ActionType as ProgressActionType } from '../context/ActivityProgressContext';
import DemoVideoWrapper from '../components/DemoVideoWrapper';
import VideoPlayer from '../components/VideoPlayer';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { DEFAULT_VIDEO_PATH } from '../shared/constants';
import envSettings from '../data/envSettings';

export default function CharacterDemo() {
  const ctx = useGameContext();
  const activityProgress = useActivityProgressContext();
  const isOnline = useOnlineStatus();
  const [videoHasFinished, setVideoHasFinished] = useState(false);
  const videoPlayRequired = useMemo(() => {
    if (!ctx) return false;
    // students can skip the demo if they come to this page on their own
    if (envSettings.devMode || ctx.canSkipDemoVideo) return false;
    // students must watch the character demo video again when they are redirected
    // to it after a max number of errors are detected while they make their character
    const currentActivityType = ctx.currentActivity?.type;
    if ((currentActivityType === ActivityType.SOLID
      || currentActivityType === ActivityType.FADED
      || currentActivityType === ActivityType.DOTTED
      || currentActivityType === ActivityType.CHASE_STAR
      || currentActivityType === ActivityType.INDEPENDENT
    ) && ctx.errorCount > 0) {
      return true;
    }

    // the first time students encounter the demo video for the current sequence item
    if (activityProgress?.redirectedFrom === null) {
      // if they're completing an instructional sequence, they must watch the video
      // if they're completing a practice set, they must watch the video
      // the sequence category will be PRACTICE but `hasCompletedSequence` will be `false`
      // if they completed the instructional sequence, thus unlocking practice mode, they can skip the video
      // in this case, the sequence category will be INSTRUCTIONAL_CHARACTERS and
      // `hasCompletedSequence` will have been set to `true`
      return !ctx.hasCompletedSequence;
    }
    // in all other cases, students can skip the video
    return false;
  }, [ctx]);

  const nextButtonIsEnabled = useMemo(
    () => (!isOnline || videoHasFinished || !videoPlayRequired || envSettings.devMode),
    [isOnline, videoHasFinished, videoPlayRequired],
  );

  if (!ctx || !ctx.currentCharacter) return null;

  const handleContinue = (): void => {
    if (!activityProgress) return;
    switch (activityProgress.redirectedFrom) {
      case GameScreens.SELF_ASSESSMENT:
        ctx.dispatch({ type: ActionType.GO_TO_SELF_ASSESS_PAGE });
        break;
      case GameScreens.DRAWING_ACTIVITIES:
      default:
        ctx.dispatch({ type: ActionType.START_WRITING });
        break;
    }
    activityProgress.dispatch({
      type: ProgressActionType.UPDATE_ACTIVITY_PROGRESS,
      payload: {
        redirectedFrom: GameScreens.DEMO_VIDEO,
      },
    });
  };

  return (
    <DemoVideoWrapper
      headerText={`Demo Video for character ${ctx.currentCharacter.display}`}
      nextButton={(
        <NextButton
          buttonTouch={handleContinue}
          isDisabled={!nextButtonIsEnabled}
        />
      )}
    >
      <VideoPlayer
        path={`${DEFAULT_VIDEO_PATH}/${ctx.currentCharacter.type}.mp4`}
        ariaLabelPostfix={`character ${ctx.currentCharacter.display}`}
        hasPlayedBefore={!videoPlayRequired}
        onFinish={() => setVideoHasFinished(true)}
      />
    </DemoVideoWrapper>
  );
}
