import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DrawnCharacter as DrawnCharacterType } from '../data/types';
import { DrawnSpacingCharacter } from '../stateMachines/spacingMachine';
import SvgGradientDefs from './SvgGradientDefs';
import { DRAWN_STROKE_WIDTH, SCALE_LARGE } from '../shared/constants';
import { pointsToDotPath, pointsToPath } from '../shared/pointConversions';

type DrawnCharacterProps = {
  drawnCharacter: DrawnCharacterType|DrawnSpacingCharacter,
  isEasyLevel: boolean,
  positioning: {
    pathTransform: string,
  }
}

export default function DrawnCharacter({
  drawnCharacter, positioning, isEasyLevel,
}: DrawnCharacterProps) {
  const isStrokeGradient = drawnCharacter.drawingStrokes[0].pen.colors.length > 1;
  const gradientId = uuidv4();
  const strokeWidth = isEasyLevel
    ? DRAWN_STROKE_WIDTH * SCALE_LARGE
    : DRAWN_STROKE_WIDTH;

  return (
    <div className="drawn-character">
      {/* SVG height and transform help to properly place all characters,
          chars that touch top and bottom lines, chars that hit only middle and bottom line,
          and chars that go below the bottom line. */}
      <svg height={isEasyLevel ? '100%' : '175px'}>
        <SvgGradientDefs gradientId={gradientId} pen={drawnCharacter.drawingStrokes[0].pen} />
        {drawnCharacter.drawingStrokes.map((drawingStroke) => {
          /* Dot strokes have two points, but Konva will not draw with just two points,
              so we immediately add two extra points to any dot stroke.
              This is explained in a comment in useKonvaGrading.
              At this point in the process, we can assume any stroke with <= 4 points is a dot. */
          const isDotStroke = drawingStroke.points.length <= 4;
          const penColor = drawingStroke.pen.colors[0];
          return (
            <path
              key={drawingStroke.touchId}
              d={isDotStroke
                ? pointsToDotPath([drawingStroke.points[0], drawingStroke.points[1]])
                : pointsToPath(drawingStroke.points)}
              stroke={isStrokeGradient ? `url(#${gradientId})` : penColor}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              fill={isDotStroke ? penColor : 'transparent'}
              transform={positioning.pathTransform}
            />
          );
        })}
      </svg>
    </div>
  );
}
