import { Character, UnlockedCharacters } from './index';

export enum SequenceCategory {
  INSTRUCTIONAL_CHARACTERS = 'INSTRUCTIONAL_CHARACTERS',
  PRACTICE = 'PRACTICE',
}

export enum InstructionalSequenceType {
  SEQUENCE_1 = 'SEQUENCE_1',
  SEQUENCE_2 = 'SEQUENCE_2',
  SEQUENCE_3 = 'SEQUENCE_3',
  SEQUENCE_4 = 'SEQUENCE_4',
  SEQUENCE_5 = 'SEQUENCE_5',
  SEQUENCE_6 = 'SEQUENCE_6',
  SEQUENCE_7 = 'SEQUENCE_7',
  SEQUENCE_8 = 'SEQUENCE_8',
  SEQUENCE_9 = 'SEQUENCE_9',
  SEQUENCE_10 = 'SEQUENCE_10',
  SEQUENCE_11 = 'SEQUENCE_11',
  SEQUENCE_12 = 'SEQUENCE_12',
  SEQUENCE_13 = 'SEQUENCE_13',
  SEQUENCE_14 = 'SEQUENCE_14',
  SEQUENCE_15 = 'SEQUENCE_15',
  SEQUENCE_16 = 'SEQUENCE_16',
  SEQUENCE_17 = 'SEQUENCE_17',
  SEQUENCE_18 = 'SEQUENCE_18',
  SEQUENCE_19 = 'SEQUENCE_19',
  SEQUENCE_20 = 'SEQUENCE_20',
  SEQUENCE_21 = 'SEQUENCE_21',
  SEQUENCE_22 = 'SEQUENCE_22',
  SEQUENCE_23 = 'SEQUENCE_23',
  CUSTOM_1 = 'CUSTOM_1',
}

export type InstructionalSequence = {
  id: InstructionalSequenceType,
  display?: string,
  order: number,
  category: SequenceCategory,
  characters: Character[],
  unlockedCharacters: UnlockedCharacters,
};
