import { SpacingActivityCategory, SpacingItemType } from '../../../data/types';
import amSuccess from './VO62-AM.m4a';
import atSuccess from './VO62-AT.m4a';
import bagSuccess from './VO62-BAG.m4a';
import chSuccess from './VO61-CH.m4a';
import ifSuccess from './VO62-IF.m4a';
import inSuccess from './VO62-IN.m4a';
import itSuccess from './VO62-IT.m4a';
import noSuccess from './VO62-NO.m4a';
import redSuccess from './VO62-RED.m4a';
import shSuccess from './VO61-SH.m4a';
import thSuccess from './VO61-TH.m4a';
import upSuccess from './VO62-UP.m4a';
import usSuccess from './VO62-US.m4a';
import yesSuccess from './VO62-YES.m4a';

import multiSuccess from './VO52.m4a';

const spacingActivitySuccess = {
  [SpacingItemType.AM]: amSuccess,
  [SpacingItemType.AT]: atSuccess,
  [SpacingItemType.BAG]: bagSuccess,
  [SpacingItemType.CH]: chSuccess,
  [SpacingItemType.IF]: ifSuccess,
  [SpacingItemType.IN]: inSuccess,
  [SpacingItemType.IT]: itSuccess,
  [SpacingItemType.NO]: noSuccess,
  [SpacingItemType.RED]: redSuccess,
  [SpacingItemType.SH]: shSuccess,
  [SpacingItemType.TH]: thSuccess,
  [SpacingItemType.UP]: upSuccess,
  [SpacingItemType.US]: usSuccess,
  [SpacingItemType.YES]: yesSuccess,

  [SpacingActivityCategory.MULTI_CHOICE]: multiSuccess,
};

export default spacingActivitySuccess;
