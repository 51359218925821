import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import {
  SpacingActivityType,
  SpacingItemType,
  SpacingItemSize,
  StrokeOutcome,
  PracticeLinesRelationship,
  Pen,
  Iteration,
  DifficultyLevel,
} from '../data/types';
import { SpacingActionOutcome, SpacingEventTypes, SpacingState } from '../stateMachines/spacingMachine';
import { SpacingPracticeAreaWrapper } from '../styles/components/ContentWrappers';
import { SpacingDrawingArea, SpacingDrawingLinesFlex } from '../styles/components/DrawingArea';
import ModelSpacingItem from './ModelSpacingItem';
import { useAudioContext } from '../context/AudioContext';
import spacingActivityIntros from '../assets/audio/spacingActivityIntros';
import SpacingWritingAreaStage from './Konva/SpacingWritingArea';
import { DRAWABLE_HEIGHT_NORMAL, DRAWABLE_HEIGHT_NORMAL_EXTENDED } from '../shared/constants';
import SpacingActivityScaffold from './SpacingActivityScaffold';
import { characters } from '../data/characters';
import spacingActivitySuccess from '../assets/audio/spacingActivitySuccess';
import ProgressIndicator from './ProgressIndicator';
import RocketAnimation from './Animations/RocketAnimation';
import { getAnalyticsSpacingOutcomeMessage } from '../shared/analyticsMessages';
import CompletedCharacter from './Konva/CompletedCharacter';
import { DrawingStroke } from './Konva/types';
import plink from '../assets/audio/activitySuccess/plink.m4a';
import useChaseTheShootingStar from '../hooks/useChaseTheShootingStar';
import set from '../assets/audio/instructions/VO49-readysetgo-set.m4a';
import go from '../assets/audio/instructions/VO49-readysetgo-go.m4a';
import practiceLetterFormation from '../assets/audio/activityErrors/VO86-practice-letter-formation.m4a';
import ReadySetGoStarAnimation from './Animations/ReadySetGoStarAnimation';
import youDidIt from '../assets/audio/spacingActivitySuccess/VO58.m4a';
import wayToGo from '../assets/audio/spacingActivitySuccess/VO65.m4a';
import errorTryAgainTraceTheLine from '../assets/audio/activityErrors/VO6-tryagain.m4a';
import errorTryAgain from '../assets/audio/activityErrors/VO11.m4a';
import errorTryDifferentActivity from '../assets/audio/activityErrors/VO85-try-different-activity.m4a';
import errorTrySomethingElse from '../assets/audio/activityErrors/VO12-13.m4a';
import { getStrokeErrorVO } from '../assets/audio/activityErrors';
import { ActionType, useGameContext } from '../context/GameContext';
import useDirectionalStartDot from '../hooks/useDirectionalStartDot';
import { instructionalSequences } from '../data/sequences';

type SpacingWritingActivityProps = {
  activePen: Pen,
  setCanChangePen: React.Dispatch<React.SetStateAction<boolean>>,
  travelingStarChild: React.ReactChild,
}

// activity maps over iterations in chase the shooting star
// so iterations array must not be empty
const defaultFirstIteration: Iteration = {
  id: 1,
  strokes: [],
};

export default function SpacingWritingActivity({
  activePen,
  setCanChangePen,
  travelingStarChild,
}: SpacingWritingActivityProps) {
  const { state, send } = useSpacingGameContext();
  const ctx = useGameContext();
  const { currentActivity } = state.context;
  const audioContext = useAudioContext();
  const { trackEvent } = useMatomo();
  const [iterations, setIterations] = useState<Iteration[]>([defaultFirstIteration]);
  const drawingAreaRef = useRef<HTMLDivElement>(null);
  const readySetGoStarRef = useRef<HTMLDivElement>(null);
  const {
    isReadySetGoStarted, finishReadySetGoSequence, beginShootingStarAnim,
    startShootingStarAnim, resetChaseStarIteration, startChaseStarIteration, getReadyStarAnimationData,
  } = useChaseTheShootingStar();
  const isStartDotDirectional = useDirectionalStartDot();

  const drawingAreaHeight = useMemo(() => {
    if (!state.context?.currentItem?.traceCharacterType) return DRAWABLE_HEIGHT_NORMAL;
    const traceCharacter = characters[state.context.currentItem.traceCharacterType];
    return (traceCharacter.practiceLinesRelationship === PracticeLinesRelationship.BELOW_LINES)
      ? DRAWABLE_HEIGHT_NORMAL_EXTENDED
      : DRAWABLE_HEIGHT_NORMAL;
  }, [state.context?.currentItem?.traceCharacterType]);

  const showFadedIntroSequence = useMemo(() => {
    if (currentActivity?.type === SpacingActivityType.TRACE_FADED
      && state.matches(SpacingState.CUEING_ACTION)) return true;
    return false;
  }, [state]);

  const showGuideDots = useMemo(() => {
    if (!currentActivity || currentActivity.type === SpacingActivityType.SPACE_INDEPENDENT) {
      return false;
    }
    if (state.matches(SpacingState.ACTIVE)) return true;
    return false;
  }, [currentActivity, state]);

  const canWrite = useMemo(() => {
    if (currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT
        && state.matches(SpacingState.CUEING_ACTION)) return true;
    return state.matches(SpacingState.ACTIVE);
  }, [currentActivity, state]);

  const currentActivityProgress = useMemo(() => {
    if (!state.context?.activitiesProgress || !currentActivity) return null;
    const current = state.context.activitiesProgress[currentActivity.type] || null;
    if (!current) return null;
    return current;
  }, [currentActivity?.type, state.context?.activitiesProgress, iterations]);

  useEffect(() => {
    if (state.context?.currentActivity?.type === SpacingActivityType.TRACE_SHOOTING_STAR
        && !state.matches(SpacingState.ACTIVE)) {
      resetChaseStarIteration();
    }
  }, [state.value, state.context?.currentActivity?.type]);

  const cueActivityIntro = (
    currentActivityType: SpacingActivityType,
    currentItemType: SpacingItemType,
    currentIteration: number,
    currentErrorCount: number,
  ) => {
    switch (currentActivityType) {
      case SpacingActivityType.TRACE_FADED:
        audioContext?.handlePlay({
          src: spacingActivityIntros[currentActivityType][currentItemType],
          onEnd: () => send(SpacingEventTypes.NEXT),
        });
        break;
      case SpacingActivityType.TRACE_SHOOTING_STAR:
        if (currentIteration === 1 && currentErrorCount === 0) {
          audioContext?.handlePlay({
            src: spacingActivityIntros[currentActivityType][currentItemType],
            onEnd: () => startChaseStarIteration(),
          });
        } else {
          startChaseStarIteration();
        }
        break;
      case SpacingActivityType.SPACE_INDEPENDENT:
        audioContext?.handlePlay({ src: spacingActivityIntros[currentActivityType][currentItemType] });
        break;
      default:
        break;
    }
  };

  const cueActivityError = (
    currentActivityType: SpacingActivityType,
    spacingErrorCount: number,
    charFormationErrorCount: number,
    lastStrokeOutcome: StrokeOutcome | null,
  ) => {
    if (charFormationErrorCount === 1 && lastStrokeOutcome) {
      audioContext?.handlePlay({
        src: getStrokeErrorVO(lastStrokeOutcome, true, currentActivityType),
        onEnd: () => send(SpacingEventTypes.NEXT),
      });
      return;
    }
    if (charFormationErrorCount > 1 && lastStrokeOutcome) {
      audioContext?.handlePlay({
        src: practiceLetterFormation,
        onEnd: () => {
          ctx?.dispatch({
            type: ActionType.SELECT_PRACTICE_SEQUENCE,
            payload: { ...instructionalSequences.SEQUENCE_21 },
          });
          send(SpacingEventTypes.NEXT);
        },
      });
      return;
    }

    switch (currentActivityType) {
      case SpacingActivityType.TRACE_FADED:
        if (spacingErrorCount === 1) {
          audioContext?.handlePlay({ src: errorTryAgainTraceTheLine, onEnd: () => send(SpacingEventTypes.NEXT) });
        }
        if (spacingErrorCount === 2) {
          audioContext?.handlePlay({ src: errorTryDifferentActivity });
        }
        break;
      case SpacingActivityType.TRACE_SHOOTING_STAR:
      case SpacingActivityType.SPACE_INDEPENDENT:
        if (spacingErrorCount === 3) {
          audioContext?.handlePlay({ src: errorTryDifferentActivity });
          break;
        }
        if (spacingErrorCount === 5) {
          audioContext?.handlePlay({ src: errorTrySomethingElse });
          break;
        }
        audioContext?.handlePlay({ src: errorTryAgain, onEnd: () => send(SpacingEventTypes.NEXT) });
        break;
      default:
        break;
    }
  };

  const cueActivitySuccess = (
    currentActivityType: SpacingActivityType,
    currentItemType: SpacingItemType,
    currentIteration: number,
  ) => {
    switch (currentActivityType) {
      case SpacingActivityType.TRACE_FADED:
        audioContext?.handlePlay({ src: spacingActivitySuccess[currentItemType] });
        break;
      case SpacingActivityType.TRACE_SHOOTING_STAR:
        if (currentIteration === 1) {
          audioContext?.handlePlay({ src: plink, onEnd: () => send(SpacingEventTypes.NEXT) });
        } else {
          audioContext?.handlePlay({
            src: plink,
            onEnd: () => audioContext?.handlePlay({
              src: wayToGo,
            }),
          });
        }
        break;
      case SpacingActivityType.SPACE_INDEPENDENT:
        if (currentIteration < 3) {
          audioContext?.handlePlay({ src: plink });
        } else {
          audioContext?.handlePlay({
            src: plink,
            onEnd: () => audioContext?.handlePlay({ src: youDidIt }),
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.context || !state.context.currentItem || !state.context.currentActivity) return;
    if (state.matches(SpacingState.CUEING_ACTION)) {
      cueActivityIntro(
        state.context.currentActivity.type,
        state.context.currentItem.type,
        state.context.completedIterations + 1,
        state.context.spacingErrorCount,
      );
    } else if (state.matches(SpacingState.SHOWING_ERROR_FEEDBACK)) {
      cueActivityError(
        state.context.currentActivity.type,
        state.context.spacingErrorCount,
        state.context.charFormationErrorCount,
        state.context.lastStrokeOutcome,
      );
    } else if (state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)) {
      cueActivitySuccess(
        state.context.currentActivity.type,
        state.context.currentItem.type,
        state.context.completedIterations + 1,
      );
    }
  }, [state.value, state.context?.currentActivity?.type]);

  useEffect(() => {
    const requiredIterations = state.context?.currentActivity?.requiredIterations || 1;
    const activityIterations = [];
    for (let i = 1; i <= requiredIterations; i++) {
      activityIterations.push({ id: i, strokes: [] });
    }
    setIterations(activityIterations);
  }, [state.context?.currentActivity?.type]);

  const handleSpacingOutcome = (
    outcome: SpacingActionOutcome,
    charStrokeOutcome: StrokeOutcome,
    hasMoreStrokes: boolean,
    currentStrokeCount: number,
    errorLocations: string,
    strokes: DrawingStroke[],
  ) => {
    setCanChangePen(!hasMoreStrokes);

    let analyticsMessage = `Stroke ${currentStrokeCount} - ${getAnalyticsSpacingOutcomeMessage(outcome)}`;
    if (state.context?.currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT && errorLocations.length > 0) {
      analyticsMessage += ` - ${errorLocations}`;
    }
    trackEvent({
      category: `${state.context?.currentItem?.type}`,
      action: analyticsMessage,
      name: `${state.context?.currentActivity?.type}`,
    });

    if (!hasMoreStrokes) {
      // only send drawn characters if we're in independent activity,
      // and the outcome is a success
      let optionalItems: { [key: string]: any } = { };
      if (currentActivity?.type === SpacingActivityType.SPACE_INDEPENDENT) {
        optionalItems = {
          ...optionalItems,
          drawnSpacingChar: {
            order: state.context.completedIterations,
            spacingItemType: state.context.currentItem?.type,
            setType: state.context.currentSet?.type,
            drawingStrokes: [...strokes],
            isSelected: false,
          },
        };
      }
      if (outcome !== SpacingActionOutcome.FAIL) {
        const currentIterationIndex = state.context?.completedIterations || 0;
        const updatedIterations = [...iterations];
        updatedIterations[currentIterationIndex] = {
          ...iterations[currentIterationIndex],
          strokes,
        };
        optionalItems = {
          ...optionalItems,
          activityProgress: {
            currentIteration: state.context.completedIterations + 1,
            iterations: updatedIterations,
          },
        };
      }
      send({
        type: SpacingEventTypes.ACTION_COMPLETED,
        payload: { spacingOutcome: outcome, charStrokeOutcome },
        ...optionalItems,
      });
      setIterations((prevState) => {
        const thisIterationIndex = state.context?.completedIterations || 0;
        const nextState = [...prevState];
        nextState[thisIterationIndex] = {
          ...prevState[thisIterationIndex],
          strokes,
        };
        return nextState;
      });
      return;
    }

    if (charStrokeOutcome !== StrokeOutcome.SUCCESS) {
      send({
        type: SpacingEventTypes.ACTION_COMPLETED,
        payload: { spacingOutcome: outcome, charStrokeOutcome },
      });
      return;
    }

    // If there are more strokes, but current stroke
    // has been spaced incorrectly, send failed outcome
    if (outcome === SpacingActionOutcome.FAIL) {
      send({
        type: SpacingEventTypes.ACTION_COMPLETED,
        payload: { spacingOutcome: outcome },
      });
    }
  };

  const handleTapOffStart = (currentStrokeIndex: number) => {
    const currentTraceCharType = state.context.currentItem?.traceCharacterType;
    const currentStroke = currentTraceCharType ? characters[currentTraceCharType].strokes[currentStrokeIndex] : null;
    ctx?.dispatch({ type: ActionType.STROKE_FALSE_START, payload: StrokeOutcome.WARNING_INCORRECT_START_POINT });
    audioContext?.handlePlay({
      src: getStrokeErrorVO(
        StrokeOutcome.WARNING_INCORRECT_START_POINT,
        isStartDotDirectional(currentStroke),
        state.context.currentActivity?.type,
      ),
      onEnd: () => ctx?.dispatch({ type: ActionType.STROKE_FALSE_START, payload: null }),
    });
  };

  const getWritingAreaWidth = (): number => {
    if (!state.context || !state.context.currentItem) return 0;
    if (state.context.currentItem.size === SpacingItemSize.sm) {
      return 250;
    }
    return 300;
  };

  const handleReadyStarAnimFinish = () => {
    beginShootingStarAnim();
    audioContext?.handlePlay({
      src: set,
      onEnd: () => {
        audioContext?.handlePlay({ src: go });
        send(SpacingEventTypes.NEXT);
        finishReadySetGoSequence();
      },
    });
  };

  const renderWritingAreaForResetOn = (resetState: SpacingState, iterationIndex: number = 0) => {
    if (!state.context?.currentItem) return null;
    const currentIteration = (currentActivityProgress?.iterations)
      ? currentActivityProgress.iterations[iterationIndex]
      : null;
    return (
      <>
        <SpacingWritingAreaStage
          width={getWritingAreaWidth()}
          height={drawingAreaHeight}
          spacingItem={state.context.currentItem}
          character={characters[state.context.currentItem.traceCharacterType]}
          activePen={activePen}
          canWrite={canWrite}
          showGuideDots={showGuideDots}
          showTargetZone={canWrite}
          doReset={state.matches(resetState) || state.matches(SpacingState.CUEING_ACTION)}
          handleSpacingOutcome={handleSpacingOutcome}
          handleTapOffStart={handleTapOffStart}
          activityInProgressStrokes={currentIteration?.strokes || null}
          currentIteration={state.context.completedIterations}
        />
        {state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK) && travelingStarChild}
      </>
    );
  };

  useEffect(() => {
    if (currentActivityProgress) {
      // Make activity look like it did before navigating away
      if (currentActivityProgress?.iterations) setIterations([...currentActivityProgress.iterations]);
    }
  }, [currentActivityProgress]);

  if (!currentActivity || !state.context?.currentItem) {
    return null;
  }

  return (
    <SpacingPracticeAreaWrapper difficultyLevel={DifficultyLevel.NORMAL}>
      <ModelSpacingItem
        currentItem={state.context.currentItem}
        showItem={currentActivity.type !== SpacingActivityType.SPACE_INDEPENDENT}
      />

      {[SpacingActivityType.TRACE_FADED, SpacingActivityType.SPACE_INDEPENDENT]
        .includes(currentActivity.type) ? (
          <>
            <SpacingDrawingLinesFlex style={{ height: `${drawingAreaHeight}px` }}>
              <SpacingDrawingArea>
                <SpacingActivityScaffold
                  currentActivityType={currentActivity.type}
                  currentItem={state.context.currentItem}
                  showIntroSequence={showFadedIntroSequence}
                />
                {renderWritingAreaForResetOn(SpacingState.ACTIVE, state.context?.completedIterations)}
              </SpacingDrawingArea>
              <RocketAnimation
                start={currentActivity.type === SpacingActivityType.SPACE_INDEPENDENT
                  && state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK) && state.context?.completedIterations < 2}
                startPositionX={(state.context?.currentItem?.display?.length || 0) <= 2 ? 75 : 0}
              />
            </SpacingDrawingLinesFlex>
            {currentActivity.type === SpacingActivityType.SPACE_INDEPENDENT && (
              <ProgressIndicator
                pageCount={currentActivity.requiredIterations}
                currentIndex={state.context.completedIterations}
              />
            )}
          </>
        ) : null}

      {currentActivity.type === SpacingActivityType.TRACE_SHOOTING_STAR ? (
        <>
          <div className="ready-set-go-star" ref={readySetGoStarRef}>
            <ReadySetGoStarAnimation
              animationData={getReadyStarAnimationData(
                drawingAreaRef,
                readySetGoStarRef,
                state.context?.currentItem?.traceCharacterType,
                state.context?.currentItem?.positioning?.scaffold.lottiePositionX,
              )}
              onAnimComplete={() => handleReadyStarAnimFinish()}
              start={isReadySetGoStarted}
            />
          </div>

          <div className="activity-grid">
            {iterations.map((iteration, index) => {
              const { currentItem } = state.context;
              const traceCharacterType = currentItem?.traceCharacterType;
              const currentActivityType = currentActivity.type;
              if (!currentItem || !traceCharacterType || !currentActivityType) return null;

              // previous iteration
              if (state.context.completedIterations > index) {
                return (
                  <SpacingDrawingLinesFlex
                    key={iteration.id}
                    width={(currentActivityType === SpacingActivityType.TRACE_FADED
                      || currentActivityType === SpacingActivityType.SPACE_INDEPENDENT) ? 75 : 100}
                    style={{ height: `${drawingAreaHeight}px` }}
                  >
                    <SpacingDrawingArea>
                      <SpacingActivityScaffold
                        currentActivityType={currentActivityType}
                        currentItem={currentItem}
                        showIntroSequence={showFadedIntroSequence}
                      />
                      <CompletedCharacter
                        width={getWritingAreaWidth()}
                        height={drawingAreaHeight}
                        strokes={iteration.strokes}
                        difficultyLevel={DifficultyLevel.NORMAL}
                      />
                    </SpacingDrawingArea>
                  </SpacingDrawingLinesFlex>
                );
              }

              // current iteration
              if (state.context.completedIterations === index) {
                return (
                  <SpacingDrawingLinesFlex
                    key={iteration.id}
                    style={{ height: `${drawingAreaHeight}px` }}
                  >
                    <SpacingDrawingArea ref={drawingAreaRef}>
                      <SpacingActivityScaffold
                        currentActivityType={currentActivityType}
                        currentItem={currentItem}
                        showIntroSequence={showFadedIntroSequence}
                        playLottie={startShootingStarAnim}
                      />
                      {renderWritingAreaForResetOn(SpacingState.CUEING_ACTION, index)}
                    </SpacingDrawingArea>
                  </SpacingDrawingLinesFlex>
                );
              }

              // future iteration
              if (state.context.completedIterations < index) {
                return (
                  <SpacingDrawingLinesFlex
                    style={{ height: `${drawingAreaHeight}px` }}
                    key={iteration.id}
                  >
                  &nbsp;
                  </SpacingDrawingLinesFlex>
                );
              }

              return null;
            })}
          </div>
        </>
      ) : null}

    </SpacingPracticeAreaWrapper>
  );
}
