import React, { useMemo } from 'react';
import { GameFlowStep, useGameContext } from '../context/GameContext';
import CharacterKinestheticGroup from '../pages/CharacterKinestheticGroup';
import CharacterDrawingActivity from '../pages/CharacterDrawingActivity';
import SelectNextCharacter from '../pages/SelectNextCharacter';
import SelectSequence from '../pages/SelectSequence';
import CreateCustomCharacterSet from '../pages/CreateCustomCharacterSet';
import CharacterDemo from '../pages/CharacterDemo';
import { ActivityType, SpacingActivityCategory } from '../data/types';
import CharacterStarGameActivity from '../pages/CharacterStarGameActivity';
import CharacterSelfAssessment from '../pages/CharacterSelfAssessment';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import { SpacingState } from '../stateMachines/spacingMachine';
import SpacingDemo from '../pages/SpacingDemo';
import SelectNextSpacingItem from '../pages/SelectNextSpacingItem';
import SpacingActivityWrapper from '../pages/SpacingActivityWrapper';
import SpacingPlanetGame from '../pages/SpacingPlanetGame';
import CharacterEndOfSetDisplay from '../pages/CharacterEndOfSetDisplay';
import GripDemo from '../pages/GripDemo';

// TODO: replace with separate routes in App.tsx when we have persistent sessions
export default function GameScreens() {
  const ctx = useGameContext();
  const { state } = useSpacingGameContext();

  const flowStep = useMemo(
    () => ctx?.flowStep,
    [ctx?.flowStep],
  );

  const currentActivity = useMemo(
    () => ctx?.currentActivity,
    [ctx?.currentActivity],
  );

  if (state.matches(SpacingState.INIT)) {
    switch (flowStep) {
      case GameFlowStep.CREATE_CUSTOM_SET:
        return <CreateCustomCharacterSet />;
      case GameFlowStep.SEQUENCE_COMPLETED:
        return <CharacterEndOfSetDisplay />;
      case GameFlowStep.SELECT_PRACTICE_CHARACTER:
        return <SelectNextCharacter />;
      case GameFlowStep.WRITING:
        return (currentActivity && currentActivity.type === ActivityType.STAR_GAME)
          ? <CharacterStarGameActivity />
          : <CharacterDrawingActivity />;
      case GameFlowStep.VIEW_KINESTHETIC_GROUP:
        return <CharacterKinestheticGroup />;
      case GameFlowStep.WATCH_CHARACTER_DEMO_VIDEO:
        return <CharacterDemo />;
      case GameFlowStep.WATCH_GRIP_DEMO_VIDEO:
        return <GripDemo />;
      case GameFlowStep.SELF_ASSESSMENT:
        return <CharacterSelfAssessment />;
      case GameFlowStep.INTRO_CHARACTER:
        return <SelectNextCharacter confirmNextCharacter />;
      case GameFlowStep.INIT:
      default:
        return <SelectSequence />;
    }
  } else {
    if (state.matches(SpacingState.SHOWING_DEMO)) {
      return <SpacingDemo />;
    }
    if (state.matches(SpacingState.SELECTING_NEXT_ITEM)) {
      return <SelectNextSpacingItem />;
    }
    if (state.context?.currentActivity?.category === SpacingActivityCategory.PLANET_GAME) {
      return <SpacingPlanetGame />;
    }
    if (
      state.matches(SpacingState.ACTIVE)
      || state.matches(SpacingState.CUEING_ACTION)
      || state.matches(SpacingState.SHOWING_ERROR_FEEDBACK)
      || state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK)
    ) {
      return <SpacingActivityWrapper />;
    }
    return <SelectSequence />;
  }
}
