export const WRITING_LINE_HEIGHT_SMALL = 85;
export const WRITING_LINE_HEIGHT_NORMAL = 120;
export const WRITING_LINE_HEIGHT_LARGE = 258;

// extra space above, left, right of target area where writing is possible
export const DRAWABLE_MARGIN = 22;
const DRAWABLE_MARGIN_2X = 2 * DRAWABLE_MARGIN;

// size of the largest yellow target writing area (these will eventually differ by letter)
export const TARGET_WIDTH_NORMAL = 122;
export const TARGET_HEIGHT_NORMAL = WRITING_LINE_HEIGHT_NORMAL;
export const TARGET_HEIGHT_NORMAL_EXTENDED = TARGET_HEIGHT_NORMAL + 40;
export const TARGET_WIDTH_LARGE = 289;
const TARGET_HEIGHT_LARGE = WRITING_LINE_HEIGHT_LARGE;
export const TARGET_HEIGHT_LARGE_EXTENDED = TARGET_HEIGHT_LARGE + 100;

// size of the drawable area, including additional space above, left, right of target area
export const DRAWABLE_WIDTH_NORMAL = TARGET_WIDTH_NORMAL + DRAWABLE_MARGIN_2X;
export const DRAWABLE_WIDTH_LARGE = TARGET_WIDTH_LARGE + DRAWABLE_MARGIN_2X;
export const DRAWABLE_HEIGHT_NORMAL = TARGET_HEIGHT_NORMAL + DRAWABLE_MARGIN_2X;
export const DRAWABLE_HEIGHT_NORMAL_EXTENDED = TARGET_HEIGHT_NORMAL_EXTENDED + DRAWABLE_MARGIN_2X;
export const DRAWABLE_HEIGHT_LARGE_EXTENDED = TARGET_HEIGHT_LARGE_EXTENDED + DRAWABLE_MARGIN_2X;

// model lines at top for demo purposes
// character position/size data should be set for WRITING_LINE_HEIGHT_NORMAL and scaled up for model lines
export const MODEL_LINE_HEIGHT_NORMAL = 156;
export const MODEL_LINE_HEIGHT_NORMAL_SCALE = MODEL_LINE_HEIGHT_NORMAL / WRITING_LINE_HEIGHT_NORMAL;
export const MODEL_LINE_HEIGHT_SMALL = 85;
export const MODEL_LINE_HEIGHT_SMALL_SCALE = MODEL_LINE_HEIGHT_SMALL / WRITING_LINE_HEIGHT_SMALL;

// scales
export const SCALE_LARGE = 2;
export const SCALE_NORMAL = 1;

// for underlying touch circles used to capture intersections of stroke and start- and mid-points
export const VISIBLE_POINT_DIAMETER_NORMAL = 16;
export const CIRCLE_POSITION_ADJUSTMENT_NORMAL = VISIBLE_POINT_DIAMETER_NORMAL / 2;
export const VISIBLE_POINT_DIAMETER_LARGE = 30;
export const CIRCLE_POSITION_ADJUSTMENT_LARGE = VISIBLE_POINT_DIAMETER_LARGE / 2;

// acceptable distance between points in a stroke, used for palm rejection logic
export const EVAL_DISTANCE_FROM_LAST_STROKE_POINT = 15;

// dot stroke
export const ACCEPTABLE_DOT_STROKE_LENGTH = 10;

// drawn stroke width
export const DRAWN_STROKE_WIDTH = 8;

// error count thresholds
export const FIRST_ERROR_THRESHOLD = 3;
export const SECOND_ERROR_THRESHOLD = 5;

// video path
export const DEFAULT_VIDEO_PATH = `${window.location.origin}/videos`;

// planet game total number of attempts allowed
export const PLANET_GAME_ALLOWED_ATTEMPTS = 3;
