import { Paths } from '../data/types';

const getDocumentTitle = (path: Paths) => {
  const title = 'Starwriter';
  switch (path) {
    case Paths.ABOUT:
      return title.concat(' | About');
    case Paths.EDUCATOR_RESOURCES:
      return title.concat(' | Educator Resources');
    case Paths.TOS:
      return title.concat(' | Terms of Use');
    case Paths.PRIVACY:
      return title.concat(' | Privacy Policy');
    case Paths.DIRECT_NOTICE:
      return title.concat(' | Direct Notice');
    default:
      return title;
  }
};

export default getDocumentTitle;
