import { characterSets } from './character-sets';
import {
  CharacterType, InstructionalSequenceType, InstructionalSequence, SequenceCategory,
} from './types';

export const instructionalSequences: {
  [key in Exclude<InstructionalSequenceType, InstructionalSequenceType.CUSTOM_1>]: InstructionalSequence
} = {
  [InstructionalSequenceType.SEQUENCE_1]: {
    id: InstructionalSequenceType.SEQUENCE_1,
    display: 'I T L',
    order: 1,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.STRAIGHT_LINE_DOWN.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_2]: {
    id: InstructionalSequenceType.SEQUENCE_2,
    display: 'O Q C G',
    order: 2,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.CIRCLE_STARTER.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_3]: {
    id: InstructionalSequenceType.SEQUENCE_3,
    display: '1 2 3',
    order: 3,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.NUMBERS_123.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_4]: {
    id: InstructionalSequenceType.SEQUENCE_4,
    display: 'J U',
    order: 4,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.DOWN_AND_CURVE.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_5]: {
    id: InstructionalSequenceType.SEQUENCE_5,
    display: 'F E H A',
    order: 5,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.LINE_BACK.characters,
      ...characterSets.MIDDLE_LINE_ACROSS.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_6]: {
    id: InstructionalSequenceType.SEQUENCE_6,
    display: '4 5 6',
    order: 6,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.NUMBERS_456.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_7]: {
    id: InstructionalSequenceType.SEQUENCE_7,
    display: 'V W M N 7',
    order: 7,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.DOWN_AND_UP_PALS.characters,
      ...characterSets.UP_AND_DOWN_PALS.characters,
      ...characterSets.NUMBER_7.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_8]: {
    id: InstructionalSequenceType.SEQUENCE_8,
    display: 'D P B',
    order: 8,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.BUMP.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_B]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_9]: {
    id: InstructionalSequenceType.SEQUENCE_9,
    display: 'K R S',
    order: 9,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.KICK.characters,
      ...characterSets.BUMP_AND_KICK.characters,
      ...characterSets.SUPER_SQUIGGLY.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_10]: {
    id: InstructionalSequenceType.SEQUENCE_10,
    display: '8 9 0',
    order: 10,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.NUMBERS_890.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_11]: {
    id: InstructionalSequenceType.SEQUENCE_11,
    display: 'X Y Z',
    order: 11,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.UPPER_CRISS_CROSS.characters,
      ...characterSets.ZIP_FORWARD_BACK.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_12]: {
    id: InstructionalSequenceType.SEQUENCE_12,
    display: 'o c a d',
    order: 12,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.LOWER_CIRCLE_STARTER.characters,
      ...characterSets.LINE_COMES_LATER.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_13]: {
    id: InstructionalSequenceType.SEQUENCE_13,
    display: 'q g',
    order: 13,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.HOOK.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_14]: {
    id: InstructionalSequenceType.SEQUENCE_14,
    display: 'j i',
    order: 14,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.DOTS_ON_TOP.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_15]: {
    id: InstructionalSequenceType.SEQUENCE_15,
    display: 'm n r p',
    order: 15,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.HUMP.characters,
      ...characterSets.CIRCLE_COMES_LATER.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_P]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_16]: {
    id: InstructionalSequenceType.SEQUENCE_16,
    display: 'l t h b k',
    order: 16,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.HIGH_STARTERS.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_K]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_17]: {
    id: InstructionalSequenceType.SEQUENCE_17,
    display: 'u v w',
    order: 17,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.LOWER_DOWN_AND_UP.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_K]: true,
      [CharacterType.LOWER_U]: true,
      [CharacterType.LOWER_V]: true,
      [CharacterType.LOWER_W]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_18]: {
    id: InstructionalSequenceType.SEQUENCE_18,
    display: 'f e s',
    order: 18,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.HIGH_HOOK.characters,
      ...characterSets.LOOP_AROUND.characters,
      ...characterSets.LOWER_SUPER_SQUIGGLY.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_K]: true,
      [CharacterType.LOWER_U]: true,
      [CharacterType.LOWER_V]: true,
      [CharacterType.LOWER_W]: true,
      [CharacterType.LOWER_F]: true,
      [CharacterType.LOWER_E]: true,
      [CharacterType.LOWER_S]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_19]: {
    id: InstructionalSequenceType.SEQUENCE_19,
    display: 'x y z',
    order: 19,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.LOWER_CRISS_CROSS.characters,
      ...characterSets.ZIG_ZAG.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
      [CharacterType.NUMBER_0]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_K]: true,
      [CharacterType.LOWER_F]: true,
      [CharacterType.LOWER_E]: true,
      [CharacterType.LOWER_S]: true,
      [CharacterType.LOWER_U]: true,
      [CharacterType.LOWER_V]: true,
      [CharacterType.LOWER_W]: true,
      [CharacterType.LOWER_X]: true,
      [CharacterType.LOWER_Y]: true,
      [CharacterType.LOWER_Z]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_20]: {
    id: InstructionalSequenceType.SEQUENCE_20,
    display: 'A to Z',
    order: 20,
    category: SequenceCategory.PRACTICE,
    characters: [],
    unlockedCharacters: {
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_21]: {
    id: InstructionalSequenceType.SEQUENCE_21,
    display: 'a to z',
    order: 21,
    category: SequenceCategory.PRACTICE,
    characters: [],
    unlockedCharacters: {
      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_E]: true,
      [CharacterType.LOWER_F]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_K]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_S]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_U]: true,
      [CharacterType.LOWER_V]: true,
      [CharacterType.LOWER_W]: true,
      [CharacterType.LOWER_X]: true,
      [CharacterType.LOWER_Y]: true,
      [CharacterType.LOWER_Z]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_22]: {
    id: InstructionalSequenceType.SEQUENCE_22,
    display: '0 to 9',
    order: 22,
    category: SequenceCategory.PRACTICE,
    characters: [],
    unlockedCharacters: {
      [CharacterType.NUMBER_0]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,
    },
  },
  [InstructionalSequenceType.SEQUENCE_23]: {
    id: InstructionalSequenceType.SEQUENCE_23,
    display: '+ = ? !',
    order: 23,
    category: SequenceCategory.INSTRUCTIONAL_CHARACTERS,
    characters: [
      ...characterSets.SYMBOLS.characters,
    ],
    unlockedCharacters: {
      [CharacterType.UPPER_A]: true,
      [CharacterType.UPPER_B]: true,
      [CharacterType.UPPER_C]: true,
      [CharacterType.UPPER_D]: true,
      [CharacterType.UPPER_E]: true,
      [CharacterType.UPPER_F]: true,
      [CharacterType.UPPER_G]: true,
      [CharacterType.UPPER_H]: true,
      [CharacterType.UPPER_I]: true,
      [CharacterType.UPPER_J]: true,
      [CharacterType.UPPER_K]: true,
      [CharacterType.UPPER_L]: true,
      [CharacterType.UPPER_M]: true,
      [CharacterType.UPPER_N]: true,
      [CharacterType.UPPER_O]: true,
      [CharacterType.UPPER_P]: true,
      [CharacterType.UPPER_Q]: true,
      [CharacterType.UPPER_R]: true,
      [CharacterType.UPPER_S]: true,
      [CharacterType.UPPER_T]: true,
      [CharacterType.UPPER_U]: true,
      [CharacterType.UPPER_V]: true,
      [CharacterType.UPPER_W]: true,
      [CharacterType.UPPER_X]: true,
      [CharacterType.UPPER_Y]: true,
      [CharacterType.UPPER_Z]: true,

      [CharacterType.LOWER_A]: true,
      [CharacterType.LOWER_B]: true,
      [CharacterType.LOWER_C]: true,
      [CharacterType.LOWER_D]: true,
      [CharacterType.LOWER_E]: true,
      [CharacterType.LOWER_F]: true,
      [CharacterType.LOWER_G]: true,
      [CharacterType.LOWER_H]: true,
      [CharacterType.LOWER_I]: true,
      [CharacterType.LOWER_J]: true,
      [CharacterType.LOWER_K]: true,
      [CharacterType.LOWER_L]: true,
      [CharacterType.LOWER_M]: true,
      [CharacterType.LOWER_N]: true,
      [CharacterType.LOWER_O]: true,
      [CharacterType.LOWER_P]: true,
      [CharacterType.LOWER_Q]: true,
      [CharacterType.LOWER_R]: true,
      [CharacterType.LOWER_S]: true,
      [CharacterType.LOWER_T]: true,
      [CharacterType.LOWER_U]: true,
      [CharacterType.LOWER_V]: true,
      [CharacterType.LOWER_W]: true,
      [CharacterType.LOWER_X]: true,
      [CharacterType.LOWER_Y]: true,
      [CharacterType.LOWER_Z]: true,

      [CharacterType.NUMBER_0]: true,
      [CharacterType.NUMBER_1]: true,
      [CharacterType.NUMBER_2]: true,
      [CharacterType.NUMBER_3]: true,
      [CharacterType.NUMBER_4]: true,
      [CharacterType.NUMBER_5]: true,
      [CharacterType.NUMBER_6]: true,
      [CharacterType.NUMBER_7]: true,
      [CharacterType.NUMBER_8]: true,
      [CharacterType.NUMBER_9]: true,

      [CharacterType.SYMBOL_PLUS]: true,
      [CharacterType.SYMBOL_EQUAL]: true,
      [CharacterType.SYMBOL_QUESTION]: true,
      [CharacterType.SYMBOL_EXCLAMATION]: true,
    },
  },
};

export const instructionalSequenceTypeList = (
  Object.keys(InstructionalSequenceType) as (keyof typeof InstructionalSequenceType)[]
);

const getAllSequencesByOrder = (): InstructionalSequence[] => (
  Object.values(instructionalSequences).sort((a, b) => a.order - b.order)
);

export function getNextCharacterSequenceInOrder(currentSequence: InstructionalSequence) {
  // If a custom sequence return itself.
  if (currentSequence.id === InstructionalSequenceType.CUSTOM_1) {
    return currentSequence;
  }
  const currentSequenceOrder = currentSequence.order;
  // For most instructional sequences, move to next sequence in order.
  let nextOrder = currentSequenceOrder + 1;
  if (currentSequence.id === InstructionalSequenceType.SEQUENCE_23
      && instructionalSequences[InstructionalSequenceType.SEQUENCE_20]) {
    // However, the symbol sequence needs to have the next sequence be "A to Z."
    nextOrder = instructionalSequences[InstructionalSequenceType.SEQUENCE_20].order;
  }
  const nextSequence = getAllSequencesByOrder().find((sequence) => sequence.order === nextOrder);
  return nextSequence || null;
}
