import { DefaultTheme } from 'styled-components';
import { CharacterCategory } from '../data/types';
import colorNames from './colors';
import paperBg from '../assets/images/backgrounds/paper-background.png';

const defaultTheme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    primary: colorNames.blue,
    secondary: colorNames.orange,
    black: colorNames.black,
    white: colorNames.white,
    buttons: {
      demo: {
        primary: colorNames.cyan,
        secondary: colorNames.darkCyan,
      },
      grip: {
        primary: colorNames.vividPurple,
        secondary: colorNames.purple,
      },
      home: {
        primary: colorNames.vividOrange,
        secondary: colorNames.darkOrange,
      },
      sequence: {
        primary: colorNames.blue,
        secondary: colorNames.darkModerateBlue,
      },
      replay: {
        primary: colorNames.lightCyan,
        secondary: colorNames.darkCyan,
      },
      next: {
        active: {
          primary: colorNames.brightBlue,
          secondary: colorNames.darkBlue,
        },
        disabled: {
          primary: colorNames.verySoftBlue,
          secondary: colorNames.softBlue,
        },
      },
      instructionalCharacterSequence: {
        active: {
          primary: colorNames.blue,
          secondary: colorNames.darkModerateBlue,
          tertiary: colorNames.blueGray,
          contrastText: colorNames.veryDarkBlue,
        },
        disabled: {
          primary: colorNames.lightBlue,
          secondary: colorNames.darkLightBlue,
          tertiary: colorNames.blueGray,
          contrastText: colorNames.gray100,
        },
      },
      instructionalDigraphSequence: {
        active: {
          primary: colorNames.yellow,
          secondary: colorNames.darkOrange,
          tertiary: colorNames.lightBrown,
          contrastText: colorNames.darkBrownOrange,
        },
        disabled: {
          primary: colorNames.paleYellow,
          secondary: colorNames.dullOrange,
          tertiary: colorNames.lightBrown,
          contrastText: colorNames.brown,
        },
      },
      practiceSequence: {
        active: {
          primary: colorNames.orange,
          secondary: colorNames.darkOrange,
          tertiary: colorNames.lightBrown,
          contrastText: colorNames.darkBrownOrange,
        },
        disabled: {
          primary: colorNames.lightYellow,
          secondary: colorNames.dullOrange,
          tertiary: colorNames.lightBrown,
          contrastText: colorNames.brown,
        },
      },
      ok: {
        primary: colorNames.blue,
        secondary: colorNames.darkModerateBlue,
        contrastText: colorNames.veryDarkBlue,
      },
      access: {
        active: {
          primary: colorNames.blue,
          secondary: colorNames.darkModerateBlue,
          contrastText: colorNames.veryDarkBlue,
        },
        disabled: {
          primary: colorNames.lightBlue,
          secondary: colorNames.darkLightBlue,
          contrastText: colorNames.gray100,
        },
      },
      resources: {
        primary: colorNames.blue,
        secondary: colorNames.darkModerateBlue,
        contrastText: colorNames.black,
      },
      launch: {
        primary: colorNames.vividPurple,
        secondary: colorNames.purple,
        contrastText: colorNames.white,
      },
    },
    svgs: {
      hexagonButton: {
        [CharacterCategory.LETTER_LOWER]: {
          fill: colorNames.vividOrange,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkOrange,
          text: colorNames.gray300,
        },
        [CharacterCategory.LETTER_UPPER]: {
          fill: colorNames.lightOrange,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkOrange,
          text: colorNames.gray300,
        },
        [CharacterCategory.NUMBER]: {
          fill: colorNames.yellow,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkOrange,
          text: colorNames.gray300,
        },
        [CharacterCategory.SYMBOL]: {
          fill: colorNames.yellow,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkOrange,
          text: colorNames.gray300,
        },
        selected: {
          fill: colorNames.blue,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkModerateBlue,
          text: colorNames.gray300,
        },
        disabled: {
          fill: colorNames.lightBlue,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkLightBlue,
          text: colorNames.gray300,
        },
        spacingDefault: {
          fill: colorNames.yellow,
          innerStroke: colorNames.whiteOrange,
          outerStroke: colorNames.darkOrange,
          text: colorNames.gray300,
        },
      },
    },
    text: {
      primary: colorNames.gray200,
      secondary: colorNames.darkModerateBlue,
      tertiary: colorNames.veryDarkBlue,
    },
    cards: {
      default: {
        borderColor: 'transparent',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.25)',
      },
      selected: {
        borderColor: colorNames.brightBlue,
        boxShadow: `0px 4px 24px 12px ${colorNames.paleBlue}`,
      },
      pass: {
        borderColor: colorNames.vividGreen,
        boxShadow: `0px 4px 24px 12px ${colorNames.lightGreen}`,
      },
      fail: {
        borderColor: colorNames.mediumPink,
        boxShadow: `0px 4px 24px 12px ${colorNames.lightPink}`,
      },
    },
    progressIndicator: {
      default: colorNames.gray400,
      selected: colorNames.cyan,
    },
    documents: {
      default: colorNames.black,
      h2: colorNames.navyBlue,
    },
  },

  fonts: {
    families: {
      primary: 'StarWriter',
      secondary: 'Inter',
    },
    weights: {
      bold: 400,
      bolder: 600,
      boldest: 700,
    },
    sizes: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      mdLg: '18px',
      lg: '20px',
      xl: '24px',
      xxl: '27px',
      xxxl: '48px',
      display: '96px',
    },
  },

  images: {
    mainBackground: paperBg,
  },
};

export default defaultTheme;
