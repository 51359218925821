import React, { useEffect, useState } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import CharacterButton from '../components/Buttons/CharacterButton';
import SequencePageButton from '../components/Buttons/SequencePageButton';
import { ActionType, useGameContext } from '../context/GameContext';
import { CharacterCategory, CharacterType } from '../data/types';
import {
  lowerLetterList, numberList, upperLetterList, symbolList, characters,
} from '../data/characters';
import { ThreeGroupHexagonButtonLayout, HexagonButtonGroup } from '../styles/components/HexagonButtonLayouts';
import { CharacterSelectionAreaWrapper, SequenceScreenAccessWrapper } from '../styles/components/ContentWrappers';
import EmptyCharacterSpacer from '../styles/components/EmptyCharacterSpacer';
import { SelectCharactersContainer } from '../styles/components/ScreenContainers';
import ClosePageButton from '../components/Buttons/ClosePageButton';
import GrantAccess from '../components/GrantAccess';
import pencil from '../assets/images/pencil.png';
import starGroup1 from '../assets/images/star-group-1.png';
import starGroup2 from '../assets/images/star-group-2.png';
import selectGreenArrow from '../assets/audio/VO1.m4a';
import selectBlueButton from '../assets/audio/VO75.m4a';
import { AnalyticsEventCategory } from '../data/types/analytics';
import NoInternetToast from '../components/NoInternet/NoInternetToast';
import { useAudioContext } from '../context/AudioContext';
import useOnlineStatus from '../hooks/useOnlineStatus';
import { ActionType as ProgressActionType, useActivityProgressContext } from '../context/ActivityProgressContext';

const CHARACTER_BUTTON_WIDTH = '68px';
const CHARACTER_BUTTON_HEIGHT = '76px';

enum ViewState {
  ACCESS = 'ACCESS',
  CHARACTERS = 'CHARACTERS'
}

export default function SelectNextCharacter({ confirmNextCharacter }: { confirmNextCharacter?: boolean }) {
  const ctx = useGameContext();
  const audioContext = useAudioContext();
  const { trackEvent } = useMatomo();
  const [view, setView] = useState(ViewState.CHARACTERS);
  const isOnline = useOnlineStatus();
  const progress = useActivityProgressContext();
  const allCharactersInSequence: CharacterType[] = (ctx?.currentSequence)
    ? ctx.currentSequence?.characters.map((character) => character.type)
    : [];

  // Design requires specific letters on specific rows
  const upperLetterListRow1 = upperLetterList.slice(0, 10);
  const upperLetterListRow2 = upperLetterList.slice(10, 21);
  const upperLetterListRow3 = upperLetterList.slice(21);
  const lowerLetterListRow1 = lowerLetterList.slice(0, 10);
  const lowerLetterListRow2 = lowerLetterList.slice(10, 21);
  const lowerLetterListRow3 = lowerLetterList.slice(21);

  useEffect(() => {
    audioContext?.handlePlay({ src: confirmNextCharacter ? selectGreenArrow : selectBlueButton });
    progress?.dispatch({ type: ProgressActionType.RESET_ALL_PROGRESS });
    return () => audioContext?.handleComplete();
  }, []);

  const isButtonDisabled = (charType: CharacterType): boolean => (
    confirmNextCharacter
      ? charType !== ctx?.currentCharacter?.type
      : !ctx?.unlockedCharacters[charType]
  );

  const isCurrentCharacter = (charType: CharacterType): boolean => charType === ctx?.currentCharacter?.type;

  const isCharacterInCurrentSequence = (charType: CharacterType): boolean => allCharactersInSequence.includes(charType);

  const isCharacterInUnlockedSet = (charType: CharacterType): boolean => {
    if (!ctx) return false;
    if (confirmNextCharacter) {
      if (!ctx.currentSequence || !ctx.currentSequence.unlockedCharacters) return false;
      return ctx.currentSequence.unlockedCharacters[charType] === true;
    }
    if (!ctx.unlockedCharacters) return false;
    return ctx.unlockedCharacters[charType] === true;
  };

  const startSequence = (key: CharacterType) => {
    if (characters[key]?.category === CharacterCategory.NUMBER
        || characters[key]?.category === CharacterCategory.SYMBOL) {
      ctx?.dispatch({ type: ActionType.GO_TO_CHARACTER_DEMO_VIDEO });
    } else {
      ctx?.dispatch({ type: ActionType.GO_TO_KINESTHETIC_GROUP_PAGE });
    }

    if (!ctx?.shouldRequireAccessToSequenceScreen) {
      trackEvent({
        category: AnalyticsEventCategory.SET,
        action: 'Started',
        name: ctx?.currentSequence?.display,
      });
    }
  };

  const handleTouch = (charType: CharacterType) => {
    if (!confirmNextCharacter) {
      ctx?.dispatch({ type: ActionType.SELECT_CUSTOM_CHARACTER, payload: characters[charType] });
    }
    startSequence(charType);
  };

  const handleViewHomeButtonTouch = () => {
    ctx?.dispatch({ type: ActionType.RESET });
    window.location.reload();
  };

  const handleCloseButtonTouch = () => {
    setView(ViewState.CHARACTERS);
    window.scrollTo(0, 0);
  };

  if (view === ViewState.ACCESS) {
    return (
      <SequenceScreenAccessWrapper>
        <ClosePageButton buttonTouch={() => handleCloseButtonTouch()} ariaLabel="go to home page" />
        <div className="content">
          <GrantAccess buttonTouch={() => handleViewHomeButtonTouch()} buttonText="Go to Home" />
        </div>
      </SequenceScreenAccessWrapper>
    );
  }

  return (
    <>
      {!isOnline && <NoInternetToast />}
      <SelectCharactersContainer>
        <h1>Starwriter</h1>
        <CharacterSelectionAreaWrapper>
          <SequencePageButton buttonTouch={ctx?.shouldRequireAccessToSequenceScreen
            ? () => setView(ViewState.ACCESS)
            : () => ctx?.dispatch({ type: ActionType.RESET })}
          />
          <img className="stars-left" src={starGroup1} alt="" />
          <img className="stars-right" src={starGroup2} alt="" />
          <ThreeGroupHexagonButtonLayout>
            <HexagonButtonGroup className="number">
              <div className="row">
                {(numberList as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.NUMBER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
            </HexagonButtonGroup>
            <HexagonButtonGroup className="symbol">
              <div className="row">
                {(symbolList as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.SYMBOL}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
            </HexagonButtonGroup>
            <HexagonButtonGroup className="upper-letter">
              <div className="row one">
                {(upperLetterListRow1 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_UPPER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
              <div className="row two">
                {(upperLetterListRow2 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_UPPER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
              <div className="row three">
                <EmptyCharacterSpacer width={CHARACTER_BUTTON_WIDTH} height={CHARACTER_BUTTON_HEIGHT} />
                {(upperLetterListRow3 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_UPPER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
            </HexagonButtonGroup>
            <HexagonButtonGroup className="lower-letter">
              <div className="row one">
                {(lowerLetterListRow1 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_LOWER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
              <div className="row two">
                {(lowerLetterListRow2 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_LOWER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
              </div>
              <div className="row three">
                {(lowerLetterListRow3 as CharacterType[]).map((key) => (
                  <CharacterButton
                    key={key}
                    charType={key}
                    charCategory={CharacterCategory.LETTER_LOWER}
                    isDisabled={isButtonDisabled(key)}
                    buttonTouch={() => handleTouch(key)}
                    isCurrentCharacter={isCurrentCharacter(key)}
                    isCharacterInCurrentSequence={isCharacterInCurrentSequence(key)}
                    isCharacterInUnlockedSet={isCharacterInUnlockedSet(key)}
                  />
                ))}
                <EmptyCharacterSpacer width={CHARACTER_BUTTON_WIDTH} height={CHARACTER_BUTTON_HEIGHT} />
              </div>
            </HexagonButtonGroup>
          </ThreeGroupHexagonButtonLayout>
          <img className="pencil" src={pencil} alt="" />
        </CharacterSelectionAreaWrapper>
      </SelectCharactersContainer>
    </>
  );
}

SelectNextCharacter.defaultProps = {
  confirmNextCharacter: false,
};
