import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import App from './App';
import GameContextProvider from './context/GameContext';
import UserSettingsContextProvider from './context/UserSettingsContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import getEnvironment from './shared/getEnvironment';
import ErrorFallback from './pages/ErrorFallback';
import './fonts.css';
import './animations.css';
import AudioContextProvider from './context/AudioContext';
import ActivityProgressContextProvider from './context/ActivityProgressContext';
import FeedbackContextProvider from './context/FeedbackContext';
import SpacingGameContextProvider from './context/SpacingGameContext';
import RewardsContextProvider from './context/RewardsContext';

const environment = getEnvironment();

Sentry.init({
  dsn: 'https://e31ff8f214174203a4b6b0350ce8ba3a@o1404145.ingest.sentry.io/6737085',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment,
  enabled: process.env.NODE_ENV !== 'development',
});

// Matomo Instance so custom javascript events can be used.
// NOTE: Changes here may require changes to public/scripts/analytics.js
const instance = createInstance({
  urlBase: 'https://starwriterorg.matomo.cloud',
  siteId: 1,
  trackerUrl: 'https://starwriterorg.matomo.cloud/matomo.php',
  srcUrl: 'https://cdn.matomo.cloud/starwriterorg.matomo.cloud/matomo.js',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <BrowserRouter>
        <UserSettingsContextProvider>
          <MatomoProvider value={instance}>
            <RewardsContextProvider>
              <GameContextProvider>
                <SpacingGameContextProvider>
                  <AudioContextProvider>
                    <ActivityProgressContextProvider>
                      <FeedbackContextProvider>
                        <App />
                      </FeedbackContextProvider>
                    </ActivityProgressContextProvider>
                  </AudioContextProvider>
                </SpacingGameContextProvider>
              </GameContextProvider>
            </RewardsContextProvider>
          </MatomoProvider>
        </UserSettingsContextProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
