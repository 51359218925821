import styled, { css } from 'styled-components';
import modelLines from '../../assets/images/model-lines.svg';
import modelLinesLarge from '../../assets/images/lines-large.svg';

export enum CardWrapperSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum CardWrapperType {
  DEFAULT = 'default',
  SELECTED = 'selected',
  PASS = 'pass',
  FAIL = 'fail'
}

export interface CardWrapperProps {
  isSelected: boolean,
  size: CardWrapperSize,
  type: CardWrapperType
  hasAdditionalCharPositioning?: boolean,
}

const CardWrapper = styled.div <CardWrapperProps>`
  box-sizing: border-box;
  ${(props) => props.size === CardWrapperSize.SM && css`
    width: 230px;
    height: 129.38px;
    padding: 0 30px;
  `}
  ${(props) => props.size === CardWrapperSize.MD && css`
    width: 384px;
    height: 200px;
  `}

  ${(props) => props.size === CardWrapperSize.LG && css`
    width: 450px;
    height: 500px;
  `}
  position: relative;
  box-shadow: ${(props) => (props.isSelected
    ? props.theme.colors.cards[props.type].boxShadow
    : props.theme.colors.cards.default.boxShadow)};
  background-color: ${(props) => (props.theme.colors.white)};
  border-width: 8px;
  border-style: solid;
  border-color: ${(props) => (props.isSelected
    ? props.theme.colors.cards[props.type].borderColor
    : props.theme.colors.cards.default.borderColor)};
  border-radius: 53px;

  button {
    width: 100%;
    height: 100%;
    padding: 20px 50px;
    position: relative;
    background-color: inherit;
    border: none;
    border-radius: 60px;
    overflow: hidden;
  }

  .lines {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      ${(props) => props.size === CardWrapperSize.LG && css`
        background-image: url(${modelLinesLarge});
      `}
      ${(props) => props.size !== CardWrapperSize.LG && css`
        background-image: url(${modelLines});
      `}
      background-position: center;
      background-repeat: repeat-x;
      ${(props) => props.size === CardWrapperSize.SM && css`
        background-size: auto 69px;
      `}
      ${(props) => props.size === CardWrapperSize.MD && css`
        background-size: auto 120px;
      `}
      ${(props) => props.size === CardWrapperSize.LG && css`
        background-size: auto 258px;
      `}

      div.drawn-character {
        position: relative;
        top: ${(props) => (props.size === CardWrapperSize.LG ? '92px' : '0')};
        height: 100%;
        ${(props) => props.hasAdditionalCharPositioning && css`
          position: absolute;
          left: 2.5%;
          top: 22px;
        `}
      }

      div.content {
        display: flex;
        justify-content: center;
        position: relative;
        height: 100%;
        width: 100%
      }
    }

  .selected-marker {
    position: absolute;
    top: 9%;
    right: 5%;
  }
`;

export default CardWrapper;
