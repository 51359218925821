import React, { useEffect, useState } from 'react';
import { useAudioContext } from '../context/AudioContext';
import TranslucentOverlay from '../styles/components/TranslucentOverlay';
import PlayButton from './Buttons/PlayButton';
import ReplayButton from './Buttons/ReplayButton';
import Video from './Video';
import play from '../assets/audio/VO3-play.m4a';
import playWithOption from '../assets/audio/VO3-playwithoption.m4a';
import replay from '../assets/audio/VO3-replay.m4a';

type VideoPlayerProps = {
  path: string;
  ariaLabelPostfix: string;
  hasPlayedBefore: boolean,
  onFinish: () => void,
}

export default function VideoPlayer({
  path,
  ariaLabelPostfix,
  hasPlayedBefore,
  onFinish,
}: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const audioContext = useAudioContext();

  useEffect(() => {
    if (!isPlaying) {
      if (isComplete) {
        audioContext?.handlePlay({ src: replay });
      } else if (hasPlayedBefore) {
        audioContext?.handlePlay({ src: playWithOption });
      } else {
        audioContext?.handlePlay({ src: play });
      }
    }
    return () => audioContext?.handleComplete();
  }, [isPlaying, isComplete, hasPlayedBefore]);

  const handleStart = () => {
    setIsPlaying(true);
  };

  const handleComplete = () => {
    setIsComplete(true);
    setIsPlaying(false);
    onFinish();
  };

  const handleError = () => {
    onFinish();
  };

  if (!path) return null;

  return (
    <>
      {(isComplete && !isPlaying) && <TranslucentOverlay />}
      <Video
        path={path}
        start={isPlaying}
        onLoad={() => setIsLoading(false)}
        onComplete={handleComplete}
        onError={handleError}
      />
      {(!isLoading && !isPlaying) && (
        <div className="button-container middle">
          {!isComplete ? (
            <PlayButton
              buttonTouch={handleStart}
              ariaLabel={`play demo video for ${ariaLabelPostfix}`}
            />
          ) : (
            <ReplayButton
              buttonTouch={handleStart}
              ariaLabel={`re-play demo video for ${ariaLabelPostfix}`}
            />
          )}
        </div>
      )}
    </>
  );
}
