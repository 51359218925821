import React, { useEffect, useMemo } from 'react';
import { useSpacingGameContext } from '../context/SpacingGameContext';
import {
  DrawnSpacingCharacter, SpacingActionOutcome, SpacingEventTypes, SpacingState,
} from '../stateMachines/spacingMachine';
import { SelfAssessmentAreaWrapper } from '../styles/components/ContentWrappers';
import DrawnCharCardButton from './Buttons/DrawnCharCardButton';
import chooseEasiestToReadWord from '../assets/audio/selfAssessment/VO66-word.m4a';
import chooseEasiestToReadDigraph from '../assets/audio/selfAssessment/VO66-digraph.m4a';
import { useAudioContext } from '../context/AudioContext';
import spacingScaffolds from '../assets/spacing-scaffolds';
import { useRewardsContext } from '../context/RewardsContext';
import { RewardEventTypes, RewardState } from '../stateMachines/rewardsMachine';
import newPen from '../assets/audio/rewards/VO28.m4a';
import { SpacingSetType } from '../data/types/spacing-sets';
import { DifficultyLevel } from '../data/types';

export default function SpacingSelfAssessment() {
  const { state, send } = useSpacingGameContext();
  const rewards = useRewardsContext();
  const audioContext = useAudioContext();

  const allDrawnSpacingCharsForCurrentSpacingItem = useMemo(() => {
    if (!state.context?.drawnSpacingChars) return [];
    return state.context.drawnSpacingChars
      .filter((drawnChar) => drawnChar.spacingItemType === state.context?.currentItem?.type);
  }, [state.context?.drawnSpacingChars]);

  const hasSelection: boolean = useMemo(() => {
    if (!state.context?.drawnSpacingChars) return false;
    return state.context.drawnSpacingChars
      .some((drawnChar) => drawnChar.spacingItemType === state.context?.currentItem?.type && drawnChar.isSelected);
  }, [state.context?.drawnSpacingChars]);

  const handleDrawnCharSelection = (drawnChar: DrawnSpacingCharacter): void => {
    const selection = { ...drawnChar, isSelected: true };
    send({
      type: SpacingEventTypes.ACTION_COMPLETED,
      payload: { spacingOutcome: SpacingActionOutcome.PASS },
      drawnSpacingChar: selection,
    });
  };

  const renderWordImage = () => {
    if (!state.context?.currentItem) return null;
    const { currentItem } = state.context;
    const yOffset = currentItem.positioning.scaffold.yOffset || 0;
    return (
      <img
        alt=""
        src={spacingScaffolds[currentItem.type]?.SPACE_INDEPENDENT}
        height={currentItem.positioning.scaffold.height || 0}
        style={{ position: 'absolute', top: `${yOffset + 14}px` }}
      />
    );
  };

  useEffect(() => {
    if (state.matches(SpacingState.CUEING_ACTION)) {
      const audioSrc = state.context.currentItem?.set === SpacingSetType.DIGRAPH
        ? chooseEasiestToReadDigraph
        : chooseEasiestToReadWord;
      audioContext?.handlePlay({ src: audioSrc, onEnd: () => send(SpacingEventTypes.NEXT) });
    } else if (state.matches(SpacingState.SHOWING_SUCCESS_FEEDBACK) && !state.context?.isPracticeMode) {
      rewards.send({
        type: RewardEventTypes.REWARD_PEN,
        payload: {
          itemType: state.context.currentItem?.type,
          activityType: state.context.currentActivity?.type,
        },
      });
    }
  }, [state.value]);

  useEffect(() => {
    if (rewards.state.matches(RewardState.REWARDING_PEN)) {
      audioContext?.handlePlay({ src: newPen });
    }
  }, [rewards.state.value]);

  return (
    <SelfAssessmentAreaWrapper>
      <div className="card-container">
        {allDrawnSpacingCharsForCurrentSpacingItem.map((drawnCharacter) => (
          <DrawnCharCardButton
            key={`${drawnCharacter.spacingItemType}-${drawnCharacter.order}`}
            onTouch={() => handleDrawnCharSelection(drawnCharacter)}
            isDisabled={hasSelection}
            drawnCharacter={drawnCharacter}
            partialWord={renderWordImage()}
            hasAdditionalCharPositioning={(state.context?.currentItem?.display?.length || 0) > 2}
            difficultyLevel={DifficultyLevel.NORMAL}
          />
        ))}
        <div className="spacer" />
      </div>
    </SelfAssessmentAreaWrapper>
  );
}
