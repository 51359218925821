import React, { createContext, useContext } from 'react';
import { ActorRefFrom } from 'xstate';
import { useActor, useInterpret } from '@xstate/react';
import { spacingMachine } from '../stateMachines/spacingMachine';

interface SpacingGlobalStateContextType {
  service: ActorRefFrom<typeof spacingMachine>;
}

const SpacingGameContext = createContext({} as SpacingGlobalStateContextType);

type SpacingGameContextProviderProps = {
  children: React.ReactNode,
}

export default function SpacingGameContextProvider({ children }: SpacingGameContextProviderProps) {
  const service = useInterpret(spacingMachine);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SpacingGameContext.Provider value={{ service }}>
      {children}
    </SpacingGameContext.Provider>
  );
}

export function useSpacingGameContext() {
  const globalSpacingServices = useContext(SpacingGameContext);

  if (globalSpacingServices === undefined) {
    throw new Error('useSpacingGameContext must be used within SpacingGameContextProvider');
  }

  const [state, send] = useActor(globalSpacingServices.service);

  return { state, send };
}
