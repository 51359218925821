import {
  CharacterType, SpacingItem, SpacingItemType, SpacingSetType, SpacingItemSize, SpacingFeedbackType,
} from './types';

export const spacingItems: {
  [key in SpacingItemType]: SpacingItem;
} = {
  [SpacingItemType.CH]: {
    type: SpacingItemType.CH,
    display: 'ch',
    set: SpacingSetType.DIGRAPH,
    traceCharacterType: CharacterType.LOWER_H,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 13,
      },
      scaffold: {
        height: 115,
        xOffset: 133,
        yOffset: 22,
        lottiePositionX: 35,
      },
      multi: {
        height: 114,
        yOffset: 18,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 145,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 177,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 187,
        },
      },
      shootingStar: {
        xOffset: 50,
      },
      grading: {
        inner: {
          xOffset: -28,
        },
        outer: {
          xOffset: 80,
        },
      },
      planetGame: {
        xOffset: 62,
      },
    },
  },
  [SpacingItemType.SH]: {
    type: SpacingItemType.SH,
    display: 'sh',
    set: SpacingSetType.DIGRAPH,
    traceCharacterType: CharacterType.LOWER_H,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 13,
      },
      scaffold: {
        height: 115,
        xOffset: 128,
        yOffset: 22,
        lottiePositionX: 28,
      },
      multi: {
        height: 112,
        yOffset: 19,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 147,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 182,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 198,
        },
      },
      shootingStar: {
        xOffset: 50,
      },
      grading: {
        outer: {
          xOffset: 80,
        },
      },
      planetGame: {
        xOffset: 58,
      },
    },
  },
  [SpacingItemType.TH]: {
    type: SpacingItemType.TH,
    display: 'th',
    set: SpacingSetType.DIGRAPH,
    traceCharacterType: CharacterType.LOWER_H,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 12,
      },
      scaffold: {
        height: 115,
        xOffset: 133,
        yOffset: 22,
        lottiePositionX: 34,
      },
      multi: {
        height: 115,
        yOffset: 16,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 152,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 178,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 192,
        },
      },
      shootingStar: {
        xOffset: 55,
      },
      grading: {
        outer: {
          xOffset: 80,
        },
      },
      planetGame: {
        xOffset: 62,
      },
    },
  },
  [SpacingItemType.IN]: {
    type: SpacingItemType.IN,
    display: 'in',
    set: SpacingSetType.WORDS_1,
    traceCharacterType: CharacterType.LOWER_N,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 75,
        yOffset: -8,
      },
      scaffold: {
        height: 75,
        xOffset: 123,
        yOffset: 64,
        lottiePositionX: 13,
      },
      multi: {
        height: 75,
        yOffset: 56,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 151,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 187,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 200,
        },
      },
      shootingStar: {
        xOffset: 45,
      },
      grading: {
        outer: {
          xOffset: 60,
        },
      },
      planetGame: {
        height: 140,
        xOffset: 42,
      },
    },
  },
  [SpacingItemType.AM]: {
    type: SpacingItemType.AM,
    display: 'am',
    set: SpacingSetType.WORDS_2,
    traceCharacterType: CharacterType.LOWER_M,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 60,
        yOffset: -16,
      },
      scaffold: {
        height: 60,
        xOffset: 132,
        yOffset: 78,
        lottiePositionX: 38,
      },
      multi: {
        height: 60,
        yOffset: 72,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 147,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 177,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 189,
        },
      },
      shootingStar: {
        xOffset: 55,
      },
      grading: {
        outer: {
          xOffset: 85,
        },
      },
      planetGame: {
        height: 100,
        xOffset: 60,
      },
    },
  },
  [SpacingItemType.UP]: {
    type: SpacingItemType.UP,
    display: 'up',
    set: SpacingSetType.WORDS_1,
    traceCharacterType: CharacterType.LOWER_P,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 95,
        yOffset: -32,
      },
      scaffold: {
        height: 95,
        xOffset: 132,
        yOffset: 76,
        lottiePositionX: 32,
      },
      multi: {
        height: 90,
        yOffset: 72,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 147,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 177,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 189,
        },
      },
      shootingStar: {
        xOffset: 55,
      },
      grading: {
        outer: {
          xOffset: 75,
        },
      },
      planetGame: {
        height: 150,
        xOffset: 62,
      },
    },
  },
  [SpacingItemType.IT]: {
    type: SpacingItemType.IT,
    display: 'it',
    set: SpacingSetType.WORDS_2,
    traceCharacterType: CharacterType.LOWER_T,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 13,
      },
      scaffold: {
        height: 115,
        xOffset: 120,
        yOffset: 22,
        lottiePositionX: 15,
      },
      multi: {
        height: 115,
        yOffset: 16,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 147,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 189,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 204,
        },
      },
      shootingStar: {
        xOffset: 40,
      },
      grading: {
        inner: {
          xOffset: -28,
        },
        outer: {
          xOffset: 65,
        },
      },
      planetGame: {
        xOffset: 43,
      },
    },
  },
  [SpacingItemType.AT]: {
    type: SpacingItemType.AT,
    display: 'at',
    set: SpacingSetType.WORDS_2,
    traceCharacterType: CharacterType.LOWER_T,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 13,
      },
      scaffold: {
        height: 115,
        xOffset: 143,
        yOffset: 22,
        lottiePositionX: 37,
      },
      multi: {
        height: 115,
        yOffset: 16,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 122,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 166,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 183,
        },
      },
      shootingStar: {
        xOffset: 65,
      },
      grading: {
        outer: {
          xOffset: 65,
        },
      },
      planetGame: {
        xOffset: 80,
      },
    },
  },
  [SpacingItemType.IF]: {
    type: SpacingItemType.IF,
    display: 'if',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_F,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 125,
        yOffset: 13,
      },
      scaffold: {
        height: 115,
        xOffset: 111,
        yOffset: 22,
        lottiePositionX: 13,
      },
      multi: {
        height: 115,
        yOffset: 16,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 152,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 196,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 208,
        },
      },
      shootingStar: {
        xOffset: 27,
      },
      grading: {
        inner: {
          xOffset: -28,
        },
        outer: {
          xOffset: 87,
        },
      },
      planetGame: {
        xOffset: 30,
      },
    },
  },
  [SpacingItemType.US]: {
    type: SpacingItemType.US,
    display: 'us',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_S,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 60,
        yOffset: -16,
      },
      scaffold: {
        height: 60,
        xOffset: 142,
        yOffset: 78,
        lottiePositionX: 32,
      },
      multi: {
        height: 60,
        yOffset: 72,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 138,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 165,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 180,
        },
      },
      shootingStar: {
        xOffset: 65,
      },
      grading: {
        outer: {
          xOffset: 65,
        },
      },
      planetGame: {
        height: 120,
        xOffset: 77,
      },
    },
  },
  [SpacingItemType.NO]: {
    type: SpacingItemType.NO,
    display: 'no',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_O,
    size: SpacingItemSize.sm,
    positioning: {
      model: {
        height: 60,
        yOffset: -16,
      },
      scaffold: {
        height: 60,
        xOffset: 132,
        yOffset: 78,
        lottiePositionX: 32,
      },
      multi: {
        height: 58,
        yOffset: 72,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 143,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 175,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 191,
        },
      },
      shootingStar: {
        xOffset: 55,
      },
      grading: {
        outer: {
          xOffset: 75,
        },
      },
      planetGame: {
        height: 120,
        xOffset: 60,
      },
    },
  },
  [SpacingItemType.YES]: {
    type: SpacingItemType.YES,
    display: 'yes',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_S,
    size: SpacingItemSize.lg,
    positioning: {
      model: {
        height: 93,
        yOffset: -32,
      },
      scaffold: {
        height: 93,
        xOffset: 220,
        yOffset: 75,
        lottiePositionX: 82,
      },
      multi: {
        height: 88,
        yOffset: 72,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 98,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 121,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 151,
        },
      },
      shootingStar: {
        xOffset: 115,
      },
      grading: {
        outer: {
          xOffset: 60,
        },
      },
      planetGame: {
        height: 140,
        xOffset: 30,
      },
    },
  },
  [SpacingItemType.RED]: {
    type: SpacingItemType.RED,
    display: 'red',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_D,
    size: SpacingItemSize.lg,
    positioning: {
      model: {
        height: 120,
        yOffset: 14,
      },
      scaffold: {
        height: 120,
        xOffset: 202,
        yOffset: 18,
        lottiePositionX: 79,
      },
      multi: {
        height: 113,
        yOffset: 18,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 102,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 146,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 155,
        },
      },
      shootingStar: {
        xOffset: 101,
      },
      grading: {
        outer: {
          xOffset: 75,
        },
      },
      planetGame: {
        xOffset: 2,
      },
    },
  },
  [SpacingItemType.BAG]: {
    type: SpacingItemType.BAG,
    display: 'bag',
    set: SpacingSetType.WORDS_3,
    traceCharacterType: CharacterType.LOWER_G,
    size: SpacingItemSize.lg,
    positioning: {
      model: {
        height: 148,
        yOffset: -3,
      },
      scaffold: {
        height: 148,
        xOffset: 208,
        yOffset: 22,
        lottiePositionX: 85,
      },
      multi: {
        height: 143,
        yOffset: 18,
      },
      chooseStar: {
        [SpacingFeedbackType.TOO_CLOSE]: {
          yOffset: 107,
        },
        [SpacingFeedbackType.TOO_WIDE]: {
          yOffset: 120,
        },
        [SpacingFeedbackType.CORRECT]: {
          yOffset: 145,
        },
      },
      shootingStar: {
        xOffset: 108,
      },
      grading: {
        outer: {
          xOffset: 75,
        },
      },
      planetGame: {
        height: 210,
        xOffset: 20,
      },
    },
  },
};

export const spacingItemTypesList = Object.keys(spacingItems);
